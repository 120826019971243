import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ascending } from 'd3';
import { monochromaticSchemeFromColor } from '../utils';
import { routes } from '../routes';

import BarChart from './graphs/BarChart';

import css from './DoorsPerLockerBySize.css';

const sortBy = (array, property) => {
  return array.sort(function (x, y) {
    return ascending(x[property], y[property]);
  });
};

export default (props) => {
  const { data, graphSize } = props;
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);

  // Dummy data
  const parsedData = useMemo(() => {
    let newSizes = [];
    let newData = data.map((e) => Object.assign({}, e));
    newData = newData.map((d) => {
      let obj = {};
      obj.label = 'cm';
      obj.id = `${d.id}`;
      obj.name = d.name || '';
      d.doors.forEach((door) => {
        if (obj[`${door.height} cm`]) {
          obj[`${door.height} cm`] = obj[`${door.height} cm`] + 1;
        } else {
          obj[`${door.height} cm`] = 1;
          if (!newSizes.includes(`${door.height} cm`)) newSizes.push(`${door.height} cm`);
        }
      });
      return obj;
    });
    const newColors = monochromaticSchemeFromColor('#889191', newSizes.length, 40, 86);
    newData = sortBy(newData, 'id');
    newSizes.sort((a, b) => {
      a = parseInt(a.replace(' cm', ''));
      b = parseInt(b.replace(' cm', ''));
      return a - b;
    });
    setColors(newColors);
    setSizes(newSizes);
    return newData;
  }, [data]);

  const CustomTooltip = ({ indexValue, value, id, data, color, ...rest }) => {
    const description = value != 1 ? `${value} puertas de` : `${value} puerta de`;
    const { lockerName } = data;
    return (
      <div
        style={{
          padding: '0px 0px 2px 0px',
          lineHeight: 1.6,
          textAlign: 'left',
        }}>
        <strong style={{ color }}>{lockerName}</strong>
        <strong
          style={{
            fontSize: '10px',
            marginLeft: '1px',
            color: '#AAAAAA',
          }}>{`ID locker: ${indexValue}`}</strong>
        <br />
        <span>{description}</span>
        <strong
          style={{
            color: 'white',
            background: color,
            padding: '1px 4px 2px 4px',
            borderRadius: '4px',
            marginLeft: '6px',
          }}>{`Altura ${id}`}</strong>
      </div>
    );
  };

  return (
    <Fragment>
      <div className={css.lockerLegend}>
        {parsedData.length > 0 && <strong className={css.legendTitle}>Lockers</strong>}
        {parsedData.map((d) => {
          const { name = '', id } = d;
          return (
            <div className={css.legendLabel} key={`label-${id}`}>
              <Link to={routes('locker', { id })}>
                <span>
                  <strong>{id}</strong> – {name}
                </span>
              </Link>
            </div>
          );
        })}
      </div>
      <BarChart
        data={parsedData}
        customTooltip={CustomTooltip}
        enableLabel={false}
        style={{
          padding: 0.6,
          colors: colors,
          margin: { top: 80, right: 260, bottom: 60, left: 40 },
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 10,
            itemWidth: 100,
            itemHeight: 10,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 8,
          },
        ]}
        {...graphSize}
        keys={sizes}
        indexBy={'id'}
        customTick
        tickWithLinks
        title={`Número de puertas por tamaño agrupadas por locker`}
        axisTitles={{ left: `Nº de puertas`, bottom: `ID locker` }}
      />
    </Fragment>
  );
};

import React, { Fragment } from 'react';
import classnames from 'classnames';

import css from './Count.css';

export const Count = (props) => {
  const { name, value, total = 1, miniBar, percentage, width, valueLabel } = props;
  const percent = Math.round((value / total) * 100);
  return (
    <Fragment>
      <style>{`.${css.countWrapper} {
				max-width: ${width}px
			}`}</style>
      <div className={css.countWrapper}>
        <span>{value}</span>
        {valueLabel && <span>{` ${valueLabel}`}</span>}
        {percentage && <span className={css.percentage}>{isNaN(percent) ? `` : `(${percent}%)`}</span>}
        {miniBar && (
          <div className={css.miniBarChart}>
            <div className={css.miniBar} style={{ width: `${100 * (value / total)}%` }} />
          </div>
        )}
        <span className={css.countName} dangerouslySetInnerHTML={{ __html: name }}></span>
      </div>
    </Fragment>
  );
};

export default Count;

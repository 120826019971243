import { useState, useMemo } from 'react';
import { parseQuery, encodeQuery } from '../utils';

export const useQuery = (location, history, initialQuery) => {
  const [query, setQuery] = useState(parseQuery(location.search) || initialQuery || {});

  useMemo(() => {
    if (!location.search && initialQuery) history.push({ search: encodeQuery(initialQuery) });
  }, []);
  useMemo(() => {
    const query = parseQuery(location.search);
    setQuery(query);
  }, [location.search]);

  const set = (k, v) => {

    if (k == 'errored' && v == 'all') delete query[k];
    else {
      if (v != null) {
        query[k] = v;
      } else {
        delete query[k];
      }
    }

    if (k !== 'page') delete query.page;
    history.push({ search: encodeQuery(query) });
  };

  const setPage = (page) => set('page', page);

  const get = (k) => query[k];

  return {
    obj: query,
    string: location.search,
    setAll: setQuery,
    set,
    setPage,
    get,
  };
};

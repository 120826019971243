const debug = require('debug')('mtk:api');
import { useState, useMemo } from 'react';
import { fromJS, Set, Map, List } from 'immutable';
import Axios from 'axios';

import store from 'store2';
import isFunction from 'lodash/fp/isFunction';

import cache from './cache';
import { routes } from '../../routes';
import { isObject } from '../../utils';

import { baseActionLabels, objectLabels } from './constants';

const API_HOST = process.env.API_HOST || 'http://localhost:7000';
const NODE_ENV = process.env.NODE_ENV || 'development';
const TOKEN = 'X-Access-Token';
const DEFAULT_PER_PAGE = 30;
const secure = NODE_ENV == 'production';

export const session = store.namespace('user');

export const sleep = (ms) => new Promise((ok) => setTimeout(ok, ms));

export const getToken = () => {
  return session.get(TOKEN);
};

export const axios = Axios.create({
  baseURL: API_HOST,
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    [TOKEN]: getToken(),
  },
});

export const fetcher = (url, { method = 'GET', params, data, token } = {}) => {
  const config = getApiConfig(token);
  const { baseURL, headers } = config;

  const search = new URLSearchParams(params);
  const lastChar = baseURL.charAt(baseURL.length - 1);
  const fetchUrl = `${lastChar == '/' ? baseURL.slice(0, -1) : baseURL}${url}${params ? '?' : ''}${search.toString()}`;

  return fetch(fetchUrl, {
    headers,
    method,
    mode: 'cors',
    body: data ? JSON.stringify(data) : undefined,
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    } else {
      const err = new Error('API Error');
      err.response = response;
      err.response.data = await response.json();
      throw err;
    }
  });
};

export const getApiConfig = ({ token } = {}) => {
  return {
    baseURL: API_HOST,
    timeout: 3000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [TOKEN]: token || getToken(),
    },
  };
};

export const setToken = (token) => {
  axios.defaults.headers.common[TOKEN] = token;
  axios.defaults.headers[TOKEN] = token;
  return session.set(TOKEN, token);
};

export const clearToken = () => {
  axios.defaults.headers.common[TOKEN] = '';
  axios.defaults.headers[TOKEN] = '';
  return session.remove(TOKEN);
};

export const setSessionUser = (user) => session.set('user', user);
export const getSessionUser = (user) => session.get('user');
export const clearSessionUser = (user) => session.remove('user');

// GENERAL UTILS

export const keyFromUrl = (url, params) => {
  if (!url && !params) return false;
  const paramString =
    isObject(params) && Object.keys(params).length
      ? '-' +
      Object.entries(params)
        .map((e) => e.join(':'))
        .join('-')
      : '';
  return `${url.replace(/\W/g, '')}${paramString}`.toLowerCase();
};

export const prepareData = (data, { allowedParams, requiredParams = [], currentData }) => {
  //remove non allowed keys
  data = Array.isArray(allowedParams) ? fromJS(data).filter(keyIn(allowedParams)) : data;

  // remove unchanged values, keeping required ones
  data = currentData
    ? data.filter((v, k) => {
      return v != currentData.get(k) || requiredParams.includes(k);
    })
    : data;
  return data
    .map((v) => {
      if (Map.isMap(v)) {
        const filtered = v.filter((i) => i != null);
        return JSON.stringify(filtered);
      }
      return v;
    })
    .toJS();
};

export const actionLabel = (action, object) => {
  let label = baseActionLabels(action);
  if (object) label = label.replace(/\{\{\s?object\s?\}\}/g, objectLabels(object));
  return label;
};

export const objectFromUrl = (url, plural) => {
  const obj = url.split('/')[1];
  return plural ? obj : obj.slice(0, -1);
};

export const urlWithId = (url, id) => {
  return id ? url.replace(/^(.+)\/(.+?)\/*$/, `$1/${id}`) : url;
};

export const keyIn = (keys) => {
  const keySet = Set(keys);
  return (v, k) => {
    return keySet.has(k);
  };
};

// API UTILS

export const useIndexState = (url, params) => {
  const cacheKey = keyFromUrl(url, params);
  const initialData = cacheKey ? cache.get(cacheKey) : null;

  return useState({
    url,
    data: initialData ? fromJS(initialData) : false,
    pageInfo: fromJS({}),
    loaded: !!initialData,
    loading: null,
    status: null,
    statusText: null,
    error: null,
  });
};

export const useItemState = (url, params, initialState = {}) => {
  const id = url ? url.split('/').pop() : null;
  const cacheKey = keyFromUrl(url, params);
  const initialData = cacheKey ? cache.get(cacheKey) : null;

  return useState({
    id,
    url,
    data: initialData ? fromJS(initialData) : false,
    loaded: !!initialData,
    loading: null,
    status: null,
    statusText: null,
    error: null,
    ...initialState,
  });
};

export const handleApiStart = (setState, action, object) => {
  setState((s) => ({
    ...s,
    loading: true,
    error: null,
    status: null,
    statusText: null,
    action: [action, object].join('-'),
    actionLabel: actionLabel(action, object),
  }));
};

export const handleApiSuccess = (res, setState, add = {}) => {
  if (add.data) {
    const url = res.config.url.replace(res.config.baseURL, '');
    const cacheKey = keyFromUrl(url, res.config.params);
    cache.set(cacheKey, add.data.toJS(), 5);
  }
  setState((s) => {
    return {
      ...s,
      loaded: true,
      loading: false,
      status: res.status,
      message: res.data.message ? res.data.message : s.message,
      lastMessage: res.data.message || add.message ? Date.now() : s.lastMessage,
      statusText: res.statusText,
      ...add,
    };
  });
};

export const handleApiError = (err, setState, customErrors = {}) => {
  debug('ERROR', err);
  const nextState = {
    loading: false,
  };
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const res = err.response;
    nextState.status = res.status;
    nextState.statusText = res.data ? res.data.message : res.statusText;
    nextState.error = customErrors[res.status] || nextState.statusText;
    if (404 == res.status) {
      const url = res.config.url.replace(res.config.baseURL, '');
      const cacheKey = keyFromUrl(url, res.config.params);
      cache.delete(cacheKey);
    }
  } else if (err.request) {
    // The request was made but no response was received
    // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    nextState.error = 'Servidor no responde.';
  } else {
    console.error('Something happened in setting up the request that triggered an Error:', err);
    nextState.error = err.message || err;
  }
  setState((s) => ({
    ...s,
    ...nextState,
  }));

  return nextState;
};

// API GENERATORS

export const indexRoute = (url, defaultParams = {}) => {
  const object = objectFromUrl(url, true);
  return (params, prefs) => {
    const [index, setIndex] = useIndexState(url, { pageInfo: true, ...defaultParams, ...(params || {}) });
    const get = async (newParams) => {
      try {
        handleApiStart(setIndex, 'load', object);
        const res = await axios.get(url, {
          params: { ...defaultParams, ...(params || {}), ...(newParams || {}) },
        });
        const data = fromJS(res.data.data);
        const pageInfo = fromJS(res.data.pageInfo);
        handleApiSuccess(res, setIndex, {
          data,
          pageInfo,
        });
        return data;
      } catch (err) {
        debug(`error on get request "${url}"\n\n`, err);
        handleApiError(err, setIndex);
        return false;
      }
    };

    const getAll = async (newParams = {}, data = [], page = 1) => {
      handleApiStart(setIndex, 'load', object);

      try {
        const res = await axios.get(url, {
          params: { ...defaultParams, ...(params || {}), ...newParams, perPage: 50, page },
        });

        let newData = [];
        let shouldContinue = !!res.data.pageInfo.nextPage;

        // Filter orders if createdAfter param is present
        const createdAfter = newParams.createdAfter && new Date(newParams.createdAfter);
        const lastItem = res.data.data[res.data.data.length - 1];
        // only filter if tha last order of the batch is older than createdAfter
        if (createdAfter && new Date(lastItem.createdAt) < createdAfter) {
          const filteredData = res.data.data.filter((d) => new Date(d.createdAt) > createdAfter);
          newData = data.concat(filteredData);
          shouldContinue = false;
        } else {
          newData = data.concat(res.data.data);
        }

        if (shouldContinue) {
          return await getAll(newParams, newData, res.data.pageInfo.nextPage);
        }

        handleApiSuccess(res, setIndex, { all: newData });
        return fromJS(newData);
      } catch (err) {
        handleApiError(err, setIndex);
      }
    };

    useMemo(() => {
      if (!(prefs && prefs.lazy && !index.hasRun)) get();
      setIndex((i) => ({ ...i, hasRun: true }));
    }, [params]);

    return { index, actions: { get, getAll } };
  };
};

export const newItemRoute = (url, opts = {}) => {
  const { allowedParams, initialState } = opts;
  const object = objectFromUrl(url);
  return ({ history } = {}) => {
    const [item, setItem] = useItemState(initialState);
    const create = async (data) => {
      try {
        handleApiStart(setItem, 'create', object);
        const res = await axios.post(url, { [object]: prepareData(data, { allowedParams }) });
        handleApiSuccess(res, setItem, { data: fromJS(res.data.data), loading: true });
        if (history && history.push) {
          await sleep(1000);
          history.push(routes(object, { id: res.data.data.id }));
        } else {
          return fromJS(res.data.data);
        }
      } catch (err) {
        handleApiError(err, setItem);
      }
    };
    const actions = { create };

    return { item, actions };
  };
};

export const getItemAction = (url, { params, setItem, onSuccess, plural }) => {
  const object = objectFromUrl(url, plural);
  return async (_newParams, _action, id) => {
    const newParams = _newParams != null ? _newParams : {};
    const action = _action != null ? _action : 'load';
    try {
      handleApiStart(setItem, action, object);
      const res = await axios.get(urlWithId(url, id), { params: { ...params, ...newParams } });
      handleApiSuccess(res, setItem, { data: fromJS(res.data.data) });

      return onSuccess ? onSuccess(res) : fromJS(res.data.data);
    } catch (e) {
      handleApiError(e, setItem);
      return false;
    }
  };
};

export const updateItemAction = (url, { item, setItem, allowedParams: _allowedParams, requiredParams, onSuccess }) => {
  return async (_data, _action, _object, id) => {
    const data = _data != null ? _data : {};
    const action = _action != null ? _action : 'save';
    const object = _object != null ? _object : objectFromUrl(url);
    const allowedParams = isFunction(_allowedParams) ? _allowedParams() : _allowedParams;

    try {
      const preparedData = prepareData(fromJS(data), {
        allowedParams,
        requiredParams,
        currentData: item.data,
      });

      if (Object.keys(preparedData).length === 0) return false;

      handleApiStart(setItem, action, object);
      const res = await axios.patch(urlWithId(url, id), {
        [object]: preparedData,
      });

      handleApiSuccess(res, setItem);
      return onSuccess ? await onSuccess(res) : fromJS(res.data);
    } catch (err) {
      handleApiError(err, setItem);
      return false;
    }
  };
};

export const deleteItemAction = (url, { setItem, history }) => {
  return async (_action, _object, id) => {
    const action = _action != null ? _action : 'delete';
    const object = _object != null ? _object : objectFromUrl(url);
    try {
      handleApiStart(setItem, action, object);
      const res = await axios.delete(urlWithId(url, id));
      handleApiSuccess(res, setItem, { data: false, loaded: false });
      if (history && history.push) {
        await sleep(1000);
        history.push(routes(objectFromUrl(url, true)));
      } else {
        return true;
      }
    } catch (e) {
      handleApiError(e, setItem);
      return false;
    }
  };
};

import { useState, useMemo, useEffect } from 'react';
import { getClientKeyByClientId, orderTypes } from '../../constants';

import {
  handleApiStart,
  handleApiSuccess,
  handleApiError,
  axios,
  setToken,
  clearToken,
  getSessionUser,
  setSessionUser,
  clearSessionUser,
} from './utils';

const auth = () => {
  const [user, setUser] = useState({
    ...(getSessionUser() || {}),
    loaded: !!getSessionUser(),
    loading: null,
    status: null,
    statusText: null,
    error: null,
  });
  //setLanguage
  orderTypes.setLang(getClientKeyByClientId(user.clientId));
  useEffect(() => {}, user);

  const login = async (credentials) => {
    try {
      handleApiStart(setUser, 'login');
      const res = await axios.post(`/tokens`, { user: credentials });
      const { token, authCode, hasAuthCode } = res.data.data;

      if (authCode) return { authCode };
      if (hasAuthCode) return { hasAuthCode };
      else if (token) {
        handleApiSuccess(res, setUser);
        setToken(token);
        return await getUser();
      }
    } catch (err) {
      const e = handleApiError(err, setUser, {
        401: 'Nombre de usuario o password inválidos',
        422: 'Debes ingresar email y password',
      });
      if (401 === e.status) logout();
      return false;
    }
  };
  
  const logout = () => {
    clearToken();
    clearSessionUser();
    const { loaded, loading, status, statusText, error } = user;
    setUser({ loaded, loading, status, statusText, error });
    window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`;
  };

  const getUser = async () => {
    try {
      handleApiStart(setUser, 'load', 'user');
      const res = await axios.get(`/user`);
      const newUser = { ...res.data.data, userStatus: res.data.data.status, validatedAt: Date.now() };
      handleApiSuccess(res, setUser, newUser);
      setSessionUser(newUser);
      return newUser;
    } catch (err) {
      const e = handleApiError(err, setUser);
      if ([404, 401, 422].includes(e.status) && user.id) {
        logout();
      }
    }
  };

  const otpReset = async (otpForm) => {
    try {
      const res = await axios.post(`/otp/reset`, { otpForm });
      return {};
    } catch (err) {
      const e = handleApiError(err, setUser, {
        422: 'No se logró resetear',
      });
      return false;
    }
  };

  const otpValidate = async (otpForm) => {
    try {
      handleApiStart(setUser, 'login');
      const res = await axios.post(`/otp/validate`, { otpForm });
      handleApiSuccess(res, setUser);
      setToken(res.data.data.token);
      return await getUser();
    } catch (err) {
      const e = handleApiError(err, setUser, {
        401: 'Código inválido',
        422: 'Debes ingresar el código de 6 dígitos',
      });
      if (401 === e.status) logout();
      return false;
    }
  };

  useMemo(() => {
    if (!user.validatedAt || Date.now() - user.validatedAt > 5 * 60 * 1000) {
      getUser();
    }
    // trigger getUser on load if should revalidate
  }, []);
  return { user, actions: { getUser, login, logout, otpValidate, otpReset } };
};

export default auth;

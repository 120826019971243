import React, { useState, Fragment } from 'react';
import classnames from 'classnames';

import { makeEnum, timeAgo, formatDateTime } from '../utils';
import { orderTypes, orderStatusLabel, orderStatusColors } from '../constants';
import { List, Header, Body, Row, Cell } from './common/List';

import css from './CourierOrderList.css';

const mapUrl = ({ locationAddress, locationLat, locationLon }) => {
  if (!locationAddress) return '';
  let url = `https://maps.google.com?q=${locationAddress.replace(/\s+/g, '+')}`;
  if (locationLat && locationLon) url += `&ll=${locationLat},${locationLon}`;
  return url;
};

const CourierOrderList = (props) => {
  const [active, setActive] = useState();
  const { orders } = props;
  return (
    <List className={classnames(css.root)}>
      <Header>
        <Cell head>Info</Cell>
        <Cell head>Paquete</Cell>

        <Cell head>Entrega</Cell>
        <Cell head>Extras</Cell>
      </Header>
      <Body>
        {orders &&
          orders.map((order, index) => {
            return (
              <Row key={`orders-row-${index}`} onClick={() => setActive(index)}>
                <Cell className={css.info} top>
                  <List>
                    <Body top>
                      <Row>
                        <Cell colSpan={2} style={{ fontWeight: 'bold' }}>
                          {orderTypes(order.orderType)}
                          {`: `}
                          {orderStatusLabel(order)}
                        </Cell>
                      </Row>
                      <Row>
                        <Cell head>ID</Cell>
                        <Cell>{order.id}</Cell>
                      </Row>

                      <Row>
                        <Cell head>Creación</Cell>
                        <Cell>{formatDateTime(order.createdAt) || '...'}</Cell>
                      </Row>
                    </Body>
                  </List>
                </Cell>

                <Cell className={css.package} top>
                  <List>
                    <Body>
                      <Row>
                        <Cell head>Retira</Cell>
                        <Cell>{order.pickupName}</Cell>
                      </Row>
                      <Row>
                        <Cell head>Dimensiones</Cell>
                        <Cell>
                          {order.width} &times; {order.height} &times; {order.length}
                        </Cell>
                      </Row>
                    </Body>
                  </List>
                </Cell>

                <Cell className={css.delivery} top>
                  {order.lockerId ? (
                    <List>
                      <Body top>
                        <Row>
                          <Cell head>Ubicación</Cell>
                          <Cell>{order.locationName}</Cell>
                        </Row>
                        {active == index ? (
                          <Fragment>
                            <Row>
                              <Cell head>Dirección</Cell>
                              <Cell>
                                <a target="_blank" href={mapUrl(order)}>
                                  {order.locationAddress}
                                </a>
                              </Cell>
                            </Row>
                            <Row>
                              <Cell head>Cómo llegar</Cell>
                              <Cell style={{ width: 300 }}>{order.locationDescription}</Cell>
                            </Row>
                            <Row>
                              <Cell head>Código entrega</Cell>
                              <Cell style={{ fontWeight: 'bold' }}>{order.dropCode}</Cell>
                            </Row>
                          </Fragment>
                        ) : (
                          <Row>
                            <Cell>
                              <a href="#">expandir...</a>
                            </Cell>
                          </Row>
                        )}
                      </Body>
                    </List>
                  ) : (
                    'Esperando asignación de locker'
                  )}
                </Cell>
                <Cell className={css.extras} top>
                  <List>
                    <Body>
                      {Object.keys(order.extras).length ? (
                        Object.keys(order.extras).map((k, index) => (
                          <Row key={`extras-row-${index}`}>
                            <Cell head>{k}</Cell>
                            <Cell>{order.extras[k]}</Cell>
                          </Row>
                        ))
                      ) : (
                        <Row key={`extras-row-${index}`}>
                          <Cell head>...</Cell>
                        </Row>
                      )}
                    </Body>
                  </List>
                </Cell>
              </Row>
            );
          })}
      </Body>
    </List>
  );
};

export default CourierOrderList;

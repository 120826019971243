import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiMenu, FiSettings } from 'react-icons/fi';
import classnames from 'classnames';

import { routes } from '../routes';
import { roles, roleLabels } from '../constants';
import { can } from '../can';

import css from './Nav.css';

export const Nav = (props) => {
  const [open, setOpen] = useState();

  const toggle = () => {
    setOpen(!open);
  };
  const onClick = (e) => {
    if (e.target.tagName == 'A') setOpen(false);
  };

  const { current, className, user, logout } = props;

  return (
    <nav className={classnames(className, css.root)} onClick={onClick}>
      <span className={css.toggle} onClick={toggle}>
        {open ? <FiX size={32} /> : <FiMenu size={32} />}
      </span>
      <ul className={classnames(css.menuList, { [css.menuOpen]: open })}>
        {can(user, 'index', 'orders') && <MenuItem label="Órdenes" path={routes('orders')} current={current} />}
        {can(user, 'index', 'lockers') && (
          <MenuItem label="Lockers" path={routes('lockers')} current={current}>
            {can(user, 'index', 'locations') && (
              <MenuItem label="Ubicaciones" path={routes('locations')} current={current} />
            )}
          </MenuItem>
        )}
        {can(user, 'index', 'reports') && <MenuItem label="Reportes" path={routes('reports')} current={current} />}
        <div className={css.spacer} />
        <MenuItem
          className={css.rightAlign}
          label={
            <Fragment>
              <span className={css.navTitle}>
                {user.clientName ? `${roleLabels(user.role)} – ${user.clientName}` : roleLabels(user.role)}
              </span>
              &nbsp;&nbsp;
              <FiSettings size={22} className={css.icon} />
            </Fragment>
          }
          path={routes('settings')}
          current={current}>
          <MenuItem label="Mi cuenta" path={routes('settings')} current={current} />
          {can(user, 'index', 'clients') && <MenuItem label="Clientes" path={routes('clients')} current={current} />}
          {user.role == roles('client') && (
            <MenuItem label="Configuración" path={routes('client', { id: user.clientId })} current={current} />
          )}
          {can(user, 'index', 'segments') && <MenuItem label="Grupos" path={routes('segments')} current={current} />}
          {can(user, 'index', 'users') && <MenuItem label="Usuarios" path={routes('users')} current={current} />}
          <li className={css.line} />
          <MenuItem label="Log Out" onClick={logout} current={current} />
        </MenuItem>
      </ul>
    </nav>
  );
};

const MenuItem = (props) => {
  const { path, onClick = () => {}, className, current, children } = props;
  const isActive = current.includes(path);
  // it's not an array if there is only one child, and we make it an array
  const isActiveChild =
    children &&
    children.length &&
    [].concat(children).some((child) => child && child.props && current.includes(child.props.path));

  return (
    <li className={classnames(css.menuItem, { [css.active]: isActive || isActiveChild, [className]: className })}>
      {path ? <Link to={props.path}>{props.label}</Link> : <a onClick={onClick}>{props.label}</a>}
      {props.children && <ul className={css.menuList}>{props.children}</ul>}
    </li>
  );
};
export default Nav;

import { useState, useRef, useEffect } from 'react';
import useSWR from 'swr';
import { produce, current } from 'immer';
import debounce from 'lodash/debounce';

import { fetcher, getToken } from './utils';
import { string } from 'prop-types';
import { clear } from 'dom-helpers';

const allowedParams = ['name', 'address', 'lat', 'lon', 'description'];

const getUnits = ([url, params, token]) => {
  return fetcher(url, { params, token });
};

// exporting this function to provide Select with a list that updates
export const loadUnits = async (locationId, params) => {
  if (!locationId) return Promise.resolve(false);
  const url = `/locations/${locationId}/units`;
  const data = await getUnits([url, params, getToken()]);
  return data.data;
};

const createUnit = async (url, unit, token) => {
  return fetcher(url, { method: 'POST', data: { unit }, token });
};

const updateUnit = debounce(async (url, { id, ...unit }, token) => {
  return await fetcher(`${url}/${id}`, { method: 'PATCH', data: { unit }, token });
}, 500);

const deleteUnit = async (url, id, token) => {
  return fetcher(`${url}/${id}`, { method: 'DELETE', token });
};

export const useUnits = (locationId, params) => {
  const [errorMessage, setErrorMessage] = useState();
  const [errors, setErrors] = useState({});
  const timer = useRef({});

  const url = `/locations/${locationId}/units`;

  const { data: units, error, isLoading, mutate } = useSWR(locationId ? [url, params, getToken()] : null, getUnits);

  const removeUnitError = (unitId) => {
    setErrors((errors) => {
      const filtered = Object.entries(errors).filter((e) => parseInt(e[0]) !== unitId);
      return Object.fromEntries(filtered);
    });
  };
  const addError = (unitId, error) => {
    const err = typeof error === 'string' ? error : error.response.data.message;
    setErrors((errors) => ({ ...errors, [unitId]: err }));
    clearTimeout(timer.current[unitId]);

    timer.current[unitId] = setTimeout(() => {
      removeUnitError(unitId);
      setErrorMessage(null);
    }, 5000);
  };

  useEffect(() => {
    // clear all timers on unmount
    return () => Object.values(timer.current).map((v) => clearTimeout(v));
  }, []);

  const create = async (unit) => {
    try {
      return await mutate(createUnit(url, unit, getToken()), {
        optimisticData: (oldData) =>
          produce(oldData, (data) => {
            data.data.push({ ...unit, temp: true });
            return data;
          }),
        populateCache: (newUnit, oldData) =>
          produce(oldData, (data) => {
            data.data.push(newUnit.data);
            return data;
          }),

        revalidate: false,
      });
    } catch (err) {
      console.error('error creating unit', err);
      addError('create', err);
    }
  };

  const update = async (unit) => {
    try {
      return await mutate(updateUnit(url, unit, getToken()), {
        optimisticData: (oldData) => {
          removeUnitError(unit.id);
          return produce(oldData, (data) => {
            Object.assign(
              data.data.find((u) => u.id === unit.id),
              unit
            );
            return data;
          });
        },
        populateCache: false,
        revalidate: false,
      });
    } catch (err) {
      console.error('error updating unit', err);
      addError(unit.id, err);
    }
  };

  const remove = async (unitId) => {
    try {
      return await mutate(deleteUnit(url, unitId, getToken()), {
        optimisticData: (oldData) =>
          produce(oldData, (data) => {
            data.data = data.data.filter((u) => u.id !== unitId);
            return data;
          }),
        populateCache: false,
        revalidate: false,
      });
    } catch (err) {
      console.error('error deleting unit', err, err.response);
      addError(unitId, err);
    }
  };

  return {
    units,
    create,
    update,
    remove,
    isLoading,
    errorMessage: error?.response?.data?.message || errorMessage,
    errors,
  };
};

import React from 'react';
import Case from 'case';
import { FiLoader } from 'react-icons/fi';

import css from './Progress.css';

export const Progress = ({ label, children }) => {
  return (
    <div>
      <FiLoader className={css.rotate} />
      {label ? Case.sentence(label) : null}
      {children}
    </div>
  );
};
export default Progress;

const debug = require('debug')('mtk:newApi');
import { useMemo } from 'react';

import {
  useItemState,
  indexRoute,
  newItemRoute,
  getItemAction,
  updateItemAction,
  deleteItemAction,
  handleApiStart,
  handleApiSuccess,
  handleApiError,
  axios,
} from './utils';

const createAllowedParams = ['name', 'scopeToClientId'];
const allowedParams = ['name'];
const requiredParams = ['name'];

const segments = {
  index: indexRoute('/segments/'),
  newItem: newItemRoute('/segments/', { allowedParams: createAllowedParams }),
  item: (id, params, { history, lazy } = {}) => {
    const url = `/segments/${id}`;
    const [item, setItem] = useItemState(url, params);
    const actions = {};

    actions.get = getItemAction(url, { params, setItem });
    (actions.update = updateItemAction(url, {
      allowedParams,
      requiredParams,
      item,
      setItem,
      onSuccess: () => actions.get(null, 'update'),
    })),
      (actions.delete = deleteItemAction(url, { setItem, history }));

    actions.clientAssign = async (clientId) => {
      try {
        handleApiStart(setItem, 'assign', 'client');
        const res = await axios.post(`/clients/${clientId}/assign/segment/${id}`);
        handleApiSuccess(res, setItem);
        actions.get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };
    actions.clientUnassign = async (clientId) => {
      try {
        handleApiStart(setItem, 'unassign', 'client');
        const res = await axios.delete(`/clients/${clientId}/assign/segment/${id}`);
        handleApiSuccess(res, setItem);
        actions.get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };
    actions.lockerAssign = async (lockerId) => {
      try {
        handleApiStart(setItem, 'assign', 'locker');
        const res = await axios.post(`/lockers/${lockerId}/assign/segment/${id}`);
        handleApiSuccess(res, setItem);
        actions.get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };
    actions.lockerUnassign = async (lockerId) => {
      try {
        handleApiStart(setItem, 'unassign', 'locker');
        const res = await axios.delete(`/lockers/${lockerId}/assign/segment/${id}`);
        handleApiSuccess(res, setItem);
        actions.get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };
    actions.userAssign = async (userId) => {
      try {
        handleApiStart(setItem, 'assign', 'user');
        const res = await axios.post(`/users/${userId}/assign/segment/${id}`);
        handleApiSuccess(res, setItem);
        actions.get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };
    actions.userUnassign = async (userId) => {
      try {
        handleApiStart(setItem, 'unassign', 'user');
        const res = await axios.delete(`/users/${userId}/assign/segment/${id}`);
        handleApiSuccess(res, setItem);
        actions.get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    useMemo(() => {
      if (!lazy) actions.get();
    }, []);

    return { item, actions };
  },
};

export default segments;

import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { FiLoader } from 'react-icons/fi';

import css from './Button.css';

const Button = (props) => {
  const {
    to,
    href,
    onClick = () => {},
    children,
    primary,
    destructive,
    large,
    small,
    invert,
    disabled,
    noMargin,
    confirm,
    loading,
    className,
    ...otherProps
  } = props;
  const [timer, setTimer] = useState();
  const [confirmed, setConfirmed] = useState();
  const handleClick = (e) => {
    if (disabled || !onClick) return;
    if (confirm && !confirmed) {
      setConfirmed(true);
      setTimer(
        setTimeout(() => {
          setConfirmed(false);
        }, 5000)
      );
    } else {
      return onClick(e);
    }
  };
  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer]);

  const classNames = classnames(css.root, className, {
    [css.primary]: primary,
    [css.destructive]: destructive,
    [css.large]: large,
    [css.small]: small,
    [css.invert]: invert,
    [css.disabled]: disabled,
    [css.noMargin]: noMargin,
    [css.warning]: confirmed,
  });
  return to ? (
    <Link className={classNames} to={to} {...otherProps}>
      {loading && <FiLoader className={css.loading} />}
      {children}
    </Link>
  ) : href ? (
    <a href={href} onClick={handleClick} className={classNames} {...otherProps}>
      {loading && <FiLoader className={css.loading} />}
      {confirmed ? confirm : children}
    </a>
  ) : (
    <button onClick={handleClick} className={classNames} {...otherProps}>
      {loading && <FiLoader className={css.loading} />}
      {confirmed ? confirm : children}
    </button>
  );
};

export default Button;

import { useMemo } from 'react';

import { useItemState, indexRoute, newItemRoute, getItemAction, updateItemAction, deleteItemAction } from './utils';

const allowedParams = ['name', 'address', 'lat', 'lon', 'description', 'countryCode', 'timezone'];

const locations = {
  index: indexRoute('/locations/'),
  newItem: newItemRoute('/locations/', { allowedParams }),
  item: (id, params, { history, lazy } = {}) => {
    const url = `/locations/${id}`;
    const [item, setItem] = useItemState(url, params);

    const get = getItemAction(url, { params, setItem });

    const update = updateItemAction(url, {
      allowedParams,
      item,
      setItem,
      onSuccess: () => get(null, 'update'),
    });
    const del = deleteItemAction(url, { setItem, history });

    useMemo(() => {
      if (!lazy) get();
    }, []);

    return { item, actions: { get, update, delete: del } };
  },
};

export default locations;

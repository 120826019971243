import React, { useState, useEffect, useMemo } from 'react';
import { ascending } from 'd3';

import BarChart from './graphs/BarChart';

const sortBy = (array, property) => {
  return array.sort(function (x, y) {
    return ascending(x[property], y[property]);
  });
};

export default (props) => {
  const { data, graphSize } = props;

  const CustomTooltip = ({ indexValue, value }) => {
    const description = value != 1 ? `${value} órdenes` : `${value} orden`;
    return (
      <div>
        <strong>{`Puerta de ${indexValue} cm`}</strong>
        <br />
        <span>{description}</span>
      </div>
    );
  };

  const parsedData = useMemo(() => {
    let newData = [];
    data.forEach((d) => {
      const pos =
        newData.length > 0
          ? newData
              .map(function (e) {
                return e.height;
              })
              .indexOf(d.height)
          : -1;
      if (pos !== -1) {
        newData[pos].value = newData[pos].value + d.count;
      } else {
        const obj = {};
        obj.height = d.height;
        obj.value = d.count;
        newData.push(obj);
      }
    });
    newData = sortBy(newData, 'height');
    return newData.map((d) => {
      d.height = d.height.toString();
      return d;
    });
  }, [data]);

  return (
    <BarChart
      data={parsedData}
      customTooltip={CustomTooltip}
      {...graphSize}
      style={{ padding: 0.6, colors: ['#c6d8d4'], margin: { top: 80, right: 40, bottom: 60, left: 40 } }}
      indexBy={'height'}
      title={`Distribución de órdenes completadas por puerta`}
      axisTitles={{ left: `Nº de órdenes`, bottom: `Altura de puerta [cm]` }}
    />
  );
};

import React from 'react';
import classnames from 'classnames';

import css from './StatusBullet.css';

export const StatusBullet = ({ on, semi }) => (
  <span className={classnames(css.root, { [css.on]: on, [css.semi]: semi })} />
);

export default StatusBullet;

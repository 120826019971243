const debug = require('debug')('mtk:react:user');

import React, { Fragment } from 'react';

import { FiArrowUpRight, FiTrash2 } from 'react-icons/fi';

import { roles, roleLabels, noPasswordRoles } from '../constants';
import { routes } from '../routes';
import { can } from '../can';
import { timeout } from '../utils';

import api from '../utils/api';
import { loadUnits } from '../utils/api/units';

import { useForm } from '../utils/form';

import { Loading, NotFound } from './PageStatus';

import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/Button';

import Input from '../components/common/Input';
import TextArea from '../components/common/TextArea';
import Select, { objectToOptions, indexToOptions } from '../components/common/Select';

import Link from '../components/common/Link';
import { List, Header, Body, Row, Cell } from '../components/common/List';

import grid from '../styles/grid.css';
import css from './User.css';

const userFields = {
  name: ['text'],
  email: ['email', true],
  role: ['text', true],
  password: ['password', false],
  password_confirm: [
    'password',
    false,
    {
      validate: (v, values) => (v == values.get('password') ? null : 'las contraseñas deben coincidir'),
    },
  ],
  phone: ['tel'],
  clientName: ['text'],
  clientId: ['number'],
  status: ['text'],
  segments: ['array'],
  extras: ['json'],
  locationId: ['number'],
  unit: {
    id: ['number'],
  },
  nationalId: ['rut'],
};

const User = ({ match, history }) => {
  const { id } = match.params;
  const { user, actions: userActions } = api.auth();
  const { item, actions } = api.users.item(id, { relations: 'all' }, { history });

  const { actions: segmentsActions } = api.segments.index(null, { lazy: true });
  const { actions: locationsActions } = api.locations.index(null, { lazy: true });

  const userData = item.data ? item.data.toJS() : {};
  const form = useForm(userFields, item.data, { submit: actions.update });

  const getSegments = async (search) => {
    if (!item.loaded) {
      await timeout(200);
      return await getSegments(search);
    }

    const index = await segmentsActions.get({ search, scopeToClientId: userData.clientId, perPage: 50 });
    return indexToOptions(index);
  };

  const onSegmentAdd = (name, value) => {
    actions.segmentAssign(value);
  };

  const onSegmentRemove = async (id) => {
    await actions.segmentUnassign(id);
  };

  const onDelete = async () => {
    await actions.delete();
  };
  const onChangePassword = async () => {
    if (form.getValue('password') == form.getValue('password_confirm')) {
      if (await actions.changePassword(form.getValue('password'))) {
        form.clearValues(['password', 'password_confirm']);
      }
    }
  };

  const getLocations = async (search) => {
    const index = await locationsActions.get({ search, perPage: 50 });
    return indexToOptions(index, { value: 'id', label: 'name' });
  };

  const locationId = form.getValue(['locationId']);

  const getUnits = async (search) => {
    const index = await loadUnits(locationId, { search, perPage: 50 });

    return indexToOptions(index, { value: 'id', label: 'name' });
  };

  const onUnitAdd = async (name, value, option) => {
    await actions.update({ unitId: value });
    await actions.get();
  };

  const resetOpt = async () => {
    const otpReset = await userActions.otpReset({
      id: userData.id,
    });
    window.location.reload(false);
  };

  const isAdmin = user.role == 'admin';
  const readOnly = !can(user, 'update', 'users', userData);

  const lockers =
    item.data && item.data.has('segments')
      ? item.data
          .get('segments')
          .groupBy((s) => s.get('lockerName'))
          .toList()
          .toJS()
      : [];
  const segments =
    item.data && item.data.has('segments')
      ? item.data
          .get('segments')
          .groupBy((s) => s.get('segmentId'))
          .map((s) => s.first())
          .toList()
      : false;

  if (item.status == 404) return <NotFound />;
  if (!item.loaded) return <Loading />;

  return (
    <main>
      <PageHeader
        section="users"
        title={item.loaded ? userData.name || userData.email : '...'}
        dirty={form.dirty}
        autoSave
        {...item}>
        {can(user, 'delete', 'users', userData) && (
          <Button destructive confirm="¿Eliminar este Usuario?" onClick={onDelete}>
            Eliminar
          </Button>
        )}
      </PageHeader>

      <div className={grid.grid}>
        <section className={grid.half}>
          <Input name="name" label="Nombre" clickToEdit readOnly={readOnly} form={form} />

          <Input
            name="email"
            label="E-Mail"
            clickToEdit
            placeholder="user@maletek.cl"
            type="email"
            readOnly={readOnly}
            form={form}
          />

          <Input name="phone" label="Teléfono Movil" type="tel" readOnly={readOnly} clickToEdit form={form} />

          <Input name="nationalId" label="RUT" readOnly={readOnly} clickToEdit form={form} />


          {/* ToDo: Add check if item.data.clientMode = housing when available */}
          {userData.role == roles('resident') && (
            <>
              <h3>Unidad Housing</h3>
              {form.getValue(['unit', 'id']) ? (
                <div className={css.row}>
                  <p>
                    <small>
                      Seleccionada: <strong>{userData.unit?.name}</strong>{' '}
                      {userData.unit?.locationName && (
                        <>
                          {' '}
                          en <strong>{userData.unit.locationName} </strong>
                        </>
                      )}
                    </small>
                  </p>
                  <Button small onClick={() => form.setValue(['unit', 'id'], null)}>
                    Cambiar
                  </Button>
                </div>
              ) : (
                <>
                  <Select
                    readOnly={readOnly}
                    name="locationId"
                    label="Ubicación"
                    isClearable={false}
                    form={form}
                    loadOptions={getLocations}
                    placeholder="Busca una ubicación para elegir unidades"
                  />

                  <Select
                    readOnly={readOnly || !locationId}
                    name={['unit', 'id']}
                    label="Unidad"
                    isClearable={false}
                    onSelectOption={onUnitAdd}
                    form={form}
                    loadOptions={!!locationId ? getUnits : false}
                    placeholder="No asignada"
                  />
                </>
              )}
            </>
          )}
          <h3>Acceso a Lockers</h3>
          {roles('admin') == userData.role ? (
            <p>
              <small>Usuario administrador tiene acceso a todos los lockers del sistema.</small>
            </p>
          ) : roles('client') == userData.role ? (
            <p>
              <small>
                Usuario administrador de cliente tiene acceso a todos los lockers a los que el cliente tiene acceso.
              </small>
            </p>
          ) : (
            <Fragment>
              {can(user, 'update', 'segments') ? (
                <Select
                  isMulti
                  readOnly={readOnly}
                  isClearable={false}
                  hideValues
                  label={'Agregar a grupo'}
                  name={'segments'}
                  value={
                    segments
                      ? indexToOptions(segments, {
                          value: 'segmentId',
                          label: 'segmentName',
                        })
                      : ''
                  }
                  onChange={() => {}}
                  onSelectOption={onSegmentAdd}
                  onDeselectOption={onSegmentRemove}
                  loadOptions={getSegments}
                  noOptionsMessage={(a) => {
                    return a.inputValue ? `No hay grupos conteniendo "${a.inputValue}"` : 'No hay grupos por agregar.';
                  }}
                />
              ) : null}
              <List>
                <Header>
                  <Cell head>Locker</Cell>
                  <Cell head>{can(user, 'update', 'segments') ? 'Grupo' : null}</Cell>
                </Header>
                <Body>
                  {lockers.length ? (
                    lockers.map((locker, i) => (
                      <Row key={`locker-${i}`}>
                        <Cell top>
                          <Link to={routes('locker', { id: locker.lockerId })}>{locker[0].lockerName}</Link>
                        </Cell>
                        <Cell top>
                          {locker.map((seg, j) =>
                            can(user, 'update', 'segments') ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                                key={`segment-${j}`}>
                                {seg.segmentName}

                                <div>
                                  <Link
                                    style={{ fontSize: 'var(--font-size-small)' }}
                                    inline
                                    tooltip={'editar grupo'}
                                    to={routes('segment', { id: seg.segmentId })}>
                                    <FiArrowUpRight />
                                  </Link>
                                  {' | '}
                                  <Link
                                    style={{ fontSize: 'var(--font-size-small)' }}
                                    inline
                                    tooltip={'remover usuario de este grupo'}
                                    onClick={() => onSegmentRemove(seg.segmentId)}>
                                    <FiTrash2 />
                                  </Link>
                                </div>
                              </div>
                            ) : null
                          )}
                        </Cell>
                      </Row>
                    ))
                  ) : (
                    <Row>
                      <Cell>Este usuario no tiene acceso a lockers.</Cell>
                    </Row>
                  )}
                </Body>
              </List>
            </Fragment>
          )}
        </section>
        <section className={grid.half}>
          <Input readOnly name="clientName" label="Cliente" form={form} />
          <Input
            readOnly
            name="role"
            label="Rol"
            onChange={() => {}}
            value={userData.role ? roleLabels(userData.role) : ''}
          />
          <Select
            label="Estado"
            name="status"
            options={objectToOptions({ active: 'Activo', inactive: 'Inactivo' })}
            isClearable={false}
            readOnly={readOnly}
            form={form}
          />

          {!noPasswordRoles.has(userData.role) && (
            <>
              <h3>Cambio de Contraseña</h3>
              <Input
                name="password"
                label={'Nueva contraseña'}
                placeholder={'•••••••••'}
                type="password"
                skipSubmit
                readOnly={readOnly}
                form={form}
              />
            </>
          )}
          {form.getValue('password') && (
            <Input
              name="password_confirm"
              label={'Confirmar contraseña'}
              placeholder={'•••••••••'}
              type="password"
              skipSubmit
              readOnly={readOnly}
              form={form}
            />
          )}
          {form.getValue('password') && (
            <Button
              disabled={form.getValue('password') !== form.getValue('password_confirm')}
              onClick={onChangePassword}>
              Cambiar contraseña
            </Button>
          )}

          {item.loaded && userData.client2fa && (
            <Fragment>
              <p>{userData.otpValidated ? 'Resetear código para doble autenticación' : 'Aún no verifica 2FA'}</p>

              <Button
                disabled={!isAdmin || !userData.otpValidated}
                confirm="¿Esta seguro de crear el nuevo código?"
                onClick={resetOpt}>
                Reset OTP
              </Button>
            </Fragment>
          )}
          <TextArea name="extras" readOnly={readOnly} label="Extras" form={form} />
        </section>
      </div>
    </main>
  );
};

export default User;

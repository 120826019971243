const debug = require('debug')('mtk:clients');

import React from 'react';

import { Link } from 'react-router-dom';

import api from '../utils/api';
import { useQuery } from '../utils/query';

import { routes } from '../routes';
import { can } from '../can';

import { Loading } from './PageStatus';
import PageHeader from '../components/common/PageHeader';
import { List, Header, Body, Row, Cell } from '../components/common/List';
import Button from '../components/common/Button';
import Pagination from '../components/common/Pagination';
import classnames from 'classnames';
import { modeLabels } from '../constants';

import css from './Clients.css';

const Clients = (props) => {
  const query = useQuery(props.location, props.history);
  const { user } = api.auth();
  const { index, actions } = api.clients.index(query.obj);

  return (
    <main>
      <PageHeader title="Clientes">
        {can(user, 'create', 'clients') && (
          <Button noMargin to={routes('newClient')}>
            Nuevo
          </Button>
        )}
      </PageHeader>

      {index.loaded ? (
        <List>
          <Header>
            <Cell head>ID</Cell>
            <Cell head>Nombre</Cell>
            <Cell head>Modo</Cell>
            <Cell head>2FA</Cell>
            <Cell head>Códigos personalizados</Cell>
            <Cell head>Devolucion</Cell>
            <Cell head>Auto revocación</Cell>
          </Header>
          <Body>
            {index.data.toJS().map((client, i) => {
              const to = routes('client', { id: client.id });
              const prefs = client.preferences;
              return (
                <Row key={`client-row-${i}`}>
                  <Cell>
                    <Link to={to}>{client.id}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>{client.name}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>
                      {prefs.mode && prefs.mode == 'housing' ? (
                        <span className={classnames(css.optionType, [css['border-warning']])}>
                          {modeLabels('housing')}
                        </span>
                      ) : (
                        <span className={classnames(css.optionType, [css['border-enabled']])}>
                          {modeLabels('normal')}
                        </span>
                      )}
                    </Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>
                      {prefs.twoFactorAuth ? (
                        <span className={classnames(css.optionType, css.enabled)}>Activado</span>
                      ) : (
                        <span className={classnames(css.optionType)}>Desactivado</span>
                      )}
                    </Link>
                  </Cell>

                  <Cell>
                    <Link to={to}>
                      {prefs.customDropCodeEnabled ? (
                        <span className={classnames(css.optionType, [css['enabled']])}>Drop</span>
                      ) : (
                        ''
                      )}

                      {prefs.reversalCodeEnabled ? (
                        <span className={classnames(css.optionType, [css['enabled']])}>Reversal</span>
                      ) : (
                        ''
                      )}

                      {prefs.courierPickupCodeEnabled ? (
                        <span className={classnames(css.optionType, [css['enabled']])}>Courier pickup</span>
                      ) : (
                        ''
                      )}
                      {!prefs.customDropCodeEnabled && !prefs.courierPickupCodeEnabled && !prefs.reversalCodeEnabled ? (
                        <span className={classnames(css.optionType)}>Desactivado</span>
                      ) : (
                        ''
                      )}
                    </Link>
                  </Cell>

                  <Cell>
                    <Link to={to}>
                      {prefs.returnsEnabled ? (
                        <span className={classnames(css.optionType, css.warning)}>Habilitada</span>
                      ) : (
                        <span className={classnames(css.optionType)}>Desactivada</span>
                      )}

                      {prefs.returnsEnabled && prefs.autoCancelReturnEnabled ? (
                        <div>
                          <span>
                            Auto-cancelación en <b>{prefs.autoCancelReturnHours}</b> hrs.
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>
                      {prefs.autoReversalEnabled ? (
                        <span className={classnames(css.optionType, [css['danger']])}>Habilitada</span>
                      ) : (
                        <span className={classnames(css.optionType)}>Desactivada</span>
                      )}

                      {prefs.autoReversalEnabled ? (
                        <div>
                          <span>
                            Auto-revocación en <b>{prefs.autoReversalHours}</b> hrs.
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </Cell>
                </Row>
              );
            })}
          </Body>
        </List>
      ) : (
        <Loading compact />
      )}
      {index.pageInfo && <Pagination {...index.pageInfo.toJS()} onChange={query.setPage} />}
    </main>
  );
};

export default Clients;

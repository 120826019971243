const debug = require('debug')('mtk:react:segment');

import React, { Fragment } from 'react';
import { FiArrowUpRight, FiTrash2 } from 'react-icons/fi';
import css from './Segment.css';
import classnames from 'classnames';

import { routes } from '../routes';
import { can } from '../can';

import api from '../utils/api';
import { useForm } from '../utils/form';

import { Loading, NotFound } from './PageStatus';

import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import Select, { indexToOptions } from '../components/common/Select';
import Link from '../components/common/Link';
import { List, Body, Row, Cell } from '../components/common/List';

import Info from '../components/Info';

import grid from '../styles/grid.css';

const segmentFields = {
  name: ['text', true],
  clients: ['array'],
  lockers: ['array'],
  users: ['array'],
};

const Segment = ({ match, history }) => {
  const { id } = match.params;
  const { user } = api.auth();
  const { item, actions } = api.segments.item(id, {}, { history });

  const segment = item.data ? item.data.toJS() : {};
  const isScoped = !!segment.scopeToClientId;

  const { actions: clientsActions } = api.clients.index(null, { lazy: true });
  const { actions: lockersActions } = api.lockers.index(null, { lazy: true });
  const { actions: usersActions } = api.users.index(null, { lazy: true });

  // form
  const form = useForm(segmentFields, item.data, { submit: actions.update });

  // ACTIONS

  const getClients = async (search) => {
    const clientsIndex = await clientsActions.get({ search, perPage: 50 });
    return indexToOptions(clientsIndex);
  };

  const onClientAssign = (name, value) => {
    actions.clientAssign(value);
  };

  const getLockers = async (search) => {
    const lockerIndex = await lockersActions.get({ search, clientId: segment.scopeToClientId, perPage: 50 });
    return indexToOptions(lockerIndex);
  };

  const onLockerAssign = (name, value) => {
    actions.lockerAssign(value);
  };

  const getUsers = async (search) => {
    const users = await usersActions.get({ search, clientId: segment.scopeToClientId, perPage: 50 });
    return indexToOptions(users);
  };

  const onUserAssign = (name, value) => {
    actions.userAssign(value);
  };

  const onDelete = async () => {
    await actions.delete();
  };

  const assignedClients = form.getValue('clients') ? form.getValue('clients').toJS() : [];
  const assignedLockers = form.getValue('lockers') ? form.getValue('lockers').toJS() : [];
  const assignedUsers = form.getValue('users') ? form.getValue('users').toJS() : [];

  if (item.status == 404) return <NotFound />;
  if (!item.loaded) return <Loading />;

  return (
    <main>
      <PageHeader
        section="segments"
        title={form.getValue('name')}
        subtitle={
          isScoped ? <h4>Sub-grupo del cliente {segment.scopeToClientName || segment.scopeToClientId}</h4> : null
        }
        dirty={form.dirty}
        autoSave
        {...item}>
        {can(user, 'delete', 'segments') && (
          <Button noMargin confirm="¿Eliminar este Grupo?" onClick={onDelete}>
            Eliminar
          </Button>
        )}
      </PageHeader>

      <div className={grid.grid}>
        <section className={grid.half}>
          <Input name="name" label="Nombre" placeholder="Maletek" form={form} />
        </section>
        <section className={grid.half}>
          {!isScoped ? (
            <Info>
              <p>
                Al remover un locker o cliente de este grupo, será removido de todos los sub-grupos de ese cliente. Esta
                acción no se puede deshacer.
              </p>
            </Info>
          ) : (
            <div />
          )}
        </section>
        <section className={grid.half}>
          <h3>Lockers</h3>
          <Select
            key={'lockers'}
            isMulti
            isClearable={false}
            hideValues
            name="lockers"
            value={indexToOptions(form.getValue('lockers'))}
            onChange={() => {}}
            onSelectOption={onLockerAssign}
            loadOptions={getLockers}
            noOptionsMessage={(a) =>
              a.inputValue ? `No hay lockers conteniendo "${a.inputValue}"` : 'Todos los lockers fueron asociados.'
            }
          />
          <List>
            <Body>
              {assignedLockers.length ? (
                assignedLockers.map((l, i) => (
                  <Row key={`locker-${i}`}>
                    <Cell className={css.cell}>
                      {l.name}
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          actions.lockerUnassign(l.id);
                        }}>
                        <FiTrash2 />
                      </a>
                      <Link inline to={routes('locker', { id: l.id })}>
                        <FiArrowUpRight />
                      </Link>
                    </Cell>
                  </Row>
                ))
              ) : (
                <Row>
                  <Cell>No hay lockers asociados</Cell>
                </Row>
              )}
            </Body>
          </List>
        </section>
        <section className={grid.half}>
          {segment.scopeToClientId ? (
            <Fragment>
              <h3>Usuarios</h3>
              <Select
                key={'users'}
                isMulti
                isClearable={false}
                hideValues
                name="users"
                value={indexToOptions(form.getValue('users'))}
                onChange={() => {}}
                onSelectOption={onUserAssign}
                loadOptions={getUsers}
                noOptionsMessage={(a) => {
                  if (a.inputValue) {
                    return `No se encontraron usuarios conteniendo "${a.inputValue}"`;
                  }
                  return 'Todos los usuarios han sido autorizados.';
                }}
              />
              <List>
                <Body>
                  {assignedUsers.length ? (
                    assignedUsers.map((u, i) => (
                      <Row key={`user-${i}`}>
                        <Cell className={css.cell}>
                          {u.name}
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              actions.userUnassign(u.id);
                            }}>
                            <FiTrash2 />
                          </a>
                          <Link inline to={routes('user', { id: u.id })}>
                            <FiArrowUpRight />
                          </Link>
                        </Cell>
                      </Row>
                    ))
                  ) : (
                    <Row>
                      <Cell>No hay usuarios autorizados</Cell>
                    </Row>
                  )}
                </Body>
              </List>
            </Fragment>
          ) : (
            <Fragment>
              <h3>Clientes</h3>
              <Select
                key={'clients'}
                isMulti
                isClearable={false}
                hideValues
                name="clients"
                value={indexToOptions(form.getValue('clients'))}
                onChange={() => {}}
                onSelectOption={onClientAssign}
                loadOptions={getClients}
                noOptionsMessage={(a) => {
                  if (a.inputValue) {
                    return `No se encontraron clientes conteniendo "${a.inputValue}"`;
                  }
                  return 'Todos los clientes han sido autorizados';
                }}
              />
              <List>
                <Body>
                  {assignedClients.length ? (
                    assignedClients.map((c, i) => (
                      <Row key={`client-${i}`}>
                        <Cell className={css.cell}>
                          {c.name}
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              actions.clientUnassign(c.id);
                            }}>
                            <FiTrash2 />
                          </a>
                          <Link inline to={routes('client', { id: c.id })}>
                            <FiArrowUpRight />
                          </Link>
                        </Cell>
                      </Row>
                    ))
                  ) : (
                    <Row>
                      <Cell>No hay clientes autorizados.</Cell>
                    </Row>
                  )}
                </Body>
              </List>
            </Fragment>
          )}
        </section>
      </div>
    </main>
  );
};

export default Segment;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { capital as titleCase, lower } from 'case';
import { isImmutable, Map } from 'immutable';
import classnames from 'classnames';
import ReactSelect, { createFilter } from 'react-select';
import ReactSelectAsync from 'react-select/async';

import { oneOfPropsIsRequired, isObject, isArray } from '../../utils';

import css from './Select.css';
import cache from '../../utils/api/cache';

const customFilter = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
});

export const indexToOptions = (index, prefs = {}) => {
  if (!index) return [];
  const _index = isImmutable(index) ? index.toJS() : index;
  return _index.map((i) => {
    const el = isImmutable(i) ? i.toJS() : i;
    return { value: i[prefs.value || 'id'], label: i[prefs.label || 'name'] };
  });
};

export const objectToOptions = (obj, prefs) => {
  const disabled = prefs ? prefs.disabled : false;
  return Object.keys(obj).map((k) => {
    const opt = {
      value: k,
      label: titleCase(obj[k]),
    };
    if (disabled && disabled.includes(k)) opt.isDisabled = true;
    return opt;
  });
};
export const valueToOption = (value, options) => {
  return options.find((o) => value == o.value);
};

export const optionToItem = (opt, prefs = {}) => {
  const item = {
    [prefs.value || 'id']: opt.value,
    [prefs.label || 'name']: opt.label,
  };
  return Map(item);
};

export const Select = (props) => {
  const [opts, setOpts] = useState(props.options || []);
  const {
    name,
    form,
    label,
    placeholder = 'Seleccionar...',
    note,
    className,
    onChange = () => {},
    onSelectOption = () => {},
    onDeselectOption = () => {},
    onClear = () => {},

    // for react-select
    options,
    loadOptions,
    defaultOptions,
    cacheOptions,
    isClearable = true,
    readOnly,
    isMulti,
    noOptionsMessage,
    hideValues,

    value: propsValue,
  } = props;

  let value;
  const optionsList = options || opts;

  if (form) {
    value = optionsList
      ? optionsList.find((o) => o.value == form.getValue(name))
      : { value: form.getValue(name), label: form.getValue(name) };
  } else {
    value =
      isObject(props.value) || isArray(props.value) ? props.value : optionsList.find((o) => o.value == props.value);
  }

  const error = form ? form.getError(name) : props.error;
  const touched = form ? form.getTouched(name) : true;

  const commonProps = {
    filterOptions: customFilter,
    classNamePrefix: 'Select',
    defaultOptions: defaultOptions || true,
    isDisabled: readOnly,
    noOptionsMessage,
    placeholder,
    isClearable,
    isMulti,
    id: `react-select-${name}-select`,
    inputId: `react-select-${name}-input`,
  };

  const handleLoadOptions = async (search) => {
    try {
      const options = await loadOptions(search);
      setOpts(options);
      return search
        ? options.filter((o) => (typeof o.label == 'string' ? lower(o.label).includes(lower(search)) : true))
        : options;
    } catch (err) {
      console.error(err);
    }
  };

  /*
   * select onChange handler receives:
   * {string|array} new value
   * {object} event the event object
   * {object} event.option  the selected option object
   * {string} event.action the action
   * one of: select-option,deselect-option,remove-value,pop-value,set-value,clear,create-option
   * see https://react-select.com/props#statemanager-props
   */
  const handleOnChange = (option, event) => {
    let value;
    if (event.action == 'select-option') {
      value = event.option ? event.option.value : option.value;
      onSelectOption(props.name, value, option, event);
    } else if (event.action == 'deselect-option') {
      value = null;
      onDeselectOption(props.name, value, option, event);
    } else if (event.action == 'remove-value') {
      value = null;
      onDeselectOption(props.name, value, option, event);
    } else if (event.action == 'clear') {
      value = null;
      onClear(props.name, value, option, event);
    }

    if (props.onChange) {
      props.onChange(props.name, value, option, event);
    } else if (form) {
      form.setValue(props.name, value);
    } else {
      console.error(
        'No onChange callback set for %s. Either provide an onChange prop or a valid form prop.',
        props.name
      );
    }
  };

  return (
    <div
      className={classnames(css.select, {
        [className]: className,
        [css.withError]: touched && error,
        [css.hideValues]: hideValues,
      })}>
      <label className={css.label}>{label}</label>
      {loadOptions ? (
        <ReactSelectAsync
          name={name}
          value={value}
          loadOptions={handleLoadOptions}
          cacheOptions={cacheOptions}
          onChange={handleOnChange}
          {...commonProps}
        />
      ) : (
        <ReactSelect name={name} value={value} options={options} onChange={handleOnChange} {...commonProps} />
      )}
      <div>
        {error && touched && <span className={css.error}>{error}</span>}
        {note && <span className={css.note}>{note}</span>}
      </div>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  form: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.object]),
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  loadOptions: oneOfPropsIsRequired(['options', 'loadOptions']),
  options: oneOfPropsIsRequired(['options', 'loadOptions']),
  isClearable: PropTypes.bool,
  readOnly: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default Select;

const debug = require('debug')('mtk:react:clientNew');

import React from 'react';

import api from '../utils/api';
import { can } from '../can';
import { useForm } from '../utils/form';
import { modeLabels } from '../constants';

import PageHeader from '../components/common/PageHeader';
import Input from '../components/common/Input';
import Button from '../components/common/Button';
import Select, { objectToOptions } from '../components/common/Select';

import grid from '../styles/grid.css';

const clientFields = {
  name: ['text', true],
  preferences: { mode: ['text', true] },
};

const ClientNew = ({ history }) => {
  const { user } = api.auth();
  const { item, actions } = api.clients.newItem({ history });

  const form = useForm(clientFields, item.data);

  const modeOptions = objectToOptions(modeLabels.obj);

  const handleCreate = async () => {
    if (form.validate()) {
      actions.create(form.getValues());
    }
  };
  const name = form.getValue('name');

  if (!can(user, 'create', 'clients')) return <Forbidden />;

  return (
    <main>
      <PageHeader section="clients" title={`Nuevo Cliente${name ? ': ' + name : ''}`} {...item} />
      <div className={grid.grid}>
        <section className={grid.half}>
          <Input name="name" form={form} label="Nombre" placeholder="Maletek" onEnter={handleCreate} />
          <Select
            name={['preferences', 'mode']}
            isClearable={false}
            options={modeOptions}
            label="Modo"
            form={form}
            note={'Define el modo en el que operará el cliente.'}
            placeholder="..."
          />
          <Button onClick={handleCreate}>Crear cliente</Button>
        </section>
        <section className={grid.half}>
          <div />
        </section>
      </div>
    </main>
  );
};

export default ClientNew;

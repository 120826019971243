import auth from './auth';
import users from './users';
import clients from './clients';
import segments from './segments';
import lockers from './lockers';
import locations from './locations';
import orders from './orders';
import stats from './stats';
import reports from './reports';

export { auth, users, clients, segments, lockers, locations, orders, stats, reports };
export default { auth, users, clients, segments, lockers, locations, orders, stats, reports };

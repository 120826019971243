import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@nivo/core';

import { routes } from '../../routes';

import Legend from './Legend';
import NoDataMessage from './NoDataMessage';

import css from './BarChart.css';

const tickColor = 'lightGray';

export default (props) => {
  const {
    customTick,
    customTooltip,
    keys = ['value'],
    indexBy,
    size,
    style,
    axisTitles,
    enableLabel,
    title,
    legends,
    subtitle = '',
    groupMode = 'stacked',
    tickWithLinks = false,
    defaultActive,
    tickRotation,
  } = props;
  const { colors, colorBy, margin, padding, innerPadding } = style;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [max, setMax] = useState(0);
  const [states, setStates] = useState();
  const ref = useRef();

  let availableData;
  if (groupMode === 'grouped') {
    let sum = 0;
    data.map((elem) => {
      const entries = Object.entries(elem);
      entries.map((e) => {
        if (e[0] !== 'hour') sum = sum + e[1];
      });
    });
    availableData = data.length > 0 ? (sum > 0 ? true : false) : false;
  } else {
    if (keys.includes('value'))
      availableData =
        data.length > 0 ? (data.reduce((sum, current) => sum + current.value, 0) > 0 ? true : false) : false;
    else {
      let sum = 0;
      data.map((elem) => {
        const entries = Object.entries(elem);
        entries.map((e) => {
          if (e[0] !== indexBy && e[0] !== 'label' && e[0] !== 'name') sum = sum + e[1];
        });
      });
      availableData = data.length > 0 ? (sum > 0 ? true : false) : false;
    }
  }

  const commonProps = {
    margin,
    indexBy,
    padding,
    innerPadding,
    groupMode,
    keys,
    enableLabel,
    legends,
    labelTextColor: 'inherit:darker(1.4)',
    labelSkipWidth: 16,
    labelSkipHeight: 16,
    labelFormat: (d) => <tspan y={-10}>{d}</tspan>,
    axisBottom: {
      legend: axisTitles.bottom,
      tickSize: 5,
      tickPadding: 6,
      legendOffset: 40,
      tickMargin: 10,
      legendPosition: 'middle',
      tickRotation: tickRotation ? -60 : 0,
    },
    axisLeft: {
      legend: axisTitles.left,
      legendPosition: 'end',
      tickSize: 0,
      legendOffset: -30,
      format: (tick) => (!Number.isInteger(tick) ? '' : tick),
    },
  };

  const CustomTick = (tick) => {
    const theme = useTheme();
    if (tickRotation)
      return (
        <g transform={`translate(${tick.x},${tick.y + 18})`} key={tick.y}>
          <line stroke={tickColor} strokeWidth={1.5} y1={-18} y2={-12} />
          <text
            className={css.link}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              ...theme.axis.ticks.text,
              fontSize: 9.8,
            }}>
            {tick.value}
          </text>
        </g>
      );
    else if (tickWithLinks)
      return (
        <g transform={`translate(${tick.x},${tick.y + 18})`} key={tick.y}>
          <line stroke={tickColor} strokeWidth={1.5} y1={-18} y2={-12} />
          <text
            className={css.link}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              ...theme.axis.ticks.text,
              fontSize: 11,
            }}>
            <Link to={routes('locker', { id: tick.value })}>{tick.value}</Link>
          </text>
        </g>
      );
    else
      return (
        <g transform={`translate(${tick.x - ref.current.offsetWidth / 54},${tick.y + 18})`} key={tick.y}>
          <line stroke={tickColor} strokeWidth={1.5} y1={-18} y2={-10} />
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              ...theme.axis.ticks.text,
              fontSize: 10,
            }}>
            {tick.value}
          </text>
        </g>
      );
  };

  if (customTick) commonProps.axisBottom.renderTick = CustomTick;
  if (customTooltip) commonProps.tooltip = customTooltip;
  if (colorBy) commonProps.colorBy = (n) => (n.data.format === 'AM' ? colors[1] : colors[0]);
  else commonProps.colorBy = 'id';

  // Loading data and series
  useEffect(() => {
    if (groupMode === 'grouped') {
      let initialStates = {};
      let initialCount = 0;
      keys.map((name) => {
        if (defaultActive.includes(name)) {
          initialStates[name] = true;
          initialCount = initialCount + 1;
        } else initialStates[name] = false;
      });
      setStates(initialStates);
      setCount(initialCount);
      filterData(initialStates);
    } else {
      setData(props.data);
    }
  }, []);

  // Calculating max value
  useEffect(() => {
    if (data.length > 0 && groupMode !== 'grouped') {
      let globalMax = max;
      data.map((current) => {
        let localMax = 0;
        globalMax = Math.max(globalMax, current.value);
      });
      setMax(globalMax);
    } else if (data.length > 0) {
      let globalMax = max;
      data.map((current) => {
        const entries = Object.entries(current);
        entries.map((e) => {
          if (e[0] !== 'hour') {
            let localMax = 0;
            globalMax = Math.max(globalMax, e[1]);
          }
        });
      });
      setMax(globalMax);
    }
  }, []);

  const handleOnClick = (name) => {
    const newStates = states;
    if (count > 1 && states[name]) {
      newStates[name] = !states[name];
      const newCount = count - 1;
      setCount(newCount);
      setStates(newStates);
    } else if (!states[name] && count < keys.length) {
      newStates[name] = !states[name];
      const newCount = count + 1;
      setCount(newCount);
      setStates(newStates);
    }
    filterData();
  };

  const filterData = (initialStates) => {
    const filterStates = states || initialStates;
    const filteredData = props.data.map((e) => {
      let filteredDatum = {};
      Object.keys(e).map((key) => {
        if (filterStates[key] === true || key === 'hour') filteredDatum[key] = e[key];
      });
      return filteredDatum;
    });
    setData(filteredData);
  };

  return (
    <div className={css.root} style={{ width: size.width, height: size.height }} ref={ref}>
      <h2 className={css.graphTitle}>{title}</h2>
      {availableData && groupMode === 'grouped' && (
        <Legend
          series={states}
          colors={Object.values(colors)}
          parentCallback={handleOnClick}
          defaultActive={defaultActive}
        />
      )}
      {availableData ? (
        <Fragment>
          {subtitle !== '' && <h3 className={css.graphSubtitle}>{subtitle}</h3>}
          <ResponsiveBar
            {...commonProps}
            data={data}
            colors={groupMode === 'grouped' ? ({ id }) => colors[id] : colors}
            theme={{
              tooltip: {
                container: {
                  background: 'rgba(255,255,255,0.9)',
                  fontSize: 13,
                  lineHeight: 1.4,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 6,
                },
              },
            }}
          />
        </Fragment>
      ) : (
        <NoDataMessage />
      )}
    </div>
  );
};

import { useMemo } from 'react';

import { useItemState, indexRoute, newItemRoute, getItemAction, deleteItemAction } from './utils';

const allowedParams = ['timeFrom', 'timeTo', 'frequency', 'clientId', 'name'];

const reports = {
  index: indexRoute('/reports/'),
  newItem: newItemRoute('/reports/', { allowedParams }),
  item: (id, params, { history, lazy } = {}) => {
    const url = `/reports/${id}`;
    const [item, setItem] = useItemState(url, params);
    const get = getItemAction(url, { params, setItem });
    const del = deleteItemAction(url, { setItem, history });
    useMemo(() => {
      if (!lazy) get();
    }, []);

    return { item, actions: { get, delete: del } };
  },
};

export default reports;

import React, { useState, useEffect, useMemo } from 'react';
import { ascending } from 'd3';

import BarChart from './graphs/BarChart';

const sortBy = (array, property) => {
  return array.sort(function (x, y) {
    return ascending(x[property], y[property]);
  });
};

export default (props) => {
  const { data, graphSize } = props;

  // Dummy data
  const parsedData = useMemo(() => {
    let newData = [];
    const sizes = [];
    data.forEach((d) => {
      d.doors.forEach((door) => {
        if (!sizes.includes(door.height)) {
          sizes.push(door.height);
          let obj = {};
          obj.height = `${door.height} cm`;
          obj.value = 1;
          obj.label = 'cm';
          newData.push(obj);
        } else {
          const pos = sizes.indexOf(door.height);
          newData[pos].value = newData[pos].value + 1;
        }
      });
    });
    newData = sortBy(newData, 'height');
    newData = newData.sort((a, b) => {
      a = parseInt(a.height.replace(' cm', ''));
      b = parseInt(b.height.replace(' cm', ''));
      return a - b;
    });
    return newData;
  }, [data]);

  const CustomTooltip = ({ indexValue, value }) => {
    const description = value != 1 ? `${value} puertas` : `${value} puerta`;
    return (
      <div>
        <strong>{`Altura ${indexValue}`}</strong>
        <br />
        <span>{description}</span>
      </div>
    );
  };

  return (
    <BarChart
      data={parsedData}
      customTooltip={CustomTooltip}
      style={{ padding: 0.6, colors: ['#9EA6A6'], margin: { top: 80, right: 40, bottom: 60, left: 40 } }}
      {...graphSize}
      indexBy={'height'}
      title={`Número de puertas por tamaño`}
      axisTitles={{ left: `Nº de puertas`, bottom: `Tamaño [cm]` }}
    />
  );
};

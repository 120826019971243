import React from 'react';

import { FiInfo } from 'react-icons/fi';

import css from './Info.css';

export const Info = (props) => {
  return (
    <div className={css.root}>
      <div className={css.icon}>
        <FiInfo size={24} />
      </div>
      <div className={css.content}>{props.children}</div>
    </div>
  );
};

export default Info;

import React, { useState, useEffect, useMemo } from 'react';
import { timeFormat, ascending } from 'd3';

import BarChart from './graphs/BarChart';

const sortBy = (array, property) => {
  return array.sort(function (x, y) {
    return ascending(x[property], y[property]);
  });
};

const format = timeFormat('%H:%M');

export default (props) => {
  const { data, graphSize } = props;

  const CustomTooltip = ({ id, index, indexValue, value, color }) => {
    let intervalEnd = new Date(2019, 0, 1, index, 0, 0);
    intervalEnd.setHours(intervalEnd.getHours() + 1);
    const description = value != 1 ? `${value} órdenes completadas` : `${value} orden completada`;
    return (
      <div>
        <strong style={{ color }}>{`${indexValue} – ${format(intervalEnd)} hrs`}</strong>
        <br />
        <span>{description}</span>
      </div>
    );
  };

  const parsedData = useMemo(() => {
    let newData = data;
    let hours = Array(24)
      .fill(1)
      .map((x, y) => x + y);
    newData = newData.map((d) => {
      const index = hours.indexOf(d.hour);
      if (index > -1) {
        hours.splice(index, 1);
      }
      d.value = d.completed;
      d.format = d.hour > 12 ? 'PM' : 'AM';
      d.hour = format(new Date(2019, 0, 1, d.hour, 0, 0));
      return d;
    });
    hours.forEach((hour) => {
      let d = {};
      d.format = hour > 12 ? 'PM' : 'AM';
      d.hour = format(new Date(2019, 0, 1, hour, 0, 0));
      d.value = 0;
      newData.push(d);
    });
    newData = sortBy(newData, 'hour');
    return newData;
  }, [data]);

  return (
    <BarChart
      data={parsedData}
      customTick
      customTooltip={CustomTooltip}
      style={{
        padding: 0.5,
        colors: ['#9EA6A6'],
        colorBy: true,
        margin: { top: 80, right: 40, bottom: 60, left: 40 },
      }}
      {...graphSize}
      indexBy={'hour'}
      title={`Distribución de órdenes completadas según horario de retiro`}
      axisTitles={{ left: `Nº de órdenes`, bottom: `Hora del día` }}
    />
  );
};

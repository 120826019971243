import React, { Fragment } from 'react';
import classnames from 'classnames';

import api from '../utils/api';

import { Link, withRouter, Redirect } from 'react-router-dom';
import { routes } from '../routes';

import Button from '../components/common/Button';

import base from '../styles/base.css';
import css from './PageStatus.css';
import grid from '../styles/grid.css';

const StatusMessages = {
  'Not Found': 'Página no encontrada',
  Unauthorized: 'No estás autenticado.',
};

export const ConnectionError = (props) => (
  <main>
    <section className={grid.grid}>
      <h1 className={classnames(base.mainTitle, grid.full)}>Error de conexión</h1>
      <section className={grid.half}>
        <p>Hubo un error conectándose al servidor. Por favor intenta más tarde o ponte en contacto con soporte.</p>
        {props.user && props.user.error && <small>{props.user.error.message}</small>}
      </section>
    </section>
  </main>
);

export const Forbidden = (props) => (
  <main>
    <h1>No estás autorizado para hacer esta operación</h1>
    <section className={grid.grid}>
      <Button large onClick={() => props.history.goBack()}>
        &larr; Volver
      </Button>
    </section>
  </main>
);

export const AccessDenied = (props) => {
  return <div>hola</div>;
};

export const NotFound = withRouter((props) => (
  <main>
    <h1>Página no encontrada.</h1>
    <p>La página que buscas no exsiste o se movió de lugar.</p>
    <div style={{ height: '4rem' }} />
    <Button large onClick={() => props.history.goBack()}>
      &larr; Volver
    </Button>
    <Button large primary to={routes('root')}>
      Página de inicio
    </Button>
  </main>
));

export const Loading = (props) => {
  const Tag = props.inline ? 'div' : 'main';
  return (
    <Tag>
      {!props.compact && <h1>{props.message || 'Cargando...'}</h1>}
      <div className={css.loading}>
        <div className={css.line} />
        <div className={css.line} />
        <div className={css.line} />
      </div>
    </Tag>
  );
};

export const Intro = (props) => {
  return (
    <div>
      <h2>Bienvenido a eLocker de Maletek</h2>
      <p>Haz login para continuar.</p>
      <Button to={'/login'}>Login</Button>
    </div>
  );
};

const publicPages = ['/', '/login', '/otp'];

const PageStatus = (props) => {
  const { pathname } = props.location;
  const { children } = props;

  if (props.user.error && props.user.error.message) {
    const { message } = props.user.error;
    return <ConnectionError />;
  } else if (!publicPages.includes(pathname) && !props.user.id && !props.user.loading) {
    return <Redirect to={`/login?redirect=${pathname}`} />;
  } else {
    return <Fragment>{children}</Fragment>;
  }
};
export default withRouter(PageStatus);

import React from 'react';
import classnames from 'classnames';

import StatusBullet from './StatusBullet';

import css from './ConnectionIndicator.css';

export const ConnectionIndicator = ({ online }) => (
  <span>
    <StatusBullet on={online} />
    {online ? 'Online' : 'Offline'}
  </span>
);

export default ConnectionIndicator;

import { makeEnum, makeEnumWithTranslation } from '../../utils';

export const objectLabels = makeEnumWithTranslation(
  {
    clients: 'clientes',
    client: 'cliente',
    locations: 'ubicaciones',
    location: 'ubicación',
    lockers: 'lockers',
    locker: 'locker',
    doors: 'puertas',
    door: 'puerta',
    orders: 'órdenes',
    order: 'orden',
    segments: 'grupos',
    segment: 'grupo',
    users: 'usuarios',
    user: 'usuario',
    reports: 'reportes',
    report: 'reporte',
    stats: 'estadísticas',
    password: 'contraseña',
    history: 'historial',
    preferences: 'preferencias',
    courierAssigned: 'asignación courier a orden',
    courierCancelled: 'cancelación de order a courier',
    pickupReady: 'envío listo para retiro',
    returnReserved: { cch: 'admisión reservada', default: 'devolución reservada' },
    returnReversal: { cch: 'admisión cancelada', default: 'devolución cancelada' },
    returnReady: { cch: 'admisión lista para retiro', default: 'devolución lista para retiro' },
    courierReady: 'orden lista para retiro a courier',
    pickupReversal: 'orden revocada',
    courierReversal: 'orden revocada a courier',
    pickupCompleted: 'retiro de paquete completado',
    courierCompleted: 'retiro de paquete completado a courier',
    pickupReminder: 'recordatorio de orden lista para retiro',
  },
  'objectLabels'
);

export const baseActionLabels = makeEnum(
  {
    login: 'autenticando',
    create: 'creando {{object}}',
    load: 'cargando {{object}}',
    download: 'descargando {{object}}',
    update: 'actualizando {{object}}',
    updateRequest: 'confirmando actualización de {{object}}',
    updateAccept: 'actualización de {{object}} aceptada',
    updateReject: 'actualización de {{object}} rechazada por el locker',
    change: 'cambiando {{object}}',
    cancel: 'cancelando {{object}}',
    cancelRequest: 'confirmando cancelación de {{object}}',
    cancelAccept: 'cancelación de {{object}} aceptada',
    cancelReject: 'cancelación de {{object}} rechazada por el locker',
    delete: 'eliminando {{object}}',
    save: 'guardando {{object}}',
    findAvaliable: 'buscando {{object}} disponibles',
    assign: 'asignando {{object}}',
    unassign: 'desasignando {{object}}',
    reserve: 'solicitando reserva de {{object}}',
    reserveRequest: 'confirmando reserva de {{object}}',
    reserveAccept: 'reserva de {{object}} aceptada',
    reserveReject: 'reserva de {{object}} rechazada por el locker',
    unreserve: 'solicitando cancelación de reserva de {{object}}',
    unreserveRequest: 'confirmando cancelación de reserva de {{object}}',
    unreserveAccept: 'cancelación de reserva de {{object}} aceptada',
    unreserveReject: 'cancelación de reserva de {{object}} rechazada por el locker',
    reversal: 'solicitando revocación de {{object}}',
    reversalRequest: 'confirmando revocación de {{object}}',
    reversalAccept: 'cancelación de revocación de {{object}} aceptada',
    reversalReject: 'cancelación de revocación de {{object}} rechazada por el locker',
    lockerUnavailable: 'el locker no respondió a la solicitud',
    notification: 'enviando notificación de {{object}}',
    notificationSent: 'notificación de {{object}} enviada',
  },
  'baseActionLabels'
);

import React, { Fragment, useRef, useEffect } from 'react';
import { FiX } from 'react-icons/fi';

import css from './Modal.css';

export const Modal = (props) => {
  const { children, open, onClose } = props;
  const el = useRef();

  useEffect(() => {
    if (open) {
      setTimeout(() => el.current && el.current.focus(), 100);
    }
  }, [open]);
  if (!open) return null;
  return (
    <Fragment>
      <div className={css.background} onClick={onClose} />
      <div className={css.root} ref={el} tabIndex="0">
        <div className={css.close} onClick={onClose}>
          <FiX />
        </div>
        {children}
      </div>
    </Fragment>
  );
};

export default Modal;

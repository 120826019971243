import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { makeEnum } from '../../utils';

import css from './Label.css';

export const labelTypes = makeEnum({
  normal: 'normal',
  success: 'success',
  error: 'error',
});

export const Label = (props) => {
  const { type, children, max } = props;
  if (!labelTypes.has(type)) console.error(`type "${type}" not recognized. Use exported labelTypes for type prop.`);
  return children ? (
    <span title={children} style={{ maxWidth: max }} className={classnames(css.root, { [css[type]]: type })}>
      {children}
    </span>
  ) : null;
};

export default Label;

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

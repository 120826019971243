import React, { Fragment } from 'react';
import classnames from 'classnames';

import css from './List.css';

export const List = (props) => {
  return (
    <div className={css.root}>
      <table
        className={classnames(css.table, props.className, {
          [css.left]: props.left,
          [css.center]: props.center,
          [css.right]: props.right,
          [css.top]: props.top,
          [css.middle]: props.middle,
          [css.bottom]: props.bottom,
        })}>
        {props.children}
      </table>
    </div>
  );
};

export const Header = (props) => {
  return (
    <thead className={classnames(css.head, props.className)}>
      <tr>{props.children}</tr>
    </thead>
  );
};

export const Body = (props) => {
  return (
    <tbody
      className={classnames(css.body, props.className, {
        [css.left]: props.left,
        [css.center]: props.center,
        [css.right]: props.right,
        [css.top]: props.top,
        [css.middle]: props.middle,
        [css.bottom]: props.bottom,
      })}>
      {props.children}
    </tbody>
  );
};

export const Row = ({ className, hover, children, onClick, ...rest }) => {
  const handleClick = (e) => {
    onClick && onClick(e);
  };
  return (
    <tr
      onClick={handleClick}
      className={classnames(css.row, className, { [css.hover]: hover, [css.clickable]: !!onClick })}
      {...rest}>
      {children}
    </tr>
  );
};

export const Cell = (props) => {
  const El = props.head ? `th` : `td`;
  return (
    <El
      rowSpan={props.rowSpan}
      colSpan={props.colSpan}
      style={props.style}
      className={classnames(css.cell, props.className, {
        [css.left]: props.left,
        [css.center]: props.center,
        [css.right]: props.right,
        [css.top]: props.top,
        [css.middle]: props.middle,
        [css.bottom]: props.bottom,
        [css.inline]: props.inline,
      })}>
      {props.hover ? (
        <Fragment>
          <span className={css.hover}>{props.hover}</span>
          <span className={css.content}>{props.children}</span>
        </Fragment>
      ) : (
        props.children
      )}
    </El>
  );
};

import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ascending } from 'd3';

import BarChart from './graphs/BarChart';

import { routes } from '../routes';

import css from './CompletedByLockers.css';

const sortBy = (array, property) => {
  return array.sort(function (x, y) {
    return ascending(x[property], y[property]);
  });
};

export default (props) => {
  const { data, graphSize } = props;

  const parsedData = useMemo(() => {
    let newData = data;
    newData = newData
      .map((d) => {
        d.lockerId = d.lockerId ? d.lockerId.toString() : d.lockerId;
        d.value = d.completed;
        return d;
      })
      .filter((d) => d.lockerId);

    newData = sortBy(newData, 'lockerId');
    return newData;
  }, [data]);

  const CustomTooltip = ({ indexValue, value, data, color }) => {
    const description = value != 1 ? `${value} órdenes` : `${value} orden`;
    const { lockerName } = data;
    return (
      <div>
        <strong style={{ color }}>{lockerName}</strong>
        <br />
        <strong>{`ID: ${indexValue}`}</strong>
        <br />
        <span>{description}</span>
      </div>
    );
  };

  return (
    <Fragment>
      <div className={css.lockerLegend}>
        <strong className={css.legendTitle}>Lockers</strong>
        {parsedData.map((d) => {
          const { lockerName, lockerId } = d;
          return (
            <div className={css.legendLabel} key={`label-${lockerId}`}>
              <Link to={routes('locker', { id: lockerId })}>
                <span>
                  <strong>{lockerId}</strong> – {lockerName}
                </span>
              </Link>
            </div>
          );
        })}
      </div>
      <BarChart
        data={parsedData}
        customTooltip={CustomTooltip}
        customTick
        tickWithLinks
        style={{ padding: 0.6, colors: ['#ea5754'], margin: { top: 80, right: 160, bottom: 60, left: 40 } }}
        {...graphSize}
        indexBy={'lockerId'}
        title={`Distribución de órdenes completadas por locker`}
        axisTitles={{ left: `Nº de órdenes`, bottom: `ID locker` }}
      />
    </Fragment>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

import css from './Pagination.css';

export const Pagination = (props) => {
  const { curPage, prevPage, nextPage, onChange } = props;
  const changePage = (page) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    onChange && onChange(page);
  };
  return (
    <div className={css.root}>
      <div>{prevPage ? <Button onClick={() => changePage(prevPage)}>&larr; Página anterior</Button> : null}</div>
      <div>{curPage && `Página ${curPage}`}</div>
      <div>{nextPage ? <Button onClick={() => changePage(nextPage)}>Próxima página &rarr;</Button> : null}</div>
    </div>
  );
};

Pagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  page: PropTypes.number,
  hasNext: PropTypes.bool,
};

export default Pagination;

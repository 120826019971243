const debug = require('debug')('mtk:react:location');

import React from 'react';

import api from '../utils/api';
import { useForm } from '../utils/form';
import { getDefaultLocation } from '../utils';
import { countriesSubset, timezonesSubset } from '../constants';

import PageHeader from '../components/common/PageHeader';
import Map from '../components/common/Map';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import TextArea from '../components/common/TextArea';
import Select, { objectToOptions } from '../components/common/Select';

import grid from '../styles/grid.css';
import css from './Location.css';

const locationFields = {
  name: ['text', true],
  address: ['text', true],
  countryCode: ['text', true],
  timezone: ['text'],
  lon: ['lon', true],
  lat: ['lat', true],
  description: ['text'],
};

export const LocationNew = ({ history, match }) => {
  const defaultLocation = getDefaultLocation();
  const { item, actions } = api.locations.newItem({ history });
  const form = useForm(locationFields, item.data);

  const tzs = Object.entries(countriesSubset).reduce((acc, [key, value]) => {
    timezonesSubset[key].forEach((tz) => {
      acc[tz] = `${value}: ${tz}`;
    });
    return acc;
  }, {});
  
  const timezoneOptions = objectToOptions(tzs);
  const countryOptions = objectToOptions(countriesSubset);

  const onMapChange = (loc) => {
    form.setValues(loc);
  };

  const handleCreate = async () => {
    if (form.validate()) {
      actions.create(form.getValues());
    }
  };
  const name = form.getValue('name');

  return (
    <main className={css.root}>
      <PageHeader section="locations" title={`Nueva Ubicación${name ? ': ' + name : ''}`} {...item} />

      <div className={grid.grid}>
        <section className={grid.half}>
          <Input name="name" label="Nombre" placeholder="Maletek" form={form} />
          <Input name="address" label="Dirección" placeholder="Bustamante 24, Providencia, Santiago" form={form} />
          <Select name="countryCode" isClearable={false} options={countryOptions} label="País" form={form} />
          <Select name="timezone" isClearable={false} options={timezoneOptions} label="Zona horaria" form={form} />
          <TextArea
            name="description"
            label="Como encontrar el grupo de lockers una vez en la dirección"
            rows={3}
            placeholder="En el sector cajas, frente a caja 55, junto a servicio al cliente"
            form={form}
          />
          <Input name="lat" label="Latitud" placeholder={defaultLocation.lat} form={form} />
          <Input name="lon" label="Longitud" placeholder={defaultLocation.lon} form={form} />

          <Button onClick={handleCreate}>Crear ubicación</Button>
        </section>
        <section className={grid.half}>
          <h3> Ubicación en el mapa</h3>
          <p>
            <small>
              Ajusta la posición de la cruz en el mapa a la ubicación exacta del grupo de lockers en el lugar. Puedes
              hacer zoom para mayor presición.
            </small>
          </p>
          <Map
            crosshair
            lat={form.getValue('lat') ? form.getValue('lat') : undefined}
            lon={form.getValue('lon') ? form.getValue('lon') : undefined}
            onChange={onMapChange}
          />
        </section>
      </div>
    </main>
  );
};

export default LocationNew;

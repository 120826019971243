import React from 'react';

import { Link } from 'react-router-dom';
import classnames from 'classnames';
import css from './Link.css';
export default (props) => {
  const { className, minimal = true, tooltip, inline, children, ...otherProps } = props;
  const classes = classnames(className, css.root, {
    [css.minimal]: minimal,
    [css.inline]: inline,
    [css.withTooltip]: tooltip,
  });
  return otherProps.to ? (
    <Link className={classes} {...otherProps}>
      {children}
      {tooltip ? <span className={css.tooltip}>{tooltip}</span> : null}
    </Link>
  ) : (
    <a className={classes} {...otherProps}>
      {children}
      {tooltip ? <span className={css.tooltip}>{tooltip}</span> : null}
    </a>
  );
};

const debug = require('debug')('mtk:react:user');
import React from 'react';
import classnames from 'classnames';
import { roleLabels, userStatusLabels } from '../constants';

import PageHeader from '../components/common/PageHeader';
import Barcode from '../components/common/Barcode';
import { Loading } from './PageStatus';

import api from '../utils/api';
import { useForm } from '../utils/form';
import { getToken } from '../utils/api/utils';

import { List, Body, Row, Cell } from '../components/common/List';
import Input from '../components/common/Input';
import Button from '../components/common/Button';
import Select, { objectToOptions } from '../components/common/Select';

import grid from '../styles/grid.css';

const userFields = {
  name: ['text'],
  email: ['email', true],
  role: ['text', true],
  clientName: ['text'],
  password: ['password'],
  password_confirm: [
    'password',
    false,
    {
      validate: (v, values) => {
        return v == values.get('password') ? null : 'las contraseñas deben coincidir';
      },
    },
  ],
  phone: ['tel'],
  clientId: ['number'],
  status: ['text'],
};

const User = (props) => {
  const { user } = api.auth();
  const { item, actions } = api.users.item(user.id, {}, { history });

  const form = useForm(userFields, item.data, { submit: actions.update });

  const onChangePassword = async () => {
    if (form.getValue('password') == form.getValue('password_confirm')) {
      if (await actions.changePassword(form.getValue('password'))) {
        form.clearValues(['password', 'password_confirm']);
      }
    }
  };
  if (!item.loaded) return <Loading />;
  const data = item.data.toJS();

  return (
    <main>
      <PageHeader section="users" title={` Configuración`} dirty={form.dirty} autoSave {...item} />

      <div className={grid.grid}>
        <section className={grid.half}>
          <Input name="name" label="Nombre" form={form} clickToEdit />

          <Input name="email" label="E-Mail" placeholder="user@maletek.cl" type="email" clickToEdit form={form} />

          <Input name="phone" label="Teléfono Movil" type="tel" form={form} clickToEdit />
          <div height={30} />
          <Barcode code={getToken()} height={30} bottomLabel="Token usuario" />
          <div height={30} />
        </section>
        <section className={grid.half}>
          <Input
            readOnly
            name="role"
            value={data.role ? roleLabels(data.role) : ''}
            label="Rol de usuario"
            // form={form}
            onChange={() => {}}
          />

          {data.clientName && (
            <Input readOnly name="clientName" label="Cliente" clickToEdit form={form} onChange={() => {}} />
          )}
          <h3>Cambio de Contraseña</h3>
          <Input
            name="password"
            label={'Nueva contraseña'}
            placeholder={'•••••••••'}
            type="password"
            skipSubmit
            form={form}
          />
          {form.getValue('password') && (
            <Input
              name="password_confirm"
              label={'Confirmar contraseña'}
              placeholder={'•••••••••'}
              type="password"
              skipSubmit
              form={form}
            />
          )}
          {form.getValue('password') && (
            <Button
              disabled={form.getValue('password') !== form.getValue('password_confirm')}
              onClick={onChangePassword}>
              Cambiar contraseña
            </Button>
          )}
        </section>
      </div>
    </main>
  );
};

export default User;

const debug = require('debug')('mtk:orders');

import React, { Fragment } from 'react';
import classnames from 'classnames';
import { pascal } from 'case';

import { routes } from '../routes';

import api from '../utils/api';
import { useQuery } from '../utils/query';

import { exportSpreadsheet } from '../utils/exportSpreadsheet';

import { roles, orderTypes } from '../constants';

import { Loading } from './PageStatus';

import PageHeader from '../components/common/PageHeader';
import OrderList from '../components/OrderList';
import CourierOrderList from '../components/CourierOrderList';
import Pagination from '../components/common/Pagination';

import Select, { indexToOptions } from '../components/common/Select';
import Button from '../components/common/Button';

import { can } from '../can';

import css from './Orders.css';

const Orders = (props) => {
  const { user } = api.auth();
  const query = useQuery(props.location, props.history, { status: '!completed,!cancelled' });
  const { index, actions } = api.orders.index(query.obj);

  const { actions: clientsActions } = api.clients.index(null, { lazy: true });
  const { actions: usersActions } = api.users.index(null, { lazy: true });
  const { actions: lockersActions } = api.lockers.index(null, { lazy: true });

  // ACTIONS
  const getClients = async (search) => indexToOptions(await clientsActions.get({ search, perPage: 50 }));

  const getCouriers = async (search) =>
    indexToOptions(await usersActions.get({ search, role: 'courier', perPage: 50 }));

  const getLockers = async (search) => indexToOptions(await lockersActions.get({ search, perPage: 50 }));

  const exportXls = async () => {
    const limitDays = 90;

    const createdAfter = new Date();
    // date objects are mutable. we can set date directly in the created date.
    createdAfter.setDate(createdAfter.getDate() - limitDays);

    // the server expects strings, so we send the date param as a string
    const reportParams = {
      createdAfter: createdAfter.toISOString(),
      orderAsc: false,
      sortBy: 'createdAt',
    };
    const params = Object.assign(reportParams, query.obj);

    for (const key of Object.keys(params)) {
      if (!params[key] || params[key] == 'all') delete params[key];
    }
    const all = await actions.getAll(params);

    // XXX the customColumns prop should be taken from a preference stored in the client.
    // For the time being we should just hard code the customization functions
    // in the codebase, and refer to them by name on the client prefs.
    exportSpreadsheet(all.toJS(), {
      clientId: params.clientId || user.clientId,
      filename: filenameFromParams('orders', params),
      extension: 'xls',
    });
  };

  const isCourier = !user.role || user.role == roles('courier');
  return (
    <main className={css.root}>
      <PageHeader title={isCourier ? 'Mis órdenes' : 'Órdenes'} {...index}>
        {can(user, 'export', 'orders') && (
          <>
            <small>Exportar últimos 90 días</small>
            <Button onClick={() => exportXls()}>Descargar .xls</Button>
          </>
        )}
        {can(user, 'create', 'orders') && (
          <Button disabled={!index.loaded} to={routes('newOrder')}>
            Nueva Orden
          </Button>
        )}
      </PageHeader>

      <div className={css.toolBar}>
        <div className={css.filters}>
          <Select
            className={css.select}
            label="Tipo"
            name="orderType"
            value={query.get('orderType')}
            onChange={query.set}
            placeholder="Todas"
            isClearable={false}
            options={[
              { label: 'Todas', value: '' },
              { label: 'Envíos y revocaciones', value: 'delivery,reversal' },
              { label: 'Solo envíos', value: 'delivery' },
              { label: 'Solo revocaciones', value: 'reversal' },
              { label: 'Devoluciones', value: 'return' },
            ]}
          />
          <Select
            className={css.select}
            label="Estado"
            name="status"
            value={query.get('status')}
            onChange={query.set}
            placeholder="Todas"
            isClearable={false}
            options={[
              { label: 'Todas', value: '!cancelled' },
              { label: 'Activas', value: '!completed,!cancelled' },
              { label: 'Creadas', value: 'created' },
              { label: 'Reservadas', value: 'reserved' },
              { label: 'Listas para retiro', value: 'ready' },
              { label: 'Completadas', value: 'completed' },
              { label: 'Canceladas', value: 'cancelled' },
            ]}
          />

          {can(user, 'index', 'clients') && (
            <Select
              className={css.select}
              label="Cliente"
              name="clientId"
              value={query.get('clientId')}
              onChange={query.set}
              placeholder="Todos"
              loadOptions={getClients}
            />
          )}
          {can(user, 'index', 'users') && (
            <Select
              className={css.select}
              label="Couriers"
              name="courierId"
              value={query.get('courierId')}
              onChange={query.set}
              placeholder="Todos"
              loadOptions={getCouriers}
            />
          )}
          {can(user, 'index', 'lockers') && (
            <Select
              className={css.select}
              label="Locker"
              name="lockerId"
              value={query.get('lockerId')}
              onChange={query.set}
              placeholder="Todos"
              loadOptions={getLockers}
            />
          )}
          <Select
            className={css.select}
            label="Error"
            name="errored"
            isClearable={false}
            value={query.get('errored')}
            onChange={query.set}
            placeholder="Todas"
            options={[
              { label: 'Todas', value: 'all' },
              { label: 'Con errores', value: 'true' },
              { label: 'Sin errores', value: 'false' },
            ]}
          />
        </div>
      </div>

      {index.loaded ? (
        isCourier ? (
          <CourierOrderList orders={index.data.toJS()} />
        ) : (
          <OrderList
            className={classnames({ [css.listLoading]: index.loading })}
            orders={index.data.toJS()}
            addFilter={query.set}
            isAdmin={user.role == 'admin'}
            clientId={query.obj.clientId || user.clientId}
          />
        )
      ) : (
        <Loading compact />
      )}
      {index.pageInfo && <Pagination {...index.pageInfo.toJS()} onChange={query.setPage} />}
    </main>
  );
};

export default Orders;

/// HELPERS

const filenameFromParams = (basename, params = {}) => {
  let filename = basename;
  for (const key of Object.keys(params)) {
    if (['page', 'perPage'].includes(key)) continue;
    filename += '-' + pascal(`${key}_${params[key].toString().replace('!', 'not_')}`);
  }
  return filename;
};

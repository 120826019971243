import React, { useRef, useCallback, useEffect, useState, Fragment } from 'react';
import { Prompt } from 'react-router';
import classnames from 'classnames';
import domHelpers from 'dom-helpers';
import throttle from 'lodash/fp/throttle';
import Case from 'case';

const { scrollTop, height, offset, ownerWindow } = domHelpers;
import ErrorBanner from './ErrorBanner';
import Progress from './Progress';
import Link from './Link';
import Button from './Button';
import { FiRefreshCw, FiChevronLeft, FiAlertTriangle, FiCheck } from 'react-icons/fi';

import { routes, sectionLabels } from '../../routes';

import css from './PageHeader.css';

const PageHeader = (props) => {
  const rootEl = useRef();
  const [header, setHeader] = useState({ height: 100, offset: 0, top: 0 });
  const [showMessage, setShowMessage] = useState();
  const [showError, setShowError] = useState();

  const onScroll = useCallback(
    throttle(1000 / 60, () => {
      const top = scrollTop(ownerWindow());
      if (top > 500) return;
      setHeader((h) => ({ ...h, top }));
    }),
    []
  );
  const onResize = useCallback(
    throttle(1000 / 60, () => {
      if (!rootEl.current) return;
      setHeader((h) => ({
        ...h,
        height: height(rootEl.current),
        offset: offset(rootEl.current).top,
      }));
    }),
    []
  );

  useEffect(() => {
    onScroll();
    onResize();
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    setShowMessage(!!props.message);
    const timer = setTimeout(() => setShowMessage(false), 5000);
    return () => clearTimeout(timer);
  }, [props.message]);

  useEffect(() => {
    setShowError(!!props.error);
    const timer = setTimeout(() => setShowError(false), 9000);
    return () => clearTimeout(timer);
  }, [props.error]);

  const onClose = () => {
    setShowError(false);
  };

  const {
    title,
    subtitle,
    section,
    children,
    error,
    message,
    actionLabel,
    loading,
    readOnly,
    dirty = false,
    autoSave,
    status,
    statusText,
    hideBreadcrumbs,
  } = props;

  const compact = header.top >= header.offset;
  return (
    <div style={{ minHeight: header.height }}>
      <Prompt
        when={dirty}
        message={(location) => `Aún no se terminan de guardar los cambios. Estás seguro que quieres salir?`}
      />
      <header ref={rootEl} className={classnames(css.root, { [css.compact]: header.top > header.height })}>
        <div className={css.titles}>
          {section && !hideBreadcrumbs && (
            <h2 className={css.section}>
              <Link to={routes(section)}>
                <FiChevronLeft /> {sectionLabels(section)}
              </Link>
            </h2>
          )}
          <h1 className={css.title}>{title}</h1>
          <div>{subtitle}</div>
          <div className={css.status}>
            {loading ? <Progress label={actionLabel} /> : showMessage && Case.sentence(message)}
            {!loading && !showMessage && dirty && <Progress label={'Se guardarán los cambios en un momento'} />}
            {!loading &&
              !showMessage &&
              !dirty &&
              autoSave &&
              (readOnly ? (
                <Fragment>
                  {' '}
                  <FiAlertTriangle /> Ponte en contacto con Maletek para solicitar cambios en esta sección.
                </Fragment>
              ) : (
                <Fragment>
                  <FiCheck /> Los cambios son guardados automáticamente.{' '}
                </Fragment>
              ))}
          </div>
        </div>
        {showError ? (
          <ErrorBanner label={actionLabel} error={error} onClose={onClose} />
        ) : (
          <div className={css.actions}>{children}</div>
        )}
      </header>
    </div>
  );
};

export default PageHeader;

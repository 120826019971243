import React, { useState, useEffect, Fragment } from 'react';

import { ResponsiveLine } from '@nivo/line';
import { useTheme } from '@nivo/core';

import Legend from './Legend';
import NoDataMessage from './NoDataMessage';

import css from './LineChart.css';

const tickColor = 'lightGray';
const colors = ['#e1251b', '#ea5754', '#165eaf', '#5f8350', '#e29a0c', '#f7c308'];

export default (props) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [max, setMax] = useState(0);
  const [states, setStates] = useState();
  const [series, setSeries] = useState([]);
  const availableData =
    data.length > 0
      ? data.reduce((sum, current) => sum + current.data.reduce((s, curr) => s + curr.y, 0), 0) > 0
        ? true
        : false
      : false;

  useEffect(() => {
    setData(props.data);
    const initialSeries = props.data.map((e) => e.id);
    setSeries(initialSeries);
    let initialStates = {};
    let initialCount = 0;
    initialSeries.map((name) => {
      if (defaultActive.includes(name)) {
        initialStates[name] = true;
        initialCount = initialCount + 1;
      } else initialStates[name] = false;
    });
    setStates(initialSeries);
    setCount(initialCount);
    if (props.data.length > 0) {
      let globalMax = max;
      props.data.map((current) => {
        let localMax = 0;
        globalMax = Math.max(
          globalMax,
          current.data.map((curr) => (localMax = Math.max(localMax, curr.y)))
        );
      });
      setMax(globalMax);
    }
  }, []);

  const { customTick, customTooltip, indexBy, size, style, axisTitles, title } = props;
  const { margin } = style;
  const commonProps = {
    margin: { top: 60, right: 240, bottom: 60, left: 60 },
    colors: colors,
    indexBy,
    labelTextColor: 'inherit:darker(1.4)',
    labelSkipWidth: 16,
    labelSkipHeight: 16,
    isInteractive: true,
    useMesh: true,
    labelFormat: (d) => <tspan y={-10}>{d}</tspan>,
    axisBottom: {
      legend: axisTitles.bottom,
      tickSize: 5,
      tickPadding: 6,
      legendOffset: 40,
      tickMarfin: 10,
      legendPosition: 'middle',
    },
    axisLeft: {
      legend: axisTitles.left,
      legendPosition: 'end',
      tickSize: 0,
      legendOffset: -30,
      format: (tick) => (!Number.isInteger(tick) ? '' : tick),
      tickValues: [...Array(max + 1).keys()],
    },
  };

  if (customTick) commonProps.axisBottom.renderTick = CustomTick;
  if (customTooltip) {
    commonProps.sliceTooltip = customTooltip;
    commonProps.enableSlices = 'x';
  }

  const handleOnClick = (name) => {
    const newStates = states;
    if (count > 1 && states[name]) {
      newStates[name] = !states[name];
      const newCount = count - 1;
      setCount(newCount);
      setStates(newStates);
    } else if (!states[name] && count < keys.length) {
      newStates[name] = !states[name];
      const newCount = count + 1;
      setCount(newCount);
      setStates(newStates);
    }
    filterData();
  };

  const filterData = (initialStates) => {
    const filterStates = states || initialStates;
    const filteredData = props.data.map((e) => {
      let filteredDatum = {};
      Object.keys(e).map((key) => {
        if (filterStates[key] === true || key === 'hour') filteredDatum[key] = e[key];
      });
      return filteredDatum;
    });
    setData(filteredData);
  };

  return (
    <div className={css.root} style={{ width: size.width, height: size.height }}>
      <h2 className={css.graphTitle}>{title}</h2>
      {availableData ? (
        <Fragment>
          <Legend series={states} colors={colors} parentCallback={handleOnClick} />
          <ResponsiveLine
            {...commonProps}
            data={data}
            theme={{
              tooltip: {
                container: {
                  background: '#fff',
                },
              },
            }}
          />
        </Fragment>
      ) : (
        <NoDataMessage />
      )}
    </div>
  );
};

import React, { Fragment, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Map, fromJS } from 'immutable';

import { FiCopy, FiX, FiPlus } from 'react-icons/fi';

import { doorStatus, doorStatusColors } from '../constants';

import { useForm, THROTTLE } from '../utils/form';
import { sizeToString } from '../utils';

import StatusBullet from './common/StatusBullet';
import Link from './common/Link';
import Input from './common/Input';
import Button from './common/Button';
import Select, { indexToOptions, objectToOptions, optionToItem } from '../components/common/Select';

import css from './DoorList.css';

const newDoor = (door) => new Map({}).merge({ doorNumber: 1, width: null, height: null, length: null }, door);

const doorFields = {
  doors: [
    {
      id: ['number'],
      width: ['number', true],
      height: ['number', true],
      length: ['number', true],
      doorNumber: ['number', true],
      status: ['text'],
      occupied: ['toggle'],
      reserved: ['toggle'],
    },
  ],
};

const doorStatusOptions = objectToOptions(doorStatus.obj);

export const DoorList = ({ locker, orders, lockerActions, readOnly }) => {
  const form = useForm(doorFields, locker.data);
  const hasDoors = locker.data.hasIn(['doors', 0]);

  const reIndex = () => {
    const doors = form.getValue('doors');
    form.setValue(
      'doors',
      doors.map((door, index) => {
        return door.set('doorNumber', index + 1);
      })
    );
  };
  const [triggerReIndex, setTriggerReIndex] = useState();
  useEffect(() => {
    reIndex();
  }, [triggerReIndex]);
  const onAddDoor = (i, clone) => {
    let doors = form.getValue('doors');
    const item = clone ? newDoor(doors.get(i)) : newDoor();

    form.appendValue('doors', fromJS(item), i);
    setTriggerReIndex(Date.now());
  };

  const onRemoveDoor = (i) => {
    if (form.getValue('doors').size > 1) {
      form.deleteValue(['doors', i]);
      setTriggerReIndex(Date.now());
    }
  };

  const handleDoorsCreate = async () => {
    if (form.validate(false, true)) {
      await lockerActions.doorsCreate(form.getValue('doors').map((door) => door.set('status', 'active')));
    }
  };

  // this is a bit convoluted but we need the save to run on the next cycle
  // so that the form is at its new state
  const [triggerUpdate, setTriggerUpdate] = useState({});

  const handleDoorChange = (k, v, doorId) => {
    const doorPath = k.slice(0, -1);
    form.setValue(k, v);
    if (!locker.data.hasIn(['doors'])) return;
    setTriggerUpdate({ doorId, doorPath });
  };

  // one throttle timer per door
  const [timers, setTimers] = useState([]);
  useEffect(() => {
    const { doorId, doorPath } = triggerUpdate;
    if (!doorId) return;
    clearTimeout(timers[doorId]);
    setTimers((timers) => {
      timers[doorId] = setTimeout(async () => {
        await lockerActions.doorUpdate(doorId, form.getValue(doorPath));
      }, THROTTLE);
      return timers;
    });
  }, [triggerUpdate]);

  const rootRef = useRef();
  useEffect(() => {
    const click = (e) => {
      if (!rootRef.current.contains(e.target)) setEditing(null);
    };
    window.addEventListener('click', click);
    return () => window.removeEventListener('click', click);
  }, []);

  const [editing, setEditing] = useState();
  const onEdit = (e) => {
    e.stopPropagation();
    setEditing(e.target.dataset.editing);
  };
  orders = orders ? orders.toJS() : orders;
  return (
    <div ref={rootRef}>
      <div>
        {form.getValue('doors') &&
          form
            .getValue('doors')
            .toJS()
            .map((door, index) => {
              const onChange = (k, v, e) => {
                return handleDoorChange(k, v, door.id);
              };

              const currentOrder = orders ? orders.find((o) => o.doorId == door.id) : false;
              return (
                <div className={classnames(css.item, { [css.editing]: editing == door.id })} key={`door-${index}`}>
                  <h4 className={css.doorNumber}>{door.doorNumber}</h4>
                  <div className={css.doorId}>(#{door.id})</div>
                  {!hasDoors || editing == door.id ? (
                    <Fragment>
                      <Input
                        className={css.sizeInput}
                        label="Alto"
                        name={['doors', index, 'height']}
                        form={form}
                        onChange={onChange}
                        placeholder="10"
                        sufix=" cm"
                      />
                      <Input
                        className={css.sizeInput}
                        label="Ancho"
                        name={['doors', index, 'width']}
                        form={form}
                        onChange={onChange}
                        placeholder="10"
                        sufix=" cm"
                      />
                      <Input
                        className={css.sizeInput}
                        label="Largo"
                        name={['doors', index, 'length']}
                        form={form}
                        onChange={onChange}
                        placeholder="10"
                        sufix=" cm"
                      />
                      {hasDoors && (
                        <Select
                          className={css.statusSelect}
                          label="Estado"
                          name={['doors', index, 'status']}
                          isClearable={false}
                          form={form}
                          onChange={onChange}
                          options={doorStatusOptions}
                        />
                      )}
                      {editing && (
                        <Link className={css.closeEditing} data-editing={null} onClick={onEdit}>
                          ×
                        </Link>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className={css.dimensions}>
                        <span className={css.boxWrapper}>
                          <span
                            className={css.box}
                            style={{
                              height: (20 / door.width) * door.height,
                              width: 20,
                            }}></span>
                        </span>
                        {sizeToString(door)}
                      </div>
                      <div className={css.availability}>
                        {/*
                         * XXX This should use the upcoming param on the order occupied value
                         * including both actually taken and reserved
                         */}

                        <StatusBullet on={door.status == 'active'} semi={door.occupied || door.reserved} />
                        {door.status == 'active'
                          ? door.occupied
                            ? 'En uso'
                            : door.reserved
                            ? 'Reservada'
                            : 'Disponible'
                          : doorStatus(door.status)}

                        {door.occupied}
                      </div>
                      {!readOnly && (
                        <Link className={css.editButton} data-editing={door.id} onClick={onEdit}>
                          editar
                        </Link>
                      )}
                    </Fragment>
                  )}
                  {!hasDoors ? (
                    <Fragment>
                      <Button className={css.btn} small noMargin onClick={() => onAddDoor(index, true)}>
                        <FiCopy />
                      </Button>
                      <Button className={css.btn} small noMargin onClick={() => onAddDoor(index)}>
                        <FiPlus />
                      </Button>
                      <Button className={css.btn} small destructive noMargin onClick={() => onRemoveDoor(index)}>
                        <FiX />
                      </Button>
                    </Fragment>
                  ) : null}
                </div>
              );
            })}
      </div>
      <br />
      <div>
        {!hasDoors && (
          <Fragment>
            <Button primary onClick={handleDoorsCreate}>
              Crear Puertas
            </Button>

            <p>
              <small>Una vez creadas, solo se podrá cambiar tamaños y estado</small>
            </p>
          </Fragment>
        )}
      </div>
    </div>
  );
};
export default DoorList;

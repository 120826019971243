import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import css from './Legend.css';

// series is an object in which its keys are the series names and its values
// a boolen depending if the series is active or not on the graph

export default ({ series, parentCallback, colors = [] }) => {
  if (!series) return <div>Loading series</div>;

  return (
    <div className={css.root}>
      <ul className={css.legend}>
        {Object.keys(series).map((name, i) => (
          <li
            key={`${name}-${i}`}
            className={classnames(css.legendItem, { [css.active]: series[name] })}
            onClick={() => parentCallback(name, i)}>
            <div
              className={classnames(css.symbol, { [css.unactive]: !series[name] })}
              style={{ background: colors[i] }}
            />
            <span className={css.series}>{name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

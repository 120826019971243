import React from 'react';
import classnames from 'classnames';
import { kebab } from 'case';

import css from './Radio.css';

const Radio = (props) => {
  let { name, value, onChange, form, className, style, label, options, readOnly, onClick } = props;

  value = form ? form.getValue(name) : value;
  onChange = onChange ? onChange : form.setValue;

  const handleOnClick = (e) => {
    if (readOnly) return;
    onClick && onClick(name, e.target.checked, e);
    onChange(name, e.target.value, e);
  };

  const optionWidth = 100 / options.length;
  const currentOptionIndex = options.findIndex((o) => o.value == value);

  return (
    <div className={classnames(css.root, className, { [css.readOnly]: readOnly })} style={style}>
      {label ? <label className={css.label}>{label}</label> : null}

      <div className={css.radioGroup} style={{ width: `${options.length > 3 ? 100 : 70}%` }}>
        {options.map((opt, index) => {
          const id = kebab(['input', name, label, index].join('-'));
          return (
            <div
              key={id}
              className={classnames(css.radio, { [css.on]: value == opt.value })}
              style={{ flexBasis: `${optionWidth}%` }}>
              <input
                id={id}
                type="radio"
                className={css.input}
                name={name}
                value={opt.value}
                checked={value === opt.value}
                onChange={handleOnClick}
                readOnly={readOnly}
              />
              <label className={css.radioLabel} htmlFor={id}>
                {opt.label}
              </label>
            </div>
          );
        })}
        <div
          className={css.slider}
          style={{ width: `${optionWidth}%`, transform: `translateX(${100 * currentOptionIndex}%)` }}>
          {currentOptionIndex > -1 && options[currentOptionIndex].label}
        </div>
      </div>
    </div>
  );
};

export default Radio;

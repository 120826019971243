import React from 'react';
import classnames from 'classnames';

import { can } from '../can';

import { modeLabels } from '../constants';

import api from '../utils/api';
import { useForm } from '../utils/form';

import { Forbidden } from './PageStatus';
import PageHeader from '../components/common/PageHeader';

import Button from '../components/common/Button';
import Input from '../components/common/Input';

import Select, { indexToOptions, objectToOptions, optionToItem } from '../components/common/Select';

import grid from '../styles/grid.css';
import css from './LockerNew.css';

const lockerFields = {
  name: ['text', true],
  locationId: ['number', true],
  preferences: {
    mode: ['text', true],
  },
};

export const Locker = ({ match, history }) => {
  const { id } = match.params;
  const { user } = api.auth();

  const { item, actions } = api.lockers.newItem({ history });
  const { index: locationsIndex, actions: locationsActions } = api.locations.index(null, { lazy: true });

  const form = useForm(lockerFields, item.data);
  const modeOptions = objectToOptions(modeLabels.obj);
  const getLocations = async (search) => {
    const index = await locationsActions.get({ search, perPage: 50 });
    return indexToOptions(index, { value: 'id', label: 'name' });
  };

  const handleCreate = async () => {
    if (form.validate()) {
      actions.create(form.getValues());
    }
  };

  if (!can(user, 'create', 'lockers')) return <Forbidden />;

  const name = form.getValue('name');

  return (
    <main className={css.root}>
      <PageHeader section="lockers" title={`Nuevo Locker${name ? ': ' + name : ''}`} {...item} />
      <div className={grid.grid}>
        <section className={grid.half}>
          <Input name="name" label="Nombre" form={form} placeholder="Nombre del Locker" />
          <Select
            name="locationId"
            label="Ubicación"
            isClearable={false}
            form={form}
            loadOptions={getLocations}
            placeholder="No asignada"
          />
          <Select
            name={['preferences', 'mode']}
            isClearable={false}
            options={modeOptions}
            label="Modo"
            form={form}
            note={'Define el modo en el que operará el locker.'}
            placeholder="..."
          />
          <Button onClick={handleCreate}>Crear locker</Button>
        </section>
        <section className={classnames(grid.half, css.doors)}>
          <div />
        </section>
      </div>
    </main>
  );
};

export default Locker;

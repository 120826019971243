import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { can } from '../can';
import { routes } from '../routes';

import { roles } from '../constants';

import api from '../utils/api';
import { useQuery } from '../utils/query';

import Pagination from '../components/common/Pagination';
import PageHeader from '../components/common/PageHeader';

import { List, Header, Body, Row, Cell } from '../components/common/List';
import Button from '../components/common/Button';
import Select, { indexToOptions } from '../components/common/Select';

import { FiChevronRight } from 'react-icons/fi';

import { Loading } from './PageStatus';

import css from './Segments.css';

const Segments = (props) => {
  const query = useQuery(props.location, props.history);
  const { user } = api.auth();
  const { index, actions } = api.segments.index(query.obj);
  const { actions: clientsActions } = api.clients.index(null, { lazy: true });
  const isAdmin = user.role == roles('admin');
  const getClients = async (search) =>
    [
      { value: '', label: 'Todos los grupos' },
      { value: 'false', label: 'Solo grupos globales' },
    ].concat(indexToOptions(await clientsActions.get({ search, perPage: 50 })));
  const showCLientScope = isAdmin && (!query.get('scopeToClientId') || query.get('scopeToClientId') != 'false');
  return (
    <main>
      <PageHeader title="Grupos">
        {can(user, 'create', 'segments') && (
          <Button noMargin to={routes('newSegment')}>
            Nuevo
          </Button>
        )}
      </PageHeader>
      <div className={css.toolbar}>
        {can(user, 'index', 'clients') && (
          <Select
            className={css.select}
            label="Filtrar grupos"
            name="scopeToClientId"
            value={query.get('scopeToClientId')}
            onChange={query.set}
            placeholder="Todos"
            loadOptions={getClients}
          />
        )}
      </div>
      {index.loaded ? (
        <Fragment>
          <List>
            <Header>
              <Cell head className={css.id}>
                ID
              </Cell>
              {showCLientScope ? (
                <Cell head className={css.scope}>
                  Cliente
                </Cell>
              ) : null}
              <Cell head className={css.nameHeader}>
                Nombre
              </Cell>
            </Header>
            <Body>
              {index.data.size ? (
                index.data.toJS().map((segment, i) => {
                  const to = routes('segment', { id: segment.id });
                  return (
                    <Row key={`segment-row-${i}`}>
                      <Cell className={css.id}>
                        <Link to={to}>{segment.id}</Link>
                      </Cell>
                      {showCLientScope ? (
                        <Cell className={css.scope}>
                          <div className={css.flex}>
                            <Link
                              to={routes('segments', null, {
                                scopeToClientId: segment.scopeToClientId,
                              })}>
                              {segment.scopeToClientName || segment.scopeToClientId}
                            </Link>
                            {segment.scopeToClientId ? <FiChevronRight /> : null}
                          </div>
                        </Cell>
                      ) : null}
                      <Cell>
                        <Link to={to}>{segment.name}</Link>
                      </Cell>
                    </Row>
                  );
                })
              ) : (
                <Row>
                  <Cell center colSpan={isAdmin ? 3 : 2}>
                    <p style={{ padding: '1rem 0' }}>
                      <small>No hay grupos que coincidan con esta busqueda</small>
                    </p>
                  </Cell>
                </Row>
              )}
            </Body>
          </List>
          {index.pageInfo && <Pagination {...index.pageInfo.toJS()} onChange={query.setPage} />}
        </Fragment>
      ) : (
        <Loading compact />
      )}
    </main>
  );
};

export default Segments;

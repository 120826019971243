const getBwipjs = async () => {
  const bwipjs = await import(/* webpackChunkName: "bwip-js" */ 'bwip-js');
  return bwipjs.default;
};

export const makeBwipCanvas = async (options) => {
  const bwipjs = await getBwipjs();
  let canvas = document.createElement('canvas');

  try {
    canvas = bwipjs.toCanvas(document.createElement('canvas'), options);
    return canvas.toDataURL('image/png');
  } catch (e) {
    console.error('Error generating the barcode', e);
    return null;
  }
};

import moment from 'moment';

import { getClientKeyByClientId, orderTypes, orderStatusLabel } from '../constants';

const dateFmt = 'DD/MM/YYYY';
const timeFmt = 'HH:mm:ss';
const dateTimeFmt = 'DD/MM/YYYY HH:mm:ss';
import { sizeToString } from '.';

const getXLSX = async () => {
  return await import(/* webpackChunkName: "xlsx" */ 'xlsx');
};

const defaultHeaders = [
  'id',
  'status',
  'clientId',
  'clientName',
  'courierEmail',
  'courierId',
  'courierName',
  'doorId',
  'doorSize',
  'error',
  'extras',
  'height',
  'length',
  'width',
  'packageSize',
  'locationName',
  'lockerId',
  'lockerName',
  'orderType',
  'pickupEmail',
  'pickupName',
  'pickupPhone',
  'createdAt',
  'completedAt',
  'courierAssignedAt',
  'readyAt',
  'reservedAt',
  'reversalAt',
  'pickupAt',
  'reversalPickupAt',
  'updatedAt',
  'erroredAt',
  'hoursFromReadyToPickup',
];

const customColumns = {
  sodimacPeru: {
    columns: [
      { header: 'No. Orden', value: 'id' },
      {
        header: 'Fecha De Creación',
        value: (row) => (row.createdAt ? moment(row.createdAt).format(dateFmt) : ''),
      },
      { header: 'Hora', value: (row) => (row.createdAt ? moment(row.createdAt).format(timeFmt) : '') },
      { header: 'Cliente', value: 'pickupName' },
      { header: 'Correo Cliente', value: 'pickupEmail' },
      { header: 'Usuario', value: 'courierName' },
      { header: 'Tienda', value: 'locationName' },
      { header: 'Status De La Orden', value: (row) => `${orderTypes(row.orderType)}: ${orderStatusLabel(row)}` },
      { header: 'Tipo de Orden', value: (row) => orderTypes(row.orderType) },
      {
        header: 'Fecha/hora de retiro',
        value: (row) => (row.pickupAt ? moment(row.pickupAt).format(dateTimeFmt) : ''),
      },
      {
        header: 'Fecha/hora de revocacion',
        value: (row) => (row.reversalAt ? moment(row.reversalAt).format(dateTimeFmt) : ''),
      },
      { header: 'Tiempo Retiro', value: 'hoursFromReadyToPickup' },
      { header: 'Orden venta', value: (row) => row.extras.ordenVenta || '' },
      { header: 'Nro Reserva', value: (row) => row.extras.nroReserva || '' },
      { header: 'SKU', value: (row) => row.extras.sKU || '' },
    ],
  },
  promart: {
    columns: [
      { header: 'ID', value: 'id' },
      { header: 'CODIGO DE ENTREGA', value: 'dropCode' },
      { header: 'ESTADO', value: (row) => orderStatusLabel(row) },
      { header: 'RESPONSABLE DE CARGA', value: 'courierName' },
      { header: 'TIENDA', value: 'locationName' },
      { header: 'TIPO', value: (row) => orderTypes(row.orderType) },
      { header: 'NOMBRE CLIENTE', value: 'pickupName' },
      { header: 'CORREO CLIENTE', value: 'pickupEmail' },
      { header: 'CELULAR CLIENTE', value: 'pickupPhone' },
      {
        header: 'HORA INGRESO PROD',
        value: (row) => (row.readyAt ? moment(row.readyAt).format(dateTimeFmt) : ''),
      },
      {
        header: 'HORA APERTURA PUERTA CLIENTE',
        value: (row) => (row.pickupAt ? moment(row.pickupAt).format(dateTimeFmt) : ''),
      },
      {
        header: 'HORA CIERRE PUERTA CLIENTE',
        value: (row) => (row.completedAt ? moment(row.completedAt).format(dateTimeFmt) : ''),
      },
    ],
  },
};

export const exportSpreadsheet = async (orders, { clientId, filename = 'orders', extension = 'xlsx' } = {}) => {
  orders = orders.map(prepareOrder);
  const columnsHelper = getColumnsHelper(clientId);
  orders = orders.map(columnsHelper.convert);
  const header = columnsHelper.headers;
  const XLSX = await getXLSX();
  const xls = XLSX.utils.json_to_sheet(orders, { header });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, xls, 'SheetJS');
  XLSX.writeFile(workbook, `${filename}.${extension}`);
};

const prepareOrder = (order) => {
  const fromReadyToCompleted =
    order.completedAt && order.readyAt ? moment.duration(moment(order.completedAt).diff(order.readyAt)) : false;

  const pad = (v) => `0${v}`.slice(-2);

  const formattedOrder = {
    ...order,
    pickupAt: order.orderType == 'delivery' && order.completedAt ? order.completedAt : '',
    hoursFromReadyToPickup: fromReadyToCompleted
      ? `${fromReadyToCompleted.days() ? fromReadyToCompleted.days() + 'd ' : ''}${pad(
          fromReadyToCompleted.hours()
        )}:${pad(fromReadyToCompleted.minutes())}`
      : 'n/a',
    packageSize: sizeToString(order),
    doorSize: order.doorWidth ? sizeToString(order, 'door') : '',
    reversalPickupAt: order.orderType == 'reversal' && order.completedAt ? order.completedAt : '',
    ...order.extras,
    extras: order.extras,
  };

  return formattedOrder;
};

const findCustomColumnsByClientId = (clientId) => {
  const key = getClientKeyByClientId(clientId);
  if (customColumns[key]) {
    return customColumns[key].columns;
  } else {
    return false;
  }
};

const getColumnsHelper = (clientId) => {
  const columns = findCustomColumnsByClientId(clientId);
  if (!columns)
    return {
      headers: defaultHeaders,
      convert: (raw) =>
        Object.keys(raw)
          .filter((key) => defaultHeaders.includes(key))
          .reduce((obj, key) => {
            obj[key] = raw[key];
            return obj;
          }, {}),
    };
  const headers = [];
  const converters = [];
  columns.forEach((p) => {
    headers.push(p.header);
    converters.push(typeof p.value === 'string' ? (r) => r[p.value] : p.value);
  });

  const convert = (row) => {
    const newRow = {};
    headers.forEach((h, i) => {
      newRow[h] = converters[i](row);
    });
    return newRow;
  };

  return { headers, convert };
};

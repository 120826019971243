import React, { Fragment } from 'react';
import classnames from 'classnames';

import { orderTypes, orderStatus } from '../constants';

import css from './OrderLifecycle.css';

import { Label, labelTypes } from './common/Label';

export default ({ orderType = 'delivery', status, courierId, locationId, lockerId, doorId, error }) => {
  let current = 100;
  error = status == 'cancelled' ? true : error;

  const delivery = () => (
    <Fragment>
      <h3 className={css.label}>Envío</h3>

      {status == 'cancelled' ? (
        <State current>Cancelado</State>
      ) : (
        orderStatus.delivery.keys.map((s, i) => {
          if (s == 'cancelled') return null;
          current = status == s ? i : current;
          return (
            <State key={`state-${i}`} done={status && i < current} current={status == s}>
              {orderStatus.delivery(s)}
            </State>
          );
        })
      )}
    </Fragment>
  );

  const reversal = () => (
    <Fragment>
      <h3 className={css.label}>{status == 'completed' ? 'Envío revocado' : 'Envío en revocación'}</h3>

      {orderStatus.reversal.keys.map((s, i) => {
        current = status == s ? i : current;
        return (
          <State key={`state-${i}`} done={i < current} current={status == s}>
            {orderStatus.reversal(s)}
          </State>
        );
      })}
    </Fragment>
  );

  const returnOrder = () => (
    <Fragment>
      <h3 className={css.label}>{orderTypes('return')}</h3>

      {status == 'cancelled' ? (
        <State current done>
          Cancelada
        </State>
      ) : (
        orderStatus.return.keys.map((s, i) => {
          if (s == 'cancelled') return null;
          current = status == s ? i : current;
          return (
            <State key={`state-${i}`} done={status && i < current} current={status == s}>
              {orderStatus.return(s)}
            </State>
          );
        })
      )}
    </Fragment>
  );

  return (
    <div className={css.root}>
      <div className={classnames(css.bar, { [css.error]: error })}>
        {orderType == 'delivery' && delivery()}
        {orderType == 'reversal' && reversal()}
        {orderType == 'return' && returnOrder()}
      </div>
    </div>
  );
};

const State = (props) => (
  <span
    className={classnames(css.state, {
      [css.done]: props.done,
      [css.current]: props.current,
      [css.left]: props.left,
    })}>
    {props.children}
  </span>
);

const debug = require('debug')('mtk:react:report');

import React, { Fragment, useState, useEffect, useMemo, useRef } from 'react';
import { FiArrowUpRight, FiTrash2 } from 'react-icons/fi';
import classnames from 'classnames';
import pick from 'lodash/fp/pick';
import moment from 'moment';
import 'moment/locale/es';

import api from '../utils/api';

import { routes } from '../routes';
import { can } from '../can';
import { useWindowSize, formatReportRange, formatDateTime } from '../utils';

import { Loading, NotFound } from './PageStatus';

import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/Button';

import CompletedByDoorSize from '../components/CompletedByDoorSize';
import CompletedByHour from '../components/CompletedByHour';
import CompletedByLockers from '../components/CompletedByLockers';
import DoorsBySize from '../components/DoorsBySize';
import DoorsPerLockerBySize from '../components/DoorsPerLockerBySize';
import OrderIntervals from '../components/OrderIntervals';
import Count from '../components/graphs/Count';
import StackedCount from '../components/graphs/StackedCount';

import grid from '../styles/grid.css';
import css from './Report.css';

const Report = ({ match, history }) => {
  const { id } = match.params;

  const { user } = api.auth();
  const { item, actions } = api.reports.item(id, {}, { history });

  const [widths, setWidths] = useState({});
  const ref = useRef();
  const win = useWindowSize();

  useEffect(() => {
    let newWidths = {};
    newWidths.fullWidth = ref.current ? ref.current.offsetWidth : win.width;
    newWidths.halfWidth = win.width >= 1000 ? newWidths.fullWidth / 2 : newWidths.fullWidth;
    setWidths(newWidths);
  }, [win.width, ref.current]);

  // reload if not done
  useEffect(() => {
    let timer;
    if (item.loaded && item.data.get('status') !== 'ready') {
      timer = setTimeout(actions.get, 3000);
    }
    return () => clearTimeout(timer);
  }, [item.data]);

  // Print listener
  useEffect(() => {
    const onPrint = () => {
      window.scrollTo(0, 0);
      let newWidths = {};
      newWidths.fullWidth = window.innerWidth - 500;
      newWidths.halfWidth = newWidths.fullWidth;
      setWidths(newWidths);
    };

    window.addEventListener('beforeprint', onPrint);
    return () => window.removeEventListener('beforeprint', onPrint);
  }, []);

  const onDelete = async () => {
    await actions.delete();
  };

  const report = useMemo(() => {
    if (item.loaded) {
      const reportData = item.data.toJS();
      if (reportData.status != 'ready') return reportData;

      const timeTo = moment(reportData.timeTo);
      const complete = timeTo.minutes() == 59 && timeTo.seconds() == 59;
      return {
        ...reportData,
        ...reportData.data,
        total: reportData.data.ordersByStatus.total.reversal + reportData.data.ordersByStatus.total.completed,
      };
    }
    return false;
  }, [item.data]);

  const ready = report && report.status == 'ready';

  const subtitle = ready ? (
    <Fragment>
      <h2 className={css.reportDate}>{formatReportRange(report, true)}</h2>
      <h2 className={css.reportClient}>
        <span>Cliente:</span> {report.clientName || `#${report.clientId}`}
      </h2>
      <p className={css.generated}>
        Generado el {formatDateTime(report.createdAt)} <span className={css.timezone}>UTC{report.timezoneOffset}</span>
      </p>
    </Fragment>
  ) : null;

  if (item.status == 404) return <NotFound />;

  return (
    <main>
      <PageHeader section="reports" title={'Reporte'} subtitle={subtitle} {...item}>
        {can(user, 'delete', 'reports') && (
          <Button confirm="¿Eliminar este reporte?" onClick={onDelete}>
            Eliminar
          </Button>
        )}
      </PageHeader>

      {ready ? (
        <div className={classnames(css.root, grid.grid)}>
          <div className={grid.full}>
            <h1 className={classnames(css.reportSubtitle, css.first)}>Órdenes</h1>
            <h4 className={css.reportNote}>(dentro del periodo consultado)</h4>
            <div className={css.countersWrapper}>
              <Count value={report.total} name={`Totales`} />
              <Count value={report.ordersByStatus.total.completed} name={`Completadas`} />
              <Count value={report.ordersByStatus.total.reversal} name={`Revocadas`} />
            </div>
          </div>
          <div className={grid.full}>
            <h3>Porcentaje de órdenes totales del período</h3>
            <div>
              <StackedCount
                values={[report.ordersByStatus.total.completed, report.ordersByStatus.total.reversal]}
                colors={['#165eaf', '#f99e1e']}
                percentage
                names={['Completadas', 'Revocadas']}
              />
            </div>
          </div>
          <div className={grid.full}>
            <OrderIntervals
              data={report.orderIntervals}
              graphSize={{
                size: {
                  width: `calc(${widths.fullWidth}px - 4rem)`,
                  height: 500,
                },
              }}
            />
          </div>
          <div className={classnames(grid.full)}>
            <CompletedByHour
              data={report.completedByHour}
              graphSize={{
                size: {
                  width: `calc(${widths.fullWidth}px - 4rem)`,
                  height: 400,
                },
              }}
            />
          </div>
          <div className={classnames(grid.half)}>
            <CompletedByDoorSize
              data={report.completedByDoorSizes}
              graphSize={{
                size: {
                  width: `calc(${widths.halfWidth}px - 4rem)`,
                  height: 400,
                },
              }}
            />
          </div>
          <div className={grid.half}>
            <CompletedByLockers
              data={report.completedByLockers}
              graphSize={{
                size: {
                  width: `calc(${widths.halfWidth}px - 4rem)`,
                  height: 400,
                },
              }}
            />
          </div>
          {report.lockersInfo && (
            <Fragment>
              <div className={classnames(grid.full, css.subtitleBorder)}>
                <h1 className={css.reportSubtitle}>Lockers</h1>
                <div className={classnames(css.countersWrapper, css.contentLeft)}>
                  <Count value={Math.round(report.lockersInfo.lockerAverage * 10) / 10} name={`Lockers`} />
                  <div className={css.gap} />
                  <Count value={Math.round(report.lockersInfo.doorAverage * 10) / 10} name={`Puertas`} />
                </div>
                {report.lockersInfo.lockersChangedDuringReport && (
                  <h4 className={css.reportMessage}>
                    {` *Promedio de lockers y puertas activas en el período consultado. El número de lockers cambió durante este periodo.`}
                  </h4>
                )}
              </div>
              {report.lockersInfo.doorMinutes > 0 && (
                <Fragment>
                  <div className={grid.full}>
                    <h3>Porcentaje de utilización de los lockers</h3>
                    <div>
                      <StackedCount
                        values={[
                          report.lockersInfo.doorMinutesReserved / report.lockersInfo.doorMinutes,
                          report.lockersInfo.doorMinutesReady / report.lockersInfo.doorMinutes,
                          report.lockersInfo.doorMinutesFree / report.lockersInfo.doorMinutes,
                        ]}
                        time={true}
                        percentage
                        showValue={false}
                        names={['Reservado', 'En uso', 'Libre']}
                      />
                      {false && <span className={css.stackedNote}>* Días promedios por puerta</span>}
                    </div>
                  </div>
                </Fragment>
              )}
              <div className={grid.half}>
                <DoorsBySize
                  data={report.lockersInfo.lockers}
                  graphSize={{
                    size: {
                      width: `calc(${widths.halfWidth}px - 4rem)`,
                      height: 400,
                    },
                  }}
                />
              </div>
              <div className={grid.half}>
                <DoorsPerLockerBySize
                  data={report.lockersInfo.lockers}
                  graphSize={{
                    size: {
                      width: `calc(${widths.halfWidth}px - 4rem)`,
                      height: 400,
                    },
                  }}
                />
              </div>
            </Fragment>
          )}
        </div>
      ) : (
        <Loading inline message={report && report.status ? 'Generando reporte' : null} />
      )}
    </main>
  );
};

export default Report;

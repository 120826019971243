import React from 'react';

import css from './NoDataMessage.css';

export default () => {
  return (
    <div className={css.noData}>
      <div>No hay datos suficientes</div>
    </div>
  );
};

import { parsePhoneNumber } from 'awesome-phonenumber';

export const functions = {
  tel: {
    mask: (val) => {
      if (!val || !val.replace(/\D/g, '').length) return val;
      val = val.replace(/^\+?/, '+');
      const num = parsePhoneNumber(val);
      return num.getNumber('international') || val;
    },
    unmask: (val) => {
      if (!val || !val.replace(/\D/g, '').length) return val;
      const num = parsePhoneNumber(val);
      return num.getNumber() || val;
    },
  },
};

export const mask = (value, type) => {
  if (!functions[type]) return value;
  return functions[type].mask(value);
};

export const unmask = (value, type) => {
  if (!functions[type]) return value;
  return functions[type].unmask(value);
};

import React, { useState, memo } from 'react';
import cn from 'classnames';
import { useUnits } from '../utils/api/units';

import { Loading } from '../pages/PageStatus';
import { Input } from './common/Input';
import Button from './common/Button';

import css from './Units.css';

const Units = (props) => {
  const { units, isLoading, error, errors, update, remove, create } = useUnits(props.locationId);
  const [newUnit, setNewUnit] = useState({});

  if (isLoading && !units) return <Loading />;

  return (
    <div>
      <h1>Unidades</h1>
      <p>
        <small>
          Disponible para ubicaciones para clientes en modo <strong>housing</strong>
        </small>
      </p>
      {error && <div>Error</div>}
      {isLoading && <p>Loading</p>}
      <ul className={css.list}>
        {units &&
          units.data.map((unit, index) => (
            <li key={unit.id || index} className={cn(css.unit, { [css.temp]: unit.temp })}>
              <div className={css.row}>
                <Input
                  name="name"
                  label="Nombre"
                  placeholder="Nombre"
                  value={unit.name}
                  error={errors[unit.id]}
                  onChange={(name, value) => update({ id: unit.id, [name]: value })}
                />
                <button className={css.delete} onClick={() => remove(unit.id)}>
                  &times;
                </button>
                <div className={css.tooltip}>Eliminar unidad</div>
              </div>
            </li>
          ))}
      </ul>
      <div className={css.new}>
        <Input
          name="name"
          placeholder={'Nombre'}
          value={newUnit.name || ''}
          onChange={(name, value) => setNewUnit((u) => ({ ...u, [name]: value }))}
        />

        <Button
          className={css.create}
          disabled={isLoading}
          onClick={() => {
            create(newUnit);
            setNewUnit({});
          }}>
          Agregar unidad
        </Button>
      </div>
    </div>
  );
};

export default memo(Units);

import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { IconContext } from 'react-icons';

localStorage.debug = process.env.DEBUG_CONFIG;

import './styles/base.css';

import Routes from './routes';

const App = (props) => {
  return (
    <IconContext.Provider value={{ style: { verticalAlign: 'middle', marginBottom: '.2em' } }}>
      <Router>
        <Routes />
      </Router>
    </IconContext.Provider>
  );
};

render(<App />, document.getElementById('root'));

const debug = require('debug')('mtk:form');
import { useState, useMemo } from 'react';
import { fromJS, List, Map } from 'immutable';
import validators, { loadCustomValidator } from './validate';
import { makePasswordValidator } from './zxcvbn';

import { isArray, isFunction } from '.';

const numericFieldTypes = ['number', 'lat', 'lon'];
const types = Object.keys(validators);

export const THROTTLE = 2000;

export const useForm = (fields, serverValues, opts = {}) => {
  const { submit = () => {} } = opts;
  const [store, setStore] = useState(fromJS({}));
  useMemo(() => {
    setStore((store) => fieldsToState(fromJS(fields), fromJS(serverValues)));
  }, [fields]);
  // actions
  const validate = (s, touch) => {
    let valid = true;

    setStore(
      (s || store).withMutations((s) => {
        s.get('paths').forEach((path) => {
          const validator = s.getIn(['validate'].concat(path));
          const fn = isFunction(validator) ? validator : validators[validator];
          const required = s.getIn(['required'].concat(path));
          const value = s.getIn(['values'].concat(path));
          if (touch) s.setIn(['touched'].concat(path), true);
          const isRequired = isFunction(required) ? required(value, s.getIn(['values'])) : required;
          const error =
            value == null && isRequired
              ? 'Valor requerido'
              : value != null && value !== ''
              ? fn(value, getValues(s))
              : null;
          // if (value) console.log(error, path, value);
          // if (error) debug(error, path, value);
          s.setIn(['errors'].concat(path), error);
          valid = error == null ? valid : false;
        });
        s.set('valid', valid);
      })
    );
    return valid;
  };

  const [timer, setTimer] = useState();
  const throttledSubmit = (store) => {
    clearTimeout(timer);
    if (!validate(store, true)) return;
    setTimer(
      setTimeout(async () => {
        await submit(getValues(store));
        setStore((store) => store.set('dirty', false));
      }, THROTTLE)
    );
  };

  const getValues = (s) => (s ? s.get('values') : store.get('values'));

  const setValues = (values, skipSubmit) => {
    values = fromJS(values);

    setStore((store) =>
      store.withMutations((s) => {
        s.set('dirty', true);
        s.get('paths').forEach((path) => {
          let value = values.getIn(path);
          if (typeof value !== 'undefined') {
            const type = s.getIn(['type'].concat(path));
            value = parseValue(value, type, path);
            s.setIn(['values'].concat(path), value);
          }
        });
        if (validate(s, true) && !(typeof skipSubmit == 'boolean' && skipSubmit)) {
          throttledSubmit(s);
        }
        return s;
      })
    );
  };

  const setServerValues = (values) => {
    values = fromJS(values);
    setStore((store) =>
      store.withMutations((s) => {
        s.set('dirty', false);
        s.get('paths').forEach((path) => {
          const value = values.getIn(path);
          if (typeof value !== 'undefined') {
            s.setIn(['touched'].concat(path), false);
            s.setIn(['values'].concat(path), value);
          }
        });
      })
    );
  };

  const getValue = (path) => store.getIn(['values'].concat(path)) || '';

  const setValue = (path, value, _silent) => {
    const silent = typeof _silent == 'boolean' && _silent;
    const _store = store.withMutations((s) => {
      if (!silent) s.set('dirty', true);
      const type = s.getIn(['type'].concat(path));
      const parsedValue = parseValue(value, type, path);
      s.setIn(['values'].concat(path), parsedValue);
      if (!List.isList(type)) s.setIn(['touched'].concat(path), true);
      if (validate(s, !silent) && !silent) {
        throttledSubmit(s);
      }
    });
    setStore(_store);
    return _store;
  };

  const appendValue = (path, value, index) => {
    if (!List.isList(store.getIn(['values'].concat(path)))) {
      console.error('Path %s must point at array value.', path);
      return;
    }
    const splice = (el, i, v) => (i ? el.splice(i, 0, v) : el.push(v));
    const getFrom = (base, path) => store.getIn([].concat(base, path, 0));

    const _store = store.withMutations((s) => {
      const next = store.getIn(['values'].concat(path)).size;
      if (Map.isMap(getValue([].concat(path, 0)))) {
        const [...keys] = getValue([].concat(path, 0)).keys();
        s.set('dirty', true)
          .update('paths', (p) => p.concat(keys.map((k) => [].concat(path, next, k))))
          .updateIn(['values'].concat(path), (el) => splice(el, index, value))
          .updateIn(['touched'].concat(path), (el) => splice(el, index, getFrom('touched', path)))
          .updateIn(['type'].concat(path), (el) => splice(el, index, getFrom('type', path)))
          .updateIn(['validate'].concat(path), (el) => splice(el, index, getFrom('validate', path)))
          .updateIn(['required'].concat(path), (el) => splice(el, index, getFrom('required', path)))
          .updateIn(['errors'].concat(path), (el) => splice(el, index, getFrom('errors', path)));
      } else {
        s.set('dirty', true)
          .update('paths', (p) => p.push([].concat(path, next)))
          .updateIn(['values'].concat(path), (list) => splice(list, index, value))
          .updateIn(['touched'].concat(path), (list) => splice(list, index, getFrom('touched', path)))
          .updateIn(['type'].concat(path), (list) => splice(list, index, getFrom('type', path)))
          .updateIn(['validate'].concat(path), (list) => splice(list, index, getFrom('validate', path)))
          .updateIn(['required'].concat(path), (list) => splice(list, index, getFrom('required', path)))
          .updateIn(['errors'].concat(path), (list) => splice(list, index, getFrom('errors', path)));
      }

      if (validate(s)) {
        throttledSubmit(s);
      }
    });
    setStore(_store);
    return _store;
  };

  const deleteValue = (path) => {
    let ff;
    setStore(
      store.withMutations((s) => {
        const pathsIndex = s.get('paths').findIndex((v) => v == path);
        if (pathsIndex) s.deleteIn(['paths', pathsIndex]);
        s.set('dirty', true)
          .deleteIn(['values'].concat(path))
          .deleteIn(['touched'].concat(path))
          .deleteIn(['type'].concat(path))
          .deleteIn(['validate'].concat(path))
          .deleteIn(['required'].concat(path))
          .deleteIn(['errors'].concat(path));
        if (validate(s)) {
          throttledSubmit(s);
        }
        ff = s;
      })
    );
    return ff;
  };

  const clearValues = (paths) => {
    setStore(
      store.withMutations((s) => {
        paths.forEach((path) => {
          s.setIn(['values'].concat(path), undefined);
        });
      })
    );
  };

  const getType = (path) => store.getIn(['type'].concat(path));
  const getErrors = () => store.get('errors');
  const getError = (path) => store.getIn(['errors'].concat(path));
  const setError = (path, error) => {
    const _store = store.withMutations((s) => {
      s.set('dirty', true);
      s.setIn(['errors'].concat(path), error);
    });
    setStore(_store);
    return _store;
  };
  const setErrors = (errors) => {
    const _store = store.withMutations((s) => {
      s.set('dirty', true);
      Object.keys(errors).forEach((error) => {
        s.setIn(['errors'].concat(error), errors[error]);
      });
    });
    setStore(_store);
    return _store;
  };
  const getTouched = (path) => store.getIn(['touched'].concat(path));

  // whenever serverValues change, override the store.
  useMemo(() => {
    if (serverValues) setServerValues(serverValues);
  }, [serverValues]);

  return {
    validate,
    getValues,
    setValues,
    setServerValues,
    getValue,
    setValue,
    appendValue,
    deleteValue,
    clearValues,
    getType,
    getError,
    setError,
    setErrors,
    getErrors,
    getTouched,
    dirty: store.get('dirty'),
    store,
  };
};

export const fieldsToState = (fields, initialValues, state = Map({ paths: List([]) }), path = []) => {
  if (
    fields.some((field) => {
      return field.get(0) == 'password';
    })
  ) {
    loadCustomValidator('password', makePasswordValidator);
  }

  fields.forEach((field, name) => {
    if (List.isList(field) && typeof field.get(0) == 'string') {
      state = fieldToState(field, initialValues, state, path.concat(name));
    } else if (List.isList(field) && (Map.isMap(field.get(0)) || List.isList(field.get(0)))) {
      const isList = List.isList(field.get(0));
      state = state
        .setIn(['values'].concat(path, name), List([]))
        .setIn(['touched'].concat(path, name), List([]))
        .setIn(['type'].concat(path, name), List([]))
        .setIn(['validate'].concat(path, name), List([]))
        .setIn(['required'].concat(path, name), List([]))
        .setIn(['errors'].concat(path, name), List([]));
      if (
        initialValues &&
        initialValues.getIn(path.concat(name)) &&
        List.isList(initialValues.getIn(path.concat(name))) &&
        initialValues.getIn(path.concat(name)).size > 0
      ) {
        initialValues.getIn([].concat(path, name)).map((item, index) => {
          state = isList
            ? fieldToState(field.get(0), initialValues, state, [].concat(path, name, index))
            : fieldsToState(fields.getIn([].concat(path, name, 0)), initialValues, state, [].concat(path, name, index));
        });
      } else {
        state = isList
          ? fieldToState(field.get(0), initialValues, state, path.concat(name, 0))
          : fieldsToState(fields.getIn([].concat(path, name, 0)), initialValues, state, path.concat(name, 0));
      }
    } else if (Map.isMap(field)) {
      state = fieldsToState(fields.get(name), initialValues, state, path.concat([name]));
    } else {
      console.error(
        'bad field signature. should be {name: [{string}type, {bool}required], {obj} {{fn}customValidate, initialValue}} or {name: {nestedField}}',
        field
      );
    }
  });
  return state;
};

const fieldToState = (field, initialValues, state, path) => {
  return state.withMutations((s) => {
    const type = field.get(0),
      required = !!field.get(1),
      customValidate = field.getIn([2, 'validate']),
      customRequired = field.getIn([2, 'required']),
      initialValue = initialValues
        ? initialValues.getIn(path)
        : field.getIn([2, 'initialValue'])
        ? field.getIn([2, 'initialValue'])
        : undefined;

    // checks
    if (!types.includes(type))
      throw new Error('Invalid type "' + type + '", allowed types are:' + types.join(', ') + '.');
    s.set('dirty', false);
    s.update('paths', (p) => p.push(path));
    s.setIn(['values'].concat(path), initialValue)
      .setIn(['touched'].concat(path), false)
      .setIn(['type'].concat(path), type)
      .setIn(['validate'].concat(path), isFunction(customValidate) ? customValidate : type)
      .setIn(['required'].concat(path), isFunction(customRequired) ? customRequired : required)
      .setIn(['errors'].concat(path), null);
  });
};

export const parseValue = (value, type, path) => {
  if (type == 'toggle') return !!value;
  if (value === '' || value == null) return null;
  const parsedValue = numericFieldTypes.includes(type) ? parseFloat(value) : value;
  if (numericFieldTypes.includes(type) && isNaN(parsedValue)) {
    console.error('Invalid numeric value. check your inputs!', { type, value, path });
    return value;
  }
  if (type == 'json') {
    try {
      return fromJS(JSON.parse(value));
    } catch (e) {
      return value;
    }
  }
  return parsedValue;
};

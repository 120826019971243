import React, { Fragment } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router';

import { hot } from 'react-hot-loader';

import { makeRoutes, makeEnum } from './utils';

import api from './utils/api';

import Header from './components/Header';

import PageStatus from './pages/PageStatus';

import Index from './pages/Index';
import Orders from './pages/Orders';
import OrderNew from './pages/OrderNew';
import Order from './pages/Order';
import Lockers from './pages/Lockers';
import LockerNew from './pages/LockerNew';
import Locker from './pages/Locker';
import Locations from './pages/Locations';
import LocationNew from './pages/LocationNew';
import Location from './pages/Location';
import Clients from './pages/Clients';
import ClientNew from './pages/ClientNew';
import Client from './pages/Client';
import Segments from './pages/Segments';
import SegmentNew from './pages/SegmentNew';
import Segment from './pages/Segment';
import Users from './pages/Users';
import UserNew from './pages/UserNew';
import User from './pages/User';
import Settings from './pages/Settings';
import Reports from './pages/Reports';
import Report from './pages/Report';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Logout from './pages/Logout';

export const routes = makeRoutes({
  root: '/',
  logout: '/logout',
  login: '/login',
  orders: '/ordenes',
  order: '/ordenes/:id',
  newOrder: '/ordenes/nueva',
  lockers: '/lockers',
  locker: '/lockers/:id',
  newLocker: '/lockers/nuevo',
  locations: '/ubicaciones',
  location: '/ubicaciones/:id',
  newLocation: '/ubicaciones/nueva',

  segments: '/grupos',
  segment: '/grupos/:id',
  newSegment: '/grupos/nuevo',
  clients: '/clientes',
  client: '/clientes/:id',
  newClient: '/clientes/nuevo',
  reports: '/reportes',
  report: '/reportes/:id',
  settings: '/configuracion',
  users: '/usuarios',
  user: '/usuarios/:id',
  newUser: '/usuarios/nuevo',
  settings: '/configuracion',
});

export const sectionLabels = makeEnum({
  orders: 'Órdenes',
  lockers: 'Lockers',
  locations: 'Ubicaciones',
  units: 'Unidades',
  segments: 'Grupos',
  clients: 'Clientes',
  reports: 'Reportes',
  settings: 'Configuracion',
  users: 'Usuarios',
});

export const Routes = (props) => {
  const { user, actions } = api.auth();
  return (
    <Fragment>
      <Header user={user} actions={actions} />
      <PageStatus user={user}>
        <Switch>
          <Route exact path={routes('root')} title="" component={Index} />
          <Route exact path={routes('orders')} title="Órdenes" component={Orders} />
          <Route exact path={routes('newOrder')} title="Nueva Orden" component={OrderNew} />
          <Route exact path={routes('order')} title="Orden" component={Order} />
          <Route exact path={routes('lockers')} title="Lockers" component={Lockers} />
          <Route exact path={routes('newLocker')} title="Nuevo Locker" component={LockerNew} />
          <Route exact path={routes('locker')} title="Locker" component={Locker} />
          <Route exact path={routes('locations')} title="Ubicaciones" component={Locations} />
          <Route exact path={routes('newLocation')} title="Nueva Ubicacion" component={LocationNew} />
          <Route exact path={routes('location')} title="Ubicacion" component={Location} />
          <Route exact path={routes('clients')} title="Clientes" component={Clients} />
          <Route exact path={routes('newClient')} title="Nuevo Cliente" component={ClientNew} />
          <Route exact path={routes('client')} title="Cliente" component={Client} />
          <Route exact path={routes('segments')} title="Grupos" component={Segments} />
          <Route exact path={routes('newSegment')} title="Nuevo Grupo" component={SegmentNew} />
          <Route exact path={routes('segment')} title="Grupo" component={Segment} />
          <Route exact path={routes('users')} title="Usuarios" component={Users} />
          <Route exact path={routes('newUser')} title="Nuevo Usuario" component={UserNew} />
          <Route exact path={routes('user')} title="Usuario" component={User} />
          <Route exact path={routes('reports')} title="Reportes" component={Reports} />
          <Route exact path={routes('report')} title="Reportes" component={Report} />
          <Route exact path={routes('settings')} title="Usuario" component={Settings} />
          <Route exact path={routes('login')} title="Login" component={Login} />
          <Route exact path={routes('logout')} title="Logout" component={Logout} />
          <Route path="*" title="Not Found" component={NotFound} />
        </Switch>
      </PageStatus>
    </Fragment>
  );
};

export default hot(module)(Routes);

import React, { useMemo, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';

import api from '../utils/api';
import { routes, sectionLabels } from '../routes';
import { can } from '../can';
import { objectGetter } from '../utils';
import { roles, orderStatusColors, colors } from '../constants';
import { Loading, Intro } from './PageStatus';
import { Label, labelTypes } from '../components/common/Label';

import css from './Index.css';
import grid from '../styles/grid.css';

const Index = (props) => {
  const { user } = api.auth();
  const { item, actions } = api.stats.dashboard();
  if (user.role == roles('courier')) return <Redirect to={routes('orders')} />;
  const types = ['delivery', 'reversal', 'return'];
  const statuses = ['created', 'reserved', 'ready'];

  const [orders, ordersActive, ordersDeliveryActive, ordersReturnActive, ordersActiveErrored, returnActiveErrored] =
    useMemo(() => {
      let orders,
        active = 0,
        activeDelivery = 0,
        returnDelivery = 0,
        errored = 0,
        returnErrored = 0;
      if (item.data) {
        orders = item.data.toJS().orders;
        orders.forEach((order) => {
          if (types.includes(order.orderType) && statuses.includes(order.status)) {
            active += order.total;
            if (order.orderType === types[0]) activeDelivery += order.total;
            if (order.orderType === types[2]) {
              returnDelivery += order.total;
              returnErrored += order.error;
            }
            errored += order.errors;
          }
        });
      }
      return [orders, active, activeDelivery, returnDelivery, errored, returnErrored];
    }, [item.data]);

  const [doors, doorsTotal] = useMemo(() => {
    let doors,
      totals = 0;
    if (item.data) {
      doors = item.data.toJS().doors;
      doors.forEach((door) => {
        totals += door.total;
      });
    }
    return [doors, totals];
  }, [item.data]);

  if (!item.loaded && !item.loading) return <Intro />;
  if (!item.loaded) return <Loading />;

  const stat = objectGetter(item.data.toJS());

  const getOrderCount = (type, status, value) => {
    const current = orders.find((e) => e.orderType == type && e.status == status);
    if (current) {
      return value == 'total' ? current.total : current.errors;
    }
    return 0;
  };

  const getDoorCount = (status, type) => {
    const doorsByStatus = doors.filter((e) => e.status == status);
    if (type == 'available') {
      return doorsByStatus.reduce((acc, curr) => acc + curr.total - curr.reserved - curr.occupied, 0);
    } else if (type == 'occupied') {
      return doorsByStatus.reduce((acc, curr) => acc + curr.occupied, 0);
    } else if (type == 'reserved') {
      return doorsByStatus.reduce((acc, curr) => acc + curr.reserved, 0);
    } else {
      return doorsByStatus.reduce((acc, curr) => acc + curr.total, 0);
    }
  };

  return (
    <main className={css.root}>
      <p className={css.intro}>Bienvenido a eLocker de Maletek.</p>
      {user.role !== roles('courier') && (
        <div className={css.status}>
          <div style={{ maxWidth: 800 }}>
            <div>
              <h2 className={css.statusTitle}>
                <Link to={routes('orders', null, 'status=!completed')}>
                  {ordersActive
                    ? ordersActive > 1
                      ? `${ordersActive} órdenes activas`
                      : '1 orden activa'
                    : 'No hay órdenes activas'}
                </Link>
              </h2>
              {ordersReturnActive > 0 && (
                <h3>
                  {ordersActive - ordersReturnActive > 1
                    ? `${ordersActive - ordersReturnActive} envíos activos`
                    : '1 envío activo'}
                </h3>
              )}
              <Bar>
                <BarSegment
                  link={routes('orders', null, 'status=created')}
                  background={orderStatusColors({ orderType: 'delivery', status: 'created' })}
                  label="creadas"
                  count={getOrderCount('delivery', 'created', 'total') || 0}
                  total={ordersActive - ordersReturnActive}
                />
                <BarSegment
                  link={routes('orders', null, 'status=reserved')}
                  background={orderStatusColors({ orderType: 'delivery', status: 'reserved' })}
                  color="white"
                  label="reservadas"
                  count={getOrderCount('delivery', 'reserved', 'total') || 0}
                  total={ordersActive - ordersReturnActive}
                />
                <BarSegment
                  link={routes('orders', null, 'orderType=delivery&status=ready&orderBy=readyAt')}
                  background={orderStatusColors({ orderType: 'delivery', status: 'ready' })}
                  label="listas para retiro"
                  count={getOrderCount('delivery', 'ready', 'total') || 0}
                  total={ordersActive - ordersReturnActive}
                />
                <BarSegment
                  link={routes('orders', null, 'orderType=reversal&status=ready')}
                  background={orderStatusColors({ orderType: 'reversal', status: 'ready' })}
                  label="en revocación"
                  count={getOrderCount('reversal', 'ready', 'total') || 0}
                  total={ordersDeliveryActive}
                />
              </Bar>
              <div className={css.errored}>
                <Link to={routes('orders', null, 'errored=true')}>
                  {ordersActiveErrored - returnActiveErrored
                    ? ordersActiveErrored - returnActiveErrored == 1
                      ? `1 orden con error`
                      : `${ordersActiveErrored - returnActiveErrored} órdenes con error`
                    : null}
                </Link>
              </div>
              {ordersReturnActive > 0 && (
                <Fragment>
                  <h3>
                    {ordersReturnActive > 1 ? `${ordersReturnActive} devoluciones activas` : '1 devolución activa'}
                  </h3>
                  <Bar>
                    <BarSegment
                      link={routes('orders', null, 'orderType=return&status=created')}
                      background={orderStatusColors({ orderType: 'return', status: 'created' })}
                      label="creadas"
                      count={getOrderCount('return', 'created', 'total') || 0}
                      total={ordersReturnActive}
                    />
                    <BarSegment
                      link={routes('orders', null, 'orderType=return&status=reserved')}
                      background={orderStatusColors({ orderType: 'return', status: 'reserved' })}
                      color="white"
                      label="reservadas"
                      count={getOrderCount('return', 'reserved', 'total') || 0}
                      total={ordersReturnActive}
                    />
                    <BarSegment
                      link={routes('orders', null, 'orderType=return&status=ready&orderBy=readyAt')}
                      background={orderStatusColors({ orderType: 'return', status: 'ready' })}
                      label="listas para retiro"
                      count={getOrderCount('return', 'ready', 'total') || 0}
                      total={ordersReturnActive}
                    />
                  </Bar>
                  <div className={css.errored}>
                    <Link to={routes('orders', null, 'errored=true')}>
                      {returnActiveErrored
                        ? returnActiveErrored == 1
                          ? `1 orden con error`
                          : `${returnActiveErrored} órdenes con error`
                        : null}
                    </Link>
                  </div>
                </Fragment>
              )}
              <div className={css.completed}>
                {getOrderCount('delivery', 'completed', 'total') && (
                  <Link to={routes('orders', null, 'orderType=delivery&status=completed')}>
                    {getOrderCount('delivery', 'completed', 'total')} entregadas
                  </Link>
                )}

                {getOrderCount('return', 'completed', 'total') > 0 && (
                  <Fragment>
                    {' / '}
                    <Link to={routes('orders', null, 'orderType=return&status=completed')}>
                      {getOrderCount('return', 'completed', 'total')} devueltas
                    </Link>
                  </Fragment>
                )}

                {getOrderCount('reversal', 'completed', 'total') && (
                  <Fragment>
                    {' / '}
                    <Link to={routes('orders', null, 'orderType=reversal&status=completed')}>
                      {getOrderCount('reversal', 'completed', 'total')} revocadas
                    </Link>
                  </Fragment>
                )}
              </div>
            </div>
            <div>
              <h2 className={css.statusTitle}>
                <Link to="/lockers"> {stat('lockers.total')} Lockers</Link>
              </h2>
              <Bar>
                <BarSegment
                  background={colors.green}
                  count={stat('lockers.online')}
                  label="online"
                  total={stat('lockers.total')}
                />
                <BarSegment
                  background={colors.orange}
                  count={stat('lockers.offline')}
                  label="offline"
                  total={stat('lockers.total')}
                />
              </Bar>
            </div>
            <div>
              <h2 className={css.statusTitle}>{doorsTotal} Puertas</h2>
              <Bar>
                <BarSegment
                  label={'disponibles'}
                  background={colors.green}
                  count={getDoorCount('active', 'available')}
                  total={doorsTotal}
                />
                <BarSegment
                  label={'en uso'}
                  background={colors.yellow}
                  count={getDoorCount('active', 'occupied')}
                  total={doorsTotal}
                />
                <BarSegment
                  label={'reservadas'}
                  background={colors.yellow}
                  count={getDoorCount('active', 'reserved')}
                  total={doorsTotal}
                />
                <BarSegment
                  label={'inactivas'}
                  background={colors.blue}
                  color="white"
                  count={getDoorCount('inactive')}
                  total={doorsTotal}
                />
                <BarSegment
                  label={'defectuosas'}
                  background={colors.red}
                  color="white"
                  count={getDoorCount('defective')}
                  total={doorsTotal}
                />
              </Bar>
            </div>
          </div>
        </div>
      )}

      <div className={css.activity}>
        <header>
          <h2>Actividad</h2>
          <span className={css.filters}>
            <a>Errores</a>
            <a>Alertas</a>
            <a>Todo</a>
          </span>
        </header>
        <ul>
          <div>Message</div>
        </ul>
      </div>
    </main>
  );
};

const Bar = ({ children }) => <div className={css.bar}>{children}</div>;

const BarSegment = ({ count, total, label, color, background, link }) => {
  if (!count) return null;
  const percent = Math.round((count * 100) / total);
  const text = (
    <Fragment>
      {count} <span style={{ fontSize: '.8rem' }}>({percent}%)</span>
      <span className={css.barSegmentLabel}>{label}</span>
    </Fragment>
  );
  return (
    <div className={css.barSegment} style={{ background, color, flex: `1 1 ${percent || 1}%` }}>
      {link ? <Link to={link}>{text}</Link> : text}
    </div>
  );
};

export default Index;

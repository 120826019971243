import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { routes } from '../routes';
import Nav from './Nav';
import css from './Header.css';

import logo from '../assets/logo.png';

const Header = ({ location, user, actions }) => {
  return (
    <header className={css.root}>
      <h1 className={css.logo}>
        <Link to={routes('root')}>eLocker</Link>
      </h1>
      {user.id && <Nav className={css.nav} current={location.pathname} user={user} logout={actions.logout} />}
    </header>
  );
};

export default withRouter(Header);

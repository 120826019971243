const debug = require('debug')('mtk:order');
import React, { Fragment, useMemo, useEffect, useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Case from 'case';
import classnames from 'classnames';
import moment from 'moment';

import { FiX, FiRefreshCw, FiAlertTriangle as Triangle } from 'react-icons/fi';

import { can } from '../can';
import api from '../utils/api';
import { useForm } from '../utils/form';
import { routes } from '../routes';
import { roles, roleLabels, orderTypes, orderParams } from '../constants';

import { timeout, timeAgo, timeTo, formatDateTime, codeInputRegex } from '../utils';

import { Loading, NotFound } from './PageStatus';
import { List, Body, Row, Cell } from '../components/common/List';
import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/Button';
import Input, { InputRow } from '../components/common/Input';
import Toggle from '../components/common/Toggle';
import Select, { indexToOptions } from '../components/common/Select';
import Map, { Marker, LockerMarker, LocationMarker } from '../components/common/Map';
import OrderLifecycle from '../components/OrderLifecycle';
import OrderInfo from '../components/OrderInfo';
import OrderActivity from '../components/OrderActivity';
import Barcode from '../components/common/Barcode';

import grid from '../styles/grid.css';
import { getProperty } from 'dot-prop';
import css from './Order.css';

let baseOrderFields = {
  locationId: ['number'],
  courierId: ['number'],
  pickupName: ['text', true],
  pickupEmail: ['email', true],
  pickupPhone: ['tel'],
  pickupNationalId: ['rut'],
  width: ['number', true],
  length: ['number', true],
  courierPickupCode: ['text'],
  height: ['number', true],
  error: ['text'],
  preferences: {
    autoReversalEnabled: ['toggle'],
    autoReversalHours: ['number'],
    autoCancelReturnEnabled: ['toggle'],
    autoCancelReturnHours: ['number'],
  },
};

const Order = ({ match, history }) => {
  const { id } = match.params;
  const { user } = api.auth();

  const { item, actions } = api.orders.item(id, {}, { history });
  const { item: lockerItem, actions: lockerActions } = api.lockers.item(null, null, { lazy: true });
  const { actions: usersActions } = api.users.index(null, { lazy: true });

  const { order, clientId, clientPreferences, lockersAvailable } = useMemo(() => {
    return {
      order: item.loaded && item.data ? item.data.toJS() : { preferences: {} },
      clientId: item.loaded && item.data ? item.data.get('clientId') : null,
      clientPreferences: item.clientPreferences ? item.clientPreferences.toJS() : {},
      lockersAvailable: item.lockersAvailable ? item.lockersAvailable.toJS() : false,
    };
  }, [item.loaded, item]);

  // once the order loaded or whenever the reserved locker changes
  // get info on the current locker
  useMemo(() => {
    const lockerId = lockerItem.loaded ? lockerItem.data.get('id') : false;
    if (order.lockerId && lockerId && lockerId !== order.lockerId) {
      lockerActions.get(null, null, order.lockerId);
    }
  }, [order, lockerItem, lockerItem.loaded]);

  const lockerOffline = useMemo(
    () => order.lockerId && lockerItem.loaded && !lockerItem.data.get('online'),
    [order, lockerItem.loaded]
  );

  // Get activity whenever id changes
  useMemo(() => actions.history(), [id]);
  const activity = useMemo(() => (item.history ? item.history.toJS().activity : []), [item.history]);

  // add custom order fields
  const orderFields = useMemo(() => {
    if (!clientPreferences.extras) return baseOrderFields;
    const extras = baseOrderFields.extras || {};
    Object.keys(clientPreferences.extras).forEach((key) => {
      extras[key] = [clientPreferences.extras[key]];
    });
    return { ...baseOrderFields, extras };
  }, [clientPreferences]);

  // initialize form
  const form = useForm(orderFields, item.data, { submit: actions.update });

  useEffect(() => {
    if (clientPreferences.autoReversalEnabled && typeof order.preferences.autoReversalEnabled !== 'boolean') {
      form.setValue(['preferences', 'autoReversalEnabled'], true, true);
    }
  }, [order, clientPreferences]);

  const [state, setState] = useState({ showPickupCode: false, showPrintView: false });
  const [loc, setLoc] = useState({ lat: 0, lon: 0 });

  const isAdmin = user.role == roles('admin');

  // AUTOREVERSAL

  const {
    autoReversalEnabled,
    autoReversalHours,
    autoReversalAt,
    autoCancelReturnEnabled,
    autoCancelReturnHours,
    autoCancelReturnAt,
  } = useMemo(() => {
    const autoReversalEnabled = form.getValue(['preferences', 'autoReversalEnabled']);
    const autoReversalHours =
      form.getValue(['preferences', 'autoReversalHours']) ||
      clientPreferences.autoReversalHours ||
      order.preferences.autoReversalHours ||
      72;
    const autoReversalAt = order.readyAt ? moment(order.readyAt).add(autoReversalHours, 'hours') : false.valueOf;
    const autoCancelReturnEnabled = form.getValue(['preferences', 'autoCancelReturnEnabled']);
    const autoCancelReturnHours =
      form.getValue(['preferences', 'autoCancelReturnHours']) ||
      clientPreferences.autoCancelReturnHours ||
      order.preferences.autoCancelReturnHours ||
      72;

    const autoCancelReturnAt = order.readyAt ? moment(order.readyAt).add(autoCancelReturnHours, 'hours') : false;

    return {
      autoReversalEnabled,
      autoReversalHours,
      autoReversalAt,
      autoCancelReturnEnabled,
      autoCancelReturnHours,
      autoCancelReturnAt,
    };
  }, [order, clientPreferences, form]);

  const readOnly = !can(user, 'update', 'orders') || ['completed', 'cancelled'].includes(order.status) || lockerOffline;
  const isReturn = order.orderType == 'return';
  const barcodeRef = useRef();

  // ACTION

  // this is a bit cumbersome but it does the trick.
  // we need to wait for the order to load, in order to correctly load the couriers
  // this contraption continues to run until there is a clientId to use for couriers.
  // the useRef is necessary to make sure the recursive function is using the current
  // version of the function (with the latest clientId)
  const getCouriers = useRef();

  getCouriers.current = async (search, role) => {
    if (!order.clientId) {
      await timeout(200);
      return getCouriers.current(search, '!noAccess');
    }
    return indexToOptions(
      await usersActions.get({ search, role: '!noAccess', status: 'active', clientId: order.clientId, perPage: 50 })
    );
  };
  const onMapChange = (loc) => {
    setLoc(loc);
  };

  const findAvailableLockers = () => {
    const values = form.getValues().toJS();
    const clientId = order.clientId;
    actions.lockersFindAvailable({ ...values, ...loc, clientId });
  };

  const lockerReserve = (lockerId) => {
    actions.lockerReserve(lockerId);
  };

  const lockerUnreserve = () => {
    actions.lockerUnreserve(order.lockerId);
  };

  const sendNotification = (notificationType) => {
    actions.sendNotification(notificationType);
  };

  const onCancel = async () => {
    await actions.cancel();
  };

  const onDelete = async () => {
    await actions.delete();
  };

  const onReturnFromOrder = async () => {
    await actions.returnFromOrder();
  };

  const togglePickupCode = () => {
    setState((s) => ({ ...s, showPickupCode: !s.showPickupCode }));
  };

  if (item.status == 404) return <NotFound />;
  if (!item.loaded) return <Loading />;

  return (
    <main className={css.root}>
      <PageHeader
        section="orders"
        title={`Orden #${id}`}
        subtitle={
          <Fragment>
            <h3>
              <span className={classnames(css.orderType, [css[order.orderType]])}>{orderTypes(order.orderType)}</span>
              {order.orderType == 'return'
                ? ` de ${order.pickupName} ${isAdmin ? `a ${order.clientName ?? '...'}` : ''}`
                : ` ${isAdmin ? `de ${order.clientName}` : ''} para ${order.pickupName}`}
            </h3>

            {order.returnOrderId && (
              <>
                Orden de devolución <strong>{order.returnOrderId}</strong> &nbsp;&nbsp;&nbsp;
                <Button small to={routes('order', { id: order.returnOrderId })}>
                  Ver orden
                </Button>
              </>
            )}
            {order.fromOrderId && (
              <>
                Creado desde envío <strong>{order.fromOrderId}</strong> &nbsp;&nbsp;&nbsp;
                <Button small to={routes('order', { id: order.fromOrderId })}>
                  Ver orden
                </Button>
              </>
            )}
            {lockerOffline ? (
              <Fragment>
                <Triangle color={'var(--color-red)'} /> <strong>Locker Offline</strong>
                {` Edición temporalmente deshabilitada.`}
              </Fragment>
            ) : null}
          </Fragment>
        }
        dirty={form.dirty}
        autoSave={!lockerOffline}
        {...item}>
        {order.status == 'ready' && order.orderType == 'delivery' && (
          <div style={{ marginBottom: '2rem' }}>
            <p>
              <small>
                {'Paquete en puerta desde '}
                <strong>{formatDateTime(order.readyAt)}</strong> ({timeAgo(order.readyAt)})
              </small>
            </p>
          </div>
        )}
        {can(user, 'delete', 'orders') && ['created', 'reserved'].includes(order.status) && (
          <Button confirm="¿Eliminar esta orden?" destructive onClick={onDelete} disabled={lockerOffline}>
            Eliminar
          </Button>
        )}
        {can(user, 'cancel', 'orders') && ['created', 'reserved'].includes(order.status) && (
          <Button disabled={lockerOffline} confirm="¿Cancelar esta orden?" destructive onClick={onCancel}>
            Cancelar orden
          </Button>
        )}

        {clientPreferences.returnsEnabled &&
          order.status == 'completed' &&
          order.orderType == 'delivery' &&
          !order.returnOrderId && (
            <Button
              confirm="¿Crear una devolución basada en esta orden?"
              onClick={onReturnFromOrder}
              loading={item.loading && item.action == 'create-order'}>
              {item.loading && item.action == 'create-order' ? 'Creando devolución' : 'Crear devolución'}
            </Button>
          )}
      </PageHeader>

      {item.loaded && item.data && <OrderLifecycle {...item.data.toJS()} />}

      <div className={classnames(grid.full, grid.nest)}>
        <div className={grid.grid}>
          <div className={classnames(grid.half, css.reversal)}>
            {can(user, 'notification', 'orders') &&
              ['created', 'reserved'].includes(order.status) &&
              order.courierId > 0 &&
              order.orderType == 'delivery' && (
                <div>
                  <Button
                    small
                    confirm={`¿Reenviar notificación de asignación a courier ${
                      order.courierName || order.courierEmail
                    }?`}
                    disabled={!order.lockerId}
                    onClick={() => sendNotification('courierAssigned')}>
                    Reenviar notificación de asignación a courier
                  </Button>
                  {!order.lockerId && (
                    <small>Solo se pueden enviar notificaciones al courier una vez que se asignó un locker.</small>
                  )}
                </div>
              )}

            {can(user, 'notification', 'orders') && order.status == 'ready' && order.orderType == 'reversal' && (
              <Fragment>
                <Button
                  small
                  confirm={`¿Reenviar notificación a ${order.pickupName}?`}
                  onClick={() => sendNotification('pickupReversal')}>
                  Reenviar notificación de revocación a usuario
                </Button>
                <Button
                  small
                  confirm={`¿Reenviar notificación a ${order.courierName || order.courierEmail}?`}
                  onClick={() => sendNotification('courierReversal')}>
                  Reenviar notificación de revocación a courier
                </Button>
              </Fragment>
            )}
            {can(user, 'notification', 'orders') && order.status == 'completed' && order.orderType == 'delivery' && (
              <Button
                small
                confirm={`¿Reenviar notificación a ${order.pickupName}?`}
                onClick={() => sendNotification('pickupCompleted')}>
                Reenviar notificación de orden completada
              </Button>
            )}

            {can(user, 'notification', 'orders') && order.orderType == 'return' && (
              <Fragment>
                {order.status == 'reserved' && (
                  <Button
                    small
                    confirm={`¿Reenviar notificación a ${order.pickupName}?`}
                    onClick={() => sendNotification('returnReserved')}>
                    Reenviar notificación de locker reservado
                  </Button>
                )}
                {order.status == 'ready' && (
                  <Button
                    small
                    confirm={`¿Reenviar notificación a courier?`}
                    onClick={() => sendNotification('returnReady')}>
                    Reenviar notificación a courier
                  </Button>
                )}
              </Fragment>
            )}
          </div>
        </div>
        <div className={classnames(grid.half, css.errors)}>
          {form.getValue('error') || state.showError ? (
            <Input
              className={classnames({ [css.errorField]: form.getValue('error') })}
              name="error"
              label="Error"
              readOnly={readOnly}
              clickToEdit
              form={form}
              placeholder="Sin error"
            />
          ) : (
            <Button disabled={readOnly} small onClick={() => setState((s) => ({ ...s, showError: true }))}>
              Agregar Error
            </Button>
          )}
        </div>
      </div>
      <div className={grid.grid}>
        <section className={grid.half}>
          <h2>Bulto</h2>
          <InputRow>
            <Input
              className={css.measurementsInput}
              name="width"
              form={form}
              readOnly={order.lockerId || readOnly}
              label="Ancho"
              sufix=" cm"
              min="1"
            />
            <FiX className={css.measurementsIcon} />
            <Input
              className={css.measurementsInput}
              name="height"
              form={form}
              readOnly={order.lockerId || readOnly}
              label="Alto"
              sufix=" cm"
              min="1"
            />
            <FiX className={css.measurementsIcon} />
            <Input
              className={css.measurementsInput}
              name="length"
              form={form}
              readOnly={order.lockerId || readOnly}
              label="Largo"
              sufix=" cm"
              min="1"
            />
          </InputRow>
          {order.lockerId && <small>Solo se pueden modificar las dimensiones antes de reservar un locker</small>}
          {autoReversalEnabled && can(user, 'reversal', 'orders') && order.orderType == 'delivery' && (
            <h2>Revocación</h2>
          )}

          {clientPreferences.autoReversalEnabled && order.orderType == 'delivery' && order.status !== 'cancelled' && (
            <>
              <p className={classnames(css.autoreversalNote)}>
                <small>
                  La orden {autoReversalEnabled ? null : <strong>NO</strong>} será revocada automáticamente
                  {autoReversalEnabled ? (
                    <>
                      {' '}
                      luego de {autoReversalHours} horas de ser entregada en el locker
                      {autoReversalAt ? (
                        <span style={{ display: 'inline-block' }}>
                          {', el '}
                          <strong>{formatDateTime(autoReversalAt, 'D [de] MMM [de] YYYY [a] [las] hh:mma')}</strong> (
                          {timeAgo(autoReversalAt)}){' '}
                        </span>
                      ) : null}
                    </>
                  ) : null}
                  .
                </small>
              </p>
              {can(user, 'reversal', 'orders') && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Toggle
                    style={{ paddingTop: 7 }}
                    form={form}
                    name={['preferences', 'autoReversalEnabled']}
                    label="Auto-revocación"
                    readOnly={readOnly}
                  />
                  {autoReversalEnabled && (
                    <Input
                      name={['preferences', 'autoReversalHours']}
                      label={'Ajustar cantidad de horas para iniciar auto-revocación'}
                      placeholder={autoReversalHours}
                      sufix="horas"
                      readOnly={readOnly}
                      form={form}
                    />
                  )}
                </div>
              )}
            </>
          )}

          {order.status == 'ready' && order.orderType == 'delivery' && (
            <div>
              {can(user, 'reversal', 'orders') && (
                <Button small confirm="¿Revocar esta orden?" disabled={readOnly} onClick={actions.reversal}>
                  Revocar orden ahora
                </Button>
              )}
              {can(user, 'notification', 'orders') && (
                <Button
                  small
                  confirm={`¿Reenviar notificación a ${order.pickupName}?`}
                  onClick={() => sendNotification('pickupReady')}>
                  Reenviar notificación de retiro a usuario
                </Button>
              )}
            </div>
          )}
          {order.orderType == 'reversal' && (
            <p className={classnames(css.autoreversalNote)}>
              <small>Esta orden ya fue revocada.</small>
            </p>
          )}
          <h2>{isReturn ? 'Remitente' : 'Destinatario'}</h2>
          <Input name="pickupName" form={form} readOnly={readOnly} label="Nombre" />
          <Input name="pickupEmail" form={form} readOnly={readOnly} label="Email" />
          <Input name="pickupPhone" form={form} readOnly={readOnly} label="Teléfono" />
          {clientPreferences.enableUsersNationalId && (
            <Input name="pickupNationalId" form={form} readOnly={readOnly} label="Cédula" />
          )}
          <Fragment>
            <h2>Courier</h2>
            <Select
              name="courierId"
              form={form}
              key="order-courier-select"
              loadOptions={getCouriers.current}
              label="Courier"
              readOnly={readOnly}
              placeholder="No asignado"
            />
          </Fragment>
          {clientPreferences.extras && (
            <Fragment>
              <h2>Extras</h2>
              {Object.keys(clientPreferences.extras).map((field, index) => (
                <Input
                  key={`extras-field-${index}`}
                  name={['extras', field]}
                  readOnly={readOnly}
                  form={form}
                  label={Case.capital(field)}
                />
              ))}
            </Fragment>
          )}

          {isReturn ? (
            <section id={'return-codes'}>
              <h3>Código de entrega (cliente)</h3>
              <p>
                <small>
                  El código de entrega se envía directamente al remitente via email una vez que se hace una reserva de
                  locker.
                </small>
              </p>
              {order.status == 'reserved' && order.orderType == 'return' && can(user, 'notification', 'orders') && (
                <Button
                  small
                  confirm={`¿Reenviar notificación a ${order.pickupName}?`}
                  onClick={() => sendNotification('returnReserved')}>
                  Reenviar notificación de retiro a usuario
                </Button>
              )}

              <h3>Código de retiro (courier)</h3>
              {form.getValue('courierPickupCode') ? (
                <div className={css.row}>
                  <Barcode className={css.barcode} ref={barcodeRef} code={form.getValue('courierPickupCode')} />
                  <ReactToPrint
                    trigger={() => (
                      <div style={{ textAlign: 'center' }}>
                        <Button>Imprimir código</Button>
                        <div>
                          <small>(etiqueta 10 x 5 cm)</small>
                        </div>
                      </div>
                    )}
                    content={() => barcodeRef.current}
                  />
                </div>
              ) : (
                <p>
                  <small>
                    {order.status == 'ready'
                      ? 'El paquete puede ser retirado sin código por cualquier courier autorizado.'
                      : 'El paquete podrá ser retirado sin código por cualquier courier autorizado.'}
                  </small>
                </p>
              )}
              <Input
                name="courierPickupCode"
                form={form}
                beforeChange={(v) => v.replace(codeInputRegex, '')}
                placeholder="Sin código"
                readOnly={readOnly}
                label="Código de retiro courier"
                clickToEdit={order.orderType !== 'reversal'}
              />
            </section>
          ) : (
            <section id={'delivery-codes'}>
              {order.dropCode && (
                <Fragment>
                  <h3>Código de entrega (courier)</h3>
                  <div className={css.row}>
                    <Barcode
                      className={css.barcode}
                      bottomLabel={
                        <>
                          {clientPreferences.printOrderParams &&
                            clientPreferences.printOrderParams
                              .split(',')
                              .map((param) => ({ name: param.trim(), value: getProperty({ order }, param.trim()) }))
                              .filter((prop) => prop.value && prop.value.length > 0)
                              .map((prop, key) => (
                                <div key={key}>
                                  {orderParams(prop.name.split('.').pop())}: <strong>{prop.value}</strong>
                                </div>
                              ))}
                        </>
                      }
                      ref={barcodeRef}
                      code={order.dropCode}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <div style={{ textAlign: 'center' }}>
                          <Button>Imprimir código</Button>
                          <div>
                            <small>(etiqueta 10 x 5 cm)</small>
                          </div>
                        </div>
                      )}
                      content={() => barcodeRef.current}
                    />
                  </div>
                </Fragment>
              )}
              <h3>Código de retiro (cliente)</h3>
              <p>
                <small>
                  El código de retiro se envía directamente al destinatario via email una vez que el bulto es entregado
                  en el locker.
                </small>
                {clientPreferences.displayPickupCode && (
                  <Button small onClick={togglePickupCode}>
                    {state.showPickupCode ? 'Ocultar' : 'Mostrar'} código de retiro
                  </Button>
                )}
                {state.showPickupCode && <b>{order.pickupCode}</b>}
              </p>
              {order.status == 'ready' && order.orderType == 'delivery' && can(user, 'notification', 'orders') && (
                <Button
                  small
                  confirm={`¿Reenviar notificación a ${order.pickupName}?`}
                  onClick={() => sendNotification('pickupReady')}>
                  Reenviar notificación de retiro a usuario
                </Button>
              )}
              <h3>Código de revocación (courier)</h3>
              {form.getValue('courierPickupCode') ? (
                <div className={css.row}>
                  <Barcode className={css.barcode} ref={barcodeRef} code={form.getValue('courierPickupCode')} />
                  <ReactToPrint
                    trigger={() => (
                      <div style={{ textAlign: 'center' }}>
                        <Button>Imprimir código</Button>
                        <div>
                          <small>(etiqueta 10 x 5 cm)</small>
                        </div>
                      </div>
                    )}
                    content={() => barcodeRef.current}
                  />
                </div>
              ) : (
                <p>
                  <small>
                    {order.orderType == 'reversal' && order.status == 'ready'
                      ? 'Esta orden puede ser retirada por cualquier courier autorizado.'
                      : 'Una vez que sea revocada, la orden podrá ser retirada sin código por cualquier courier autorizado'}
                  </small>
                </p>
              )}
              <Input
                name="courierPickupCode"
                form={form}
                beforeChange={(v) => v.replace(codeInputRegex, '')}
                placeholder="Sin código"
                readOnly={readOnly}
                label="Código de retiro courier"
                clickToEdit={order.orderType !== 'reversal'}
              />
            </section>
          )}
        </section>

        <section className={classnames(grid.half)}>
          <h2>Locker</h2>
          <Map
            readOnly={readOnly}
            lat={order.locationLat}
            lon={order.locationLon}
            onChange={onMapChange}
            compact={order.lockerId}>
            {!order.lockerId && order.locationId && (
              <LocationMarker mini={!!lockersAvailable} lat={order.locationLat} lng={order.locationLon} />
            )}
            {order.lockerId && order.locationLat ? (
              <LockerMarker lat={order.locationLat} lng={order.locationLon} />
            ) : (
              lockersAvailable &&
              lockersAvailable.map((l, index) => {
                return (
                  <LockerMarker
                    hover={state.hoveredLocker == l.id}
                    key={`locker-marker-${index}`}
                    lat={l.locationLat}
                    lng={l.locationLon}
                    onMouseEnter={() => setState((s) => ({ ...s, hoveredLocker: l.id }))}
                    onMouseLeave={() => setState((s) => ({ ...s, hoveredLocker: null }))}
                  />
                );
              })
            )}
          </Map>
          {!order.lockerId && order.locationId && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '1rem 0',
              }}>
              <p style={{ fontSize: '1rem' }}>
                Ubicación seleccionada: <br />
                <strong>{order.locationName}</strong>
                <br />
                {order.locationAddress}
              </p>
              {can(user, 'update', 'orders') && (
                <Button small onClick={() => form.setValue('locationId', null)}>
                  Remover preferencia de ubicación
                </Button>
              )}
            </div>
          )}
          {order.lockerId ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Locker reservado: <strong>{order.lockerName}</strong>
              </span>
              {can(user, 'update', 'orders') && order.status == 'reserved' && (
                <Button disabled={lockerOffline} onClick={lockerUnreserve}>
                  Cancelar reserva
                </Button>
              )}
            </div>
          ) : lockersAvailable ? (
            <List>
              <Body>
                {lockersAvailable.length == 0 ? (
                  <Row>
                    <Cell>
                      <p>No hay lockers disponibles cerca de esta ubicación</p>
                    </Cell>
                  </Row>
                ) : (
                  lockersAvailable.map((l, index) => (
                    <Row
                      hover={state.hoveredLocker == l.id}
                      key={`locker-${index}`}
                      style={{ verticalAlign: 'top' }}
                      onMouseEnter={() => setState((s) => ({ ...s, hoveredLocker: l.id }))}
                      onMouseLeave={() => setState((s) => ({ ...s, hoveredLocker: null }))}>
                      <Cell top>
                        <h3>{l.name}</h3>
                        <small
                          style={{
                            color: l.doorsAvailable > 3 ? 'var(--color-green)' : 'var(--color-yellow)',
                          }}>
                          <strong>{l.doorsAvailable} disponibles</strong>
                        </small>
                      </Cell>
                      <Cell>
                        <h3>{l.locationName}</h3>
                        <small>{l.locationAddress}</small>
                      </Cell>
                      {can(user, 'update', 'orders') && (
                        <Cell
                          style={{
                            verticalAlign: 'center',
                            paddingLeft: 5,
                          }}>
                          <Button
                            small
                            disabled={!l.online}
                            onClick={() => lockerReserve(l.id)}
                            style={{ width: '100%', marginBottom: 5 }}>
                            {l.online ? 'Reservar locker' : 'Locker offfline'}
                          </Button>
                          <Button
                            small
                            style={{ width: '100%' }}
                            onClick={() => form.setValue('locationId', l.locationId)}>
                            Guardar preferencia de ubicación
                          </Button>
                        </Cell>
                      )}
                    </Row>
                  ))
                )}
              </Body>
            </List>
          ) : null}
          {!order.lockerId && (
            <Button disabled={readOnly} onClick={findAvailableLockers}>
              {lockersAvailable ? 'Volver a buscar' : 'Buscar lockers disponibles'}
            </Button>
          )}

          <h3>Info</h3>
          <OrderInfo order={order} />
          <OrderActivity activity={activity} order={order} />
          <Button onClick={actions.history} loading={item.loading && item.action == 'load-history'}>
            {item.loading && item.action == 'load-history' ? null : <FiRefreshCw />}
            &nbsp; Refrescar
          </Button>
        </section>
      </div>
    </main>
  );
};

export default Order;

const debug = require('debug')('mtk:react:segment');
import React, { Fragment } from 'react';

import api from '../utils/api';
import { useForm } from '../utils/form';
import { roles } from '../constants';

import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import Toggle from '../components/common/Toggle';
import Select, { indexToOptions } from '../components/common/Select';

import Info from '../components/Info';

import grid from '../styles/grid.css';

let segmentFields = {
  name: ['text', true],
  scoped: ['toggle'],
  scopeToClientId: [
    'number',
    false,
    {
      required: (v, values) => !!values.get('scoped'),
    },
  ],
};

const SegmentNew = ({ history }) => {
  const { user } = api.auth();
  const { item, actions } = api.segments.newItem({ history });
  const { actions: clientsActions } = api.clients.index(null, { lazy: true });

  const form = useForm(segmentFields, item.data);

  const isSubGroup = form.getValue('scoped');
  const isAdmin = user.role == roles('admin');
  const name = form.getValue('name');

  const getClients = async (search) => {
    const index = await clientsActions.get({ search, perPage: 50 });
    const options = indexToOptions(index, { value: 'id', label: 'name' });
    return options;
  };
  const onClientChange = (name, option) => {
    form.setValue(name, option);
  };

  const handleCreate = async () => {
    if (form.validate(null, true)) {
      actions.create(form.getValues());
    }
  };

  return (
    <main>
      <PageHeader
        section="segments"
        title={`Nuevo ${isSubGroup ? 'Sub-' : ''}Grupo${name ? ': ' + name : ''}`}
        {...item}
      />
      <div className={grid.grid}>
        <section className={grid.half}>
          {isAdmin && (
            <Info>
              <ul>
                <li>
                  Un <strong>grupo</strong> autoriza clientes para operar un grupo de lockers.
                </li>
                <li>
                  Un <strong>sub-grupo</strong> autoriza a usuarios de un cliente para operar un sub-grupo de lockers
                  dentro de los que ese cliente tenga acceso.
                </li>
              </ul>
            </Info>
          )}
          {isAdmin && (
            <Fragment>
              <Toggle name="scoped" label="Sub-grupo" form={form} />
              {isSubGroup ? (
                <Select
                  label="Cliente"
                  name="scopeToClientId"
                  onChange={onClientChange}
                  loadOptions={getClients}
                  form={form}
                  noOptionsMessage={(a) => {
                    if (a.inputValue) {
                      return `No hay clientes que contengan "${a.inputValue}"`;
                    }
                    return 'No hay clientes.';
                  }}
                />
              ) : null}
            </Fragment>
          )}
          <Input name="name" label="Nombre" placeholder="Nombre de Grupo" form={form} onEnter={handleCreate} />
          <div style={{ height: 16 }} />
          <Button onClick={handleCreate}>Crear grupo</Button>
        </section>
        <section className={grid.half}>
          <div />
        </section>
      </div>
    </main>
  );
};

export default SegmentNew;

import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { kebab } from 'case';

import css from './Toggle.css';

const Toggle = (props) => {
  const { name, form, className, style, label, innerLabels, readOnly, onClick } = props;

  const id = kebab(`input-${name}-${label}`);
  const value = typeof props.value !== 'undefined' ? props.value : form.getValue(name);
  const onChange = form ? form.setValue : props.onChange;

  const handleOnClick = (e) => {
    if (readOnly) return;
    onClick && onClick(name, e.target.checked, e);
    onChange(name, e.target.checked, e);
  };

  return (
    <div className={classnames(css.root, { [css.readOnly]: readOnly })} style={style}>
      <input
        id={id}
        className={css.checkbox}
        type="checkbox"
        checked={value}
        onChange={handleOnClick}
        readOnly={readOnly}
      />
      <div
        className={classnames(css.toggle, className, {
          [css.on]: value,
        })}>
        <span className={css.slider} />

        {innerLabels && innerLabels.length == 2 && (
          <span className={css.innerLabels}>
            <span className={css.innerLabel}>{innerLabels[0]}</span>
            <span className={css.innerLabel}>{innerLabels[1]}</span>
          </span>
        )}
      </div>
      <label htmlFor={id} className={css.label}>
        {label}
      </label>
    </div>
  );
};

export default Toggle;

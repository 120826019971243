import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import isEqual from 'lodash/fp/isEqual';
import isObject from 'lodash/fp/isObject';

import Link from './common/Link';
import Label from './common/Label';
import { routes } from '../routes';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

import { formatDateTime } from '../utils';
import { orderActions } from '../constants';
import { objectLabels } from '../utils/api/constants';

import css from './OrderActivity.css';

export const OrderActivity = (props) => {
  const [current, setCurrent] = useState();
  const onSetCurrent = (i) => {
    setCurrent(i == current ? null : i);
  };

  const activity = useMemo(() => parseActivity(props.activity, props.order), [props.activity]);

  return (
    <div className={css.root}>
      <h3>Historial</h3>
      {activity ? (
        activity.map((a, index) => {
          const changeCount = a.changes ? a.changes.length : 0;
          const isOpen = current == index;
          return (
            <div key={`activity-${index}`} className={css.row}>
              <h4 className={css.label}>
                <span className={css.leftGroup}>
                  {a.label}
                  {changeCount == 1 ? <span style={{ fontWeight: 'normal' }}>: {a.changes[0].label}</span> : null}
                  {changeCount > 1 ? <span style={{ fontWeight: 'normal' }}>({changeCount} cambios)</span> : null}
                  {a.topicType == 'requests' && (
                    <Label type={a.retries > 5 ? 'error' : 'normal'}>
                      {a.retries > 0 && `${a.retries + 1} intentos`}
                    </Label>
                  )}
                  {(a.whatsAppFailed || a.whatsAppRead) && (
                    <Label type={a.whatsAppFailed ? 'error' : a.whatsAppRead ? 'success' : 'normal'}>
                      WhatsApp: {whatsAppStatus(a)}
                    </Label>
                  )}
                </span>
                <span>
                  <span className={css.labelDate}>{formatDateTime(a.createdAt)}</span>
                  <span
                    className={classnames(css.toggle, {
                      [css.hidden]: !a.userId && !changeCount && !a.notifications,
                    })}
                    onClick={() => onSetCurrent(index)}>
                    {isOpen ? <FiChevronUp /> : <FiChevronDown />}
                  </span>
                </span>
              </h4>
              <div
                className={classnames(css.details, {
                  [css.open]: isOpen,
                })}>
                {changeCount
                  ? a.changes.map((ch, chIndex) => {
                      return (
                        <div key={`change-${index}-${chIndex}`} className={css.change}>
                          <span>{ch.label}</span>: {!!ch.prev ? <code>{ch.prev}</code> : ''}{' '}
                          {ch.action == 'modify' ? '→' : ''}
                          {!!ch.next ? <code>{ch.next}</code> : ''}
                        </div>
                      );
                    })
                  : ''}
                {a.notifications &&
                  a.notifications.map((notification, notificationIndex) => {
                    return <div key={`notification-${notificationIndex}`}>{notification.label}</div>;
                  })}
                <div>
                  {!a.userId ? null : a.userId == 0 ? (
                    <small>Por: Destinatario</small>
                  ) : (
                    <small>
                      {'Por: '}
                      <Link target="_blank" minimal={false} to={routes('user', { id: a.userId })}>
                        Usuario #{a.userId} {a.userName}
                      </Link>
                    </small>
                  )}
                </div>
                {a.data && (
                  <pre className={css.data} style={{ display: 'none' }}>
                    {'{ payload: '}
                    {JSON.stringify(a.data, null, 2)}
                    {'}'}
                  </pre>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <p>No hay actividad registrada para esta orden</p>
      )}
    </div>
  );
};

const addToLabel = (label, toAdd) => {
  if (label.props) {
    return (
      <>
        {label}
        &nbsp;
        {toAdd}
      </>
    );
  } else {
    return label + toAdd;
  }
};

const whatsAppStatus = (a) => {
  if (a.whatsAppRead) return 'Leído';
  if (a.whatsAppDelivered) return 'Entregado';
  if (a.whatsAppSent) return 'Enviado';
  if (a.whatsAppFailed) return 'Error en envio';
  if (a.whatsApp) return 'Enviado';

  return '';
};

const parseActivity = (act = [], order) => {
  const activity = act.sort((a, b) => {
    let aa = new Date(a.createdAt).getTime();
    let bb = new Date(b.createdAt).getTime();
    return bb > aa ? -1 : 1;
  });
  let last = {};
  let retries = 0;
  const events = [
    {
      label: orderActions('createOrder'),
      createdAt: order.createdAt,
      changes: [],
    },
  ];
  for (var i = 0; i < activity.length; i++) {
    const item = activity[i];
    const { type, payload } = item;
    let a = { ...payload };

    a.createdAt = activity[i].createdAt;

    if (type == 'notification') {
      events.push({
        ...a,
        changes: [],
        notifications: [
          {
            label: (
              <>
                Email: <strong>{a.email ? 'Enviado' : 'No enviado'}</strong>
              </>
            ),
          },
          {
            label: (
              <>
                SMS: <strong>{a.sms ? 'Enviado' : 'No enviado'}</strong>
              </>
            ),
          },
          {
            label: (
              <>
                WhatsApp: <strong>{a.whatsApp ? whatsAppStatus(a) : 'No enviado'}</strong>
              </>
            ),
          },
        ],
        label: `Notificación de ${objectLabels(a.notificationType)}`,
      });

      continue;
    }

    a.label = orderActions(a.messageType) + (a.topicType == 'requests' ? ' solicitada' : '');
    let next = i == activity.length - 1 ? false : activity[i + 1];
    if (a.topicType == 'requests' && next && next.payload.topicType == 'requests' && type == next.type) {
      retries++;
      continue;
    } else if (a.topicType == 'requests' && retries == 0) {
      continue;
    } else if (a.topicType == 'requests') {
      a.retries = retries;
    } else {
      retries = retries > 0 && a.topicType == 'accepts' ? -1 : 0;
    }

    a.changes = [];

    if (['reserveOrder', 'unreserveOrder'].includes(type)) {
      a.label = (
        <>
          {orderActions(type)}
          {a.data.lockerId && (
            <>
              &nbsp;–&nbsp; <Link to={`/lockers/${a.data.lockerId}`}>Locker #{a.data.lockerId}</Link>
            </>
          )}
        </>
      );
    } else if (['readyOrder'].includes(type)) {
      a.label = (
        <>
          {orderActions(type)}&nbsp;–&nbsp;<Link to={`/lockers/${a.data.lockerId}`}> Puerta #{a.data.doorId}</Link>
        </>
      );
    } else if ('reversalOrder' == type) {
      a.label = 'Revocación de orden';
    } else if ('completeOrder' == type) {
      a.label = 'Orden completada';
    } else if ('cancelOrder' == type) {
      a.label = 'Cancelación de orden';
    } else if ('deleteOrder' == type) {
      a.label = 'Eliminación de orden';
    } else if ('updateOrder' == type) {
      a.label = 'Modificación ';
      a = checkChange(a, last, 'width', '{{}} ancho del bulto', ['Agregar', 'Modificar', 'Eliminar']);
      a = checkChange(a, last, 'height', '{{}} alto del bulto', ['Agregar', 'Modificar', 'Eliminar']);
      a = checkChange(a, last, 'length', '{{}} largo del bulto', ['Agregar', 'Modificar', 'Eliminar']);

      a = checkChange(a, last, 'courierId', '{{}} courier', ['Asignar', 'Reasignar', 'Desasignar']);
      a = checkChange(a, last, 'locationId', '{{}}} ubicación de entrega', [
        'Seleccionar',
        'Modificar',
        'Deseleccionar',
      ]);

      a = checkChange(a, last, 'pickupName', '{{}} nombre del destinatario', ['Agregar', 'Modificar', 'Eliminar']);
      a = checkChange(a, last, 'pickupEmail', '{{}} email del destinatario', ['Agregar', 'Modificar', 'Eliminar']);
      a = checkChange(a, last, 'pickupPhone', '{{}} teléfono del destinatario', ['Agregar', 'Modificar', 'Eliminar']);
      a = checkChange(a, last, 'extras', '{{}} campos adicionales', ['Agregar', 'Modificar', 'Eliminar']);
      a = checkChange(a, last, 'preferences', '{{}} las preferencias', ['Agregar', 'Modificar', 'Eliminar']);
      a = checkChange(a, last, 'error', '{{}} error', ['Agregar', 'Modificar', 'Eliminar']);
      if (a.changes.length == 0) a.skip = true;
    } else {
      a.changes.push({ action: type, label: orderActions(type) });
    }

    if (a.topicType == 'requests') {
      a.label = addToLabel(a.label, 'solicitada');
    }
    if (a.topicType == 'rejects') {
      a.label = addToLabel(a.label, 'rechazada');
    }

    if (a.topicType == 'accepts') {
      if (retries == -1) a.label = addToLabel(a.label, 'aceptada');
      retries = 0;
      last = { ...last, ...a.data };
    }

    if (!a.skip) events.push(a);
  }
  return events;
};

const checkChange = (activity, lastData, prop, label, actions) => {
  if (!Object.keys(activity.data).includes(prop)) return activity;
  let prev = lastData[prop];
  let next = activity.data[prop];

  if (isObject(next))
    try {
      prev = JSON.parse(prev);
    } catch (e) {}
  if (isObject(prev))
    try {
      next = JSON.parse(next);
    } catch (e) {}

  let change, action, actionLabel;

  if (!isEqual(prev, next)) {
    change = {
      prev: isObject(prev) ? JSON.stringify(prev) : prev,
      next: isObject(next) ? JSON.stringify(next) : next,
    };
    if (!prev && !!next) {
      actionLabel = actions[0];
      action = 'add';
    } else if (!!prev && !!next) {
      action = 'modify';
      actionLabel = actions[1];
    } else if (!!prev && !next) {
      action = 'remove';
      actionLabel = actions[2];
    }

    if (action) {
      change.action = action;
      change.label = label.replace('{{}}', actionLabel);
      activity.changes.push(change);
    }
  }
  return activity;
};

export default OrderActivity;

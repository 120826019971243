import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeBwipCanvas } from '../../utils/bwip';

const Barcode = (props) => {
  const { code, scale = 3, height = 10, label, bottomLabel, className, innerRef } = props;
  const [image, setImage] = useState();

  useMemo(async () => {
    const bwipSettings = {
      bcid: 'pdf417',
      text: code,
      scale,
      height,
    };

    const imgUrl = await makeBwipCanvas(bwipSettings);
    if (imgUrl) setImage(imgUrl);
  }, [code, scale, height]);

  return (
    <figure ref={innerRef} className={className} style={{ display: 'inline-block', margin: 0 }}>
      {label && <h3>{label}</h3>}
      <img src={image} style={{ maxWidth: '100%' }} />
      <figcaption style={{ textAlign: 'center' }}>{bottomLabel || code}</figcaption>
    </figure>
  );
};

Barcode.propTypes = {
  code: PropTypes.string.isRequired,
  scale: PropTypes.number,
  height: PropTypes.number,
  label: PropTypes.string,
};

export default React.forwardRef((props, ref) => <Barcode innerRef={ref} {...props} />);

const debug = require('debug')('mtk:cache');
const store = require('store2');

const cache = store.namespace('cache');

export const set = (key, value, exp = 5) => {
  cache.set(key, { value, exp: Date.now() + exp * 60 * 1000 });
};

export const get = (key) => {
  const element = cache.get(key);
  if (element && element.exp > Date.now()) {
    return element.value;
  } else if (element) {
    cache.remove(key);
  }
  return null;
};

const del = (key) => {
  cache.remove(key);
};

const deleteExpired = () => {
  let count = 0;
  const total = store.size();
  cache.each((key, value) => {
    if (value.exp < Date.now()) {
      cache.remove(key);
      count++;
    }
  });
  if (count) debug(`Deleted ${count} of ${total}.`);
};

const expirer = setInterval(deleteExpired, 1000);

export default {
  get,
  set,
  delete: del,
  store: cache,
};

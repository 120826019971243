import React from 'react';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';

import { timeAgo, formatDateTime } from '../utils';
import { routes } from '../routes';
import {
  orderTypes,
  orderStatusLabel,
  orderStatusColors,
  colors,
  getOrderIndexExtraColumnsByClientId,
} from '../constants';

import { List, Header, Body, Row, Cell } from './common/List';
import { Label, labelTypes } from './common/Label';
import { capital as titleCase } from 'case';

import css from './OrderList.css';

const OrderList = (props) => {
  const { orders, history, isAdmin, clientId, addFilter, className } = props;

  const orderIndexExtraColumns = getOrderIndexExtraColumnsByClientId(clientId);

  return (
    <List className={classnames(css.root, { [className]: className })}>
      <Header>
        <Cell head>ID</Cell>
        <Cell head>Tipo</Cell>

        <Cell head>Creada</Cell>
        {isAdmin && <Cell head>Cliente</Cell>}
        {orderIndexExtraColumns &&
          Object.keys(orderIndexExtraColumns).map((name, i) => (
            <Cell key={i} head>
              {name}
            </Cell>
          ))}
        <Cell head>Courier</Cell>
        <Cell head>Destinatario</Cell>
        <Cell head>Ubicación</Cell>
        <Cell head>Locker</Cell>
        <Cell head>Estado</Cell>
      </Header>
      <Body top>
        {orders && orders.length ? (
          orders.map((order, index) => {
            const to = routes('order', { id: order.id });

            return (
              <Row
                key={`orders-row-${index}`}
                className={classnames(css.row, css[order.orderType])}
                onClick={(e) => {
                  if (e.target.nodeName !== 'A') {
                    history.push(to);
                  }
                }}>
                <Cell className={css.id}>
                  <Link to={to}>{order.id}</Link>
                </Cell>
                <Cell>
                  <strong>{orderTypes(order.orderType)}</strong>
                  {order.fromOrderId ? (
                    <>
                      {' – de orden '}
                      <Link to={routes('order', { id: order.fromOrderId })}>
                        <strong>{order.fromOrderId}</strong>
                      </Link>
                    </>
                  ) : null}
                </Cell>

                <Cell className={css.date}>
                  <span>{formatDateTime(order.createdAt) || '...'}</span>
                  <span>{timeAgo(order.createdAt) || '...'}</span>
                </Cell>
                {isAdmin && (
                  <Cell className={css.name}>
                    <a onClick={() => addFilter('clientId', order.clientId)}>{order.clientName}</a>
                  </Cell>
                )}

                {orderIndexExtraColumns &&
                  Object.entries(orderIndexExtraColumns).map(([name, fn], i) => {
                    try {
                      return <Cell key={i}>{fn(order)}</Cell>;
                    } catch (err) {
                      console.error('orderIndexExtraColumnsFunction failed', name, fn, err);
                      return <Cell key={i} />;
                    }
                  })}

                <Cell style={{ color: order.courierId ? 'inherit' : colors.gray }}>
                  <a onClick={() => addFilter('courierId', order.courierId)}>
                    {order.courierName || order.courierEmail || 'No asignado'}
                  </a>
                </Cell>
                <Cell>{order.pickupName || order.pickupEmail || ''}</Cell>
                <Cell>{order.locationName || 'No asignada'}</Cell>
                <Cell style={{ color: order.lockerId ? 'inherit' : colors.gray }}>
                  {order.lockerName ? (
                    <a onClick={() => addFilter('lockerId', order.lockerId)}>
                      {order.lockerName} (#{order.lockerId})
                    </a>
                  ) : (
                    'No asignado'
                  )}
                </Cell>
                <Cell style={{ color: orderStatusColors(order) }} className={classnames(css.status)}>
                  <strong>{orderTypes(order.orderType)}: </strong>
                  {order.error ? (
                    <Label max={200} type={labelTypes('error')}>
                      {order.error}
                    </Label>
                  ) : (
                    orderStatusLabel(order)
                  )}
                </Cell>
              </Row>
            );
          })
        ) : (
          <Row>
            <Cell center colSpan={isAdmin ? 8 : 7}>
              <p style={{ padding: '1rem 0' }}>
                <small>No hay órdenes que coincidan con esta busqueda</small>
              </p>
            </Cell>
          </Row>
        )}
      </Body>
    </List>
  );
};

export default withRouter(OrderList);

import React from 'react';
import css from './Input.css';
import classnames from 'classnames';
import { kebab } from 'case';
import { isImmutable, fromJS } from 'immutable';

export const TextArea = (props) => {
  let {
    name,
    form,
    rows = 3,
    type,
    value,
    error,
    readOnly,
    touched,
    onChange,
    label,
    placeholder,
    sufix,
    note,
    className,
    onEnter,
    ...otherProps
  } = props;

  const id = kebab(`input-${name}-${label}`);

  value = form ? form.getValue(name) : _value;
  type = form ? form.getType(name) : _type;
  error = form ? form.getError(name) : _error;
  touched = form ? form.getTouched(name) : true;
  onChange = onChange ? onChange : form.setValue;

  // this should be a custom proptype function
  if (typeof value == 'undefined' || typeof onChange == 'undefined')
    console.error('either form or (value && onChange) props must be provided!');

  const handleOnChange = (evt) => {
    onChange(name, evt.target.value);
  };

  return (
    <div
      className={classnames(css.root, {
        [className]: className,
        [css.withError]: touched && error,
        [css.readOnly]: readOnly,
      })}>
      {label ? (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      ) : null}
      <div className={css.inputWrapper}>
        <textarea
          id={id}
          className={classnames(css.textArea, { [css.json]: type == 'json' })}
          onChange={handleOnChange}
          value={type == 'json' && isImmutable(value) ? JSON.stringify(value, null, 2) : value}
          {...{ label, rows, placeholder, readOnly, ...otherProps }}
        />
        {sufix && <span className={css.sufix}>{sufix}</span>}
      </div>
      {touched && error && <p className={css.error}>{error}</p>}
      {note && <p className={css.note}>{note}</p>}
    </div>
  );
};
export default TextArea;

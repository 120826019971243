import React, { Fragment, useRef, useState, useEffect } from 'react';
import classnames from 'classnames';

import NoDataMessage from './NoDataMessage';
import { formatMinutesToDays } from '../../utils';

import css from './StackedCount.css';

// From https://stackoverflow.com/questions/9333379/check-if-an-elements-content-is-overflowing/34299947
function isOverflown(barElement, numberElement) {
  return numberElement && barElement ? numberElement.scrollWidth > barElement.clientWidth : true;
}

export const StackedCount = (props) => {
  const barRefs = useRef([]);
  const percentageRefs = useRef([]);
  const [current, setCurrent] = useState();
  const [overflows, setOverFlows] = useState([]);
  const {
    names,
    values,
    percentage,
    width,
    colors = ['#f99e1e', '#ea5754', '#165eaf'],
    time = false,
    showValue = true,
  } = props;
  const total = values.reduce((total, num) => total + num);

  useEffect(() => {
    const initialOverflows = values.map((_, i) => isOverflown(barRefs.current[i], percentageRefs.current[i]));
    setOverFlows(initialOverflows);
  }, []);

  const changeCurrent = (index) => {
    setCurrent(index);
  };

  return (
    <div className={css.root}>
      {total !== 0 ? (
        <Fragment>
          <div className={classnames(css.stackedCountWrapper, { [css.maxWidth]: width })}>
            <div className={css.stackedBarChart}>
              {values.map((value, index) => (
                <div
                  ref={(barRef) => {
                    if (barRefs.current.length < values.length) barRefs.current.push(barRef);
                  }}
                  key={`bar-${index}`}
                  className={css.stackedBar}
                  onMouseOver={() => changeCurrent(index)}
                  onMouseOut={() => changeCurrent(-1)}
                  style={{ width: `${100 * (value / total)}%`, backgroundColor: colors[index] }}>
                  {percentage && (
                    <div
                      ref={(pRef) => {
                        if (percentageRefs.current.length < values.length) percentageRefs.current.push(pRef);
                      }}
                      className={classnames(
                        css.percentage,
                        {
                          [css.hidden]: overflows[index],
                        },
                        { [css.hover]: index == current }
                      )}>
                      {isNaN(Math.round((value / total) * 100)) ? `` : `${Math.round((value / total) * 100)}%`}
                      {showValue && (
                        <span className={css.barQuantity}>
                          {time ? formatMinutesToDays(value) : `${value} órdenes`}
                        </span>
                      )}
                    </div>
                  )}
                  {value !== 0 && (
                    <div
                      className={classnames(css.nameContainer, {
                        [css.hover]: index == current,
                      })}>
                      <span className={css.countName} style={{ color: colors[index] }}>
                        {names[index]}
                        {isNaN(Math.round((value / total) * 100)) ? `` : ` (${Math.round((value / total) * 100)}%)`}
                      </span>
                      {showValue && (
                        <span className={css.quantity}>{time ? formatMinutesToDays(value) : `${value} órdenes`}</span>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={css.legend}>
            {values.map((value, index) => (
              <div
                className={classnames(css.item, { [css.hover]: index == current })}
                key={`legend-item-${index}`}
                onMouseOver={() => changeCurrent(index)}
                onMouseOut={() => changeCurrent(-1)}>
                <div key={`icon-${index}`} className={css.icon} style={{ backgroundColor: colors[index] }} />
                <div className={css.label}>
                  {names[index]}
                  {isNaN(Math.round((value / total) * 100)) ? `` : ` (${Math.round((value / total) * 100)}%)`}
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      ) : (
        <NoDataMessage />
      )}
    </div>
  );
};

export default StackedCount;

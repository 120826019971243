// https://zxcvbn-ts.github.io/zxcvbn/guide/lazy-loading/

let zxcvbnLib;
const loadZxcvbnLib = async () => {
  if (zxcvbnLib) return zxcvbnLib;
  const zxcvbnCorePackage = await import(/* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/core');
  const zxcvbnCommonPackage = await import(/* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common');
  const zxcvbnEnPackage = await import(/* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en');
  const zxcvbnEsEsPackage = await import(/* webpackChunkName: "zxcvbnEsEsPackage" */ '@zxcvbn-ts/language-es-es');

  const options = {
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
      ...zxcvbnEsEsPackage.dictionary,
    },
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    translations: zxcvbnEsEsPackage.translations,
  };
  zxcvbnCorePackage.zxcvbnOptions.setOptions(options);
  zxcvbnLib = zxcvbnCorePackage.zxcvbn;
  return zxcvbnLib;
};

export const makePasswordValidator = async () => {
  const zxcvbn = await loadZxcvbnLib();
  return function password(v) {
    const ev = zxcvbn(v);
    if (ev.score >= 3) {
      return null;
    }
    else {
      let msg = '';
      if (ev.feedback.warning)
        msg += `${ev.feedback.warning} `;

      if (ev.feedback.suggestions.length > 0)
        msg += ev.feedback.suggestions.join(' ');

      if (msg.length == 0)
        msg = 'Contraseña inválida';

      return msg;
    }
  };
};

import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from '../routes';
import api from '../utils/api';

const Logout = (props) => {
  const { user, actions } = api.auth();
  useEffect(() => {
    actions.logout();
  }, []);
  return user.id ? <p>Cerrando sesión...</p> : <Redirect to={routes('root')} />;
};

export default Logout;

const debug = require('debug')('mtk:react:report');

import React, { Fragment, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { routes } from '../routes';
import { roles } from '../constants';
import { can } from '../can';
import { formatReportRange, formatDateTime } from '../utils';
import { reportFrequencies, reportFrequenciesLabels } from '../constants';

import api from '../utils/api';
import { useForm } from '../utils/form';
import { useQuery } from '../utils/query';

import { FiCheck } from 'react-icons/fi';

import PageHeader from '../components/common/PageHeader';
import Progress from '../components/common/Progress';
import { List, Header, Body, Row, Cell } from '../components/common/List';
import Pagination from '../components/common/Pagination';
import Modal from '../components/common/Modal';

import Button from '../components/common/Button';
import Input from '../components/common/Input';
import Select, { objectToOptions, indexToOptions } from '../components/common/Select';

import { Loading } from './PageStatus';

import css from './Reports.css';

const momentToISO = (d) => d.toISOString(true).substring(0, 16) + 'Z';

const createReportPresets = () => {
  const presets = [
    {
      label: 'thisWeek',
      name: 'thisWeek',
      label: `Esta semana (hasta ayer)`,
      isDisabled: moment().weekday() === 0,
      timeFrom: moment().startOf('isoWeek'),
      timeTo: moment().startOf('day'),
    },
    {
      name: 'lastWeek',
      label: `Semana pasada`,
      timeFrom: moment().subtract(1, 'weeks').startOf('isoWeek'),
      timeTo: moment().subtract(1, 'weeks').endOf('isoWeek'),
    },
    {
      name: 'thisMonth',
      label: `Este mes (${moment().startOf('month').format('DD MMMM')} - ayer)`,
      isDisabled: moment().date() === 1,
      timeFrom: moment().startOf('month'),
      timeTo: moment().startOf('day'),
    },
    {
      name: 'lastMonth',
      label: `Mes pasado (${moment().subtract(1, 'months').format('MMMM')})`,
      timeFrom: moment().subtract(1, 'months').startOf('month'),
      timeTo: moment().subtract(1, 'months').endOf('month'),
    },
    {
      name: 'twoMonthsAgo',
      label: moment().subtract(2, 'months').format('MMMM'),
      timeFrom: moment().subtract(2, 'months').startOf('month'),
      timeTo: moment().subtract(2, 'months').endOf('month'),
    },
    {
      name: 'threeMonthsAgo',
      label: moment().subtract(3, 'months').format('MMMM'),
      timeFrom: moment().subtract(3, 'months').startOf('month'),
      timeTo: moment().subtract(3, 'months').endOf('month'),
    },
  ];
  // quarters

  const now = moment(); //.subtract(3, 'months');

  const thisQuarterFrom = now.startOf('month').subtract(3, 'months');
  thisQuarterFrom.add(3 - (thisQuarterFrom.month() % 3), 'months');
  presets.push({
    name: 'thisQuarter',
    label: `Este trimestre (${thisQuarterFrom.format('MMMM')} - ayer)`,
    timeFrom: thisQuarterFrom,
    timeTo: now.startOf('day'),
  });
  const lastQuarterFrom = thisQuarterFrom.clone().subtract(3, 'month');
  const lastQuarterTo = thisQuarterFrom.clone().subtract(1, 'day').endOf('day');
  presets.push({
    name: 'lastQuarter',
    label: `Trimestre pasado (${lastQuarterFrom.format('MMMM')} - ${lastQuarterTo.format('MMMM YYYY')})`,
    timeFrom: lastQuarterFrom,
    timeTo: lastQuarterTo,
  });

  const thisSemesterFrom = now.startOf('month').subtract(6, 'months');
  thisSemesterFrom.add(6 - (thisQuarterFrom.month() % 6), 'months');
  presets.push({
    name: 'thisSemester',
    label: `Este semestre (${thisSemesterFrom.format('MMMM')} - ayer)`,
    timeFrom: thisSemesterFrom,
    timeTo: now.startOf('day'),
  });
  const lastSemesterFrom = thisSemesterFrom.clone().subtract(6, 'month');
  const lastSemesterTo = thisSemesterFrom.clone().subtract(1, 'day').endOf('day');
  presets.push({
    name: 'lastSemester',
    label: `Semestre pasado (${lastSemesterFrom.format('MMMM')} - ${lastSemesterTo.format('MMMM YYYY')})`,
    timeFrom: lastSemesterFrom,
    timeTo: lastSemesterTo,
  });

  presets.push({
    name: 'custom',
    label: `Rango personalizado`,
  });

  return presets;
};

const presetsToOptions = (presets) =>
  presets.map((p) => ({
    value: p.name,
    label: p.label,
    isDisabled: p.isDisabled,
  }));

const timezones = {
  'America/Lima': {
    utc: 'UTC-5',
    offset: '-05:00',
    value: 'America/Lima',
    label: 'Lima (UTC-5)',
  },
  'America/Santiago': {
    utc: 'UTC-3',
    offset: '-03:00',
    value: 'America/Santiago',
    label: 'Santiago (UTC-3)',
  },
};

const reportIndexFields = {
  type: ['text', false, { initialValue: 'orders' }],
  frequency: ['text', true, { initialValue: 'day' }],
  preset: ['text', true],
  timeFrom: [
    'date',
    false,
    {
      required: (v, values) => values.get('preset') == 'custom',
      validate: (v, values) => {
        if (moment().isBefore(v)) return 'No puede estar en el futuro';
        if (!values.get('timeTo')) return null;
        const diff = moment(values.get('timeTo')).diff(v, 'days');
        if (diff < 0) return 'Debe ser anterior al término';
        if (diff > 184) return 'El rango máximo permitido es 6 meses';
        return null;
      },
    },
  ],
  timeTo: [
    'date',
    false,
    {
      required: (v, values) => values.get('preset') == 'custom',
      validate: (v, values) => {
        if (moment().isBefore(v)) return 'Fecha no puede estar en el futuro';
        if (!values.get('timeFrom')) return null;
        const diff = moment(v).diff(values.get('timeFrom'), 'days');
        if (diff < 0) return 'Debe ser posterior al inicio';
        if (diff > 184) return 'El rango máximo permitido es 6 meses';
        return null;
      },
    },
  ],
  timezone: ['text', true, { initialValue: moment.tz.guess() }],
  clientId: ['number'],
};

const Reports = ({ location, history }) => {
  const { user } = api.auth();
  const query = useQuery(location, history);
  const { index, actions } = api.reports.index(query.obj);
  const { actions: reportActions } = api.reports.newItem({ history });
  const { actions: clientsActions } = api.clients.index(null, { lazy: true });
  const [modal, setModal] = useState();
  const form = useForm(reportIndexFields);
  const reportPresets = useMemo(createReportPresets, []);

  const getClients = async (search) => indexToOptions(await clientsActions.get({ search, perPage: 50 }));

  // This function will turn the report presets into a timeFrom, timeTo and frequency
  // object. Note that all of this will be in the browsers timezone, and then converted
  // to UTC. E.g., "thisWeek" will be the week of the browsers timezone, not UTC.
  const createReport = async () => {
    const values = form.getValues().toJS();
    const preset = { ...reportPresets.find((p) => p.name == values.preset) };

    if (preset.name == 'custom') {
      preset.timeFrom = moment(values.timeFrom).startOf('day');
      preset.timeTo = moment(values.timeTo).endOf('day');
    }

    const data = {
      frequency: reportFrequencies('day'),
      timeFrom: momentToISO(preset.timeFrom).replace('Z', timezones[values.timezone].offset),
      timeTo: momentToISO(preset.timeTo).replace('Z', timezones[values.timezone].offset),
      clientId: values.clientId || user.clientId,
    };

    if (form.validate()) {
      reportActions.create(data);
    }
  };
  const isAdmin = user.role === roles('admin');

  const openModal = () => {
    setModal(true);
  };

  return (
    <main>
      <PageHeader title="Reportes" {...index}>
        {can(user, 'create', 'reports') && (
          <Button className={css.button} onClick={openModal}>
            Nuevo Reporte
          </Button>
        )}
      </PageHeader>
      <Modal open={modal} onClose={() => setModal(false)}>
        {can(user, 'create', 'reports') && (
          <div>
            <h3>Nuevo Reporte</h3>
            {/* <Select
							id="select-orders"
							className={css.select}
							isClearable={false}
							name="type"
							form={form}
							label="Tipo"
							options={objectToOptions({ orders: 'Órdenes' })}
						/> */}
            <Select
              className={css.select}
              isClearable={false}
              name="preset"
              form={form}
              label="Período"
              options={presetsToOptions(reportPresets)}
            />
            {form.getValue('preset') == 'custom' && (
              <Fragment>
                <Input label="Fecha Incio" name={'timeFrom'} form={form} />
                <Input label="Fecha Término" name={'timeTo'} form={form} />
              </Fragment>
            )}
            <Select
              className={css.select}
              isClearable={false}
              name="timezone"
              form={form}
              label="Zona Horaria"
              options={Object.values(timezones)}
            />
            {/*
							<Select
								className={css.select}
								isClearable={false}
								name="frequency"
								form={form}
								label="Frecuencia"
								options={objectToOptions({ day: 'diaria' })}
							/> */}

            {isAdmin && (
              <Select className={css.select} label="Cliente" name="clientId" form={form} loadOptions={getClients} />
            )}
            <Button
              className={css.button}
              onClick={createReport}
              disabled={!form.getValue('preset') || (isAdmin && !form.getValue('clientId'))}>
              Crear
            </Button>
          </div>
        )}
      </Modal>
      {index.loaded ? (
        <List>
          <Header>
            <Cell head>ID</Cell>
            <Cell head>Tipo</Cell>
            <Cell head>Período</Cell>
            {false && <Cell head>Frecuencia</Cell>}
            <Cell head>Generado</Cell>
            {isAdmin && <Cell head>Cliente</Cell>}
            <Cell head></Cell>
          </Header>
          <Body>
            {index.data.toJS().map((report, i) => {
              const to = routes('report', { id: report.id });
              return (
                <Row key={`report-row-${report.id}`}>
                  <Cell>
                    <Link to={to}>{report.id}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>Órdenes</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>{formatReportRange(report)}</Link>
                  </Cell>
                  {false && (
                    <Cell>
                      <Link to={to}>{reportFrequenciesLabels(report.frequency)}</Link>
                    </Cell>
                  )}
                  <Cell>
                    <Link to={to}>{formatDateTime(report.createdAt)}</Link>
                  </Cell>
                  {isAdmin && <Cell>{report.clientName || report.clientId}</Cell>}
                  <Cell>{report.status === 'ready' ? <FiCheck /> : <Progress />}</Cell>
                </Row>
              );
            })}
          </Body>
        </List>
      ) : (
        <Loading compact />
      )}
      {index.pageInfo && <Pagination {...index.pageInfo.toJS()} onChange={query.setPage} />}
    </main>
  );
};

export default Reports;

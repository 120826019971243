const debug = require('debug')('mtk:order');
import React, { Fragment, useMemo, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import Case from 'case';

import { FiX } from 'react-icons/fi';

import api from '../utils/api';
import { useForm } from '../utils/form';
import { groupBy } from 'lodash';
import { fromJS } from 'immutable';
import { orderTypes, roles } from '../constants';

import { timeout, sizeToString, codeInputRegex } from '../utils';

import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/Button';
import Toggle from '../components/common/Toggle';
import Input, { InputRow } from '../components/common/Input';
import Select, { indexToOptions } from '../components/common/Select';
import Radio from '../components/common/Radio';

import { routes } from '../routes';
import grid from '../styles/grid.css';
import css from './Order.css';

const DEFAULT_PICKUP_USER_ROLE = 'noAccess';

let newOrderFields = {
  clientName: ['text'],
  orderType: ['text'],
  clientId: ['number'],
  locationId: ['number'],
  courierId: ['number'],
  courierName: ['number'],
  pickupName: ['text', true],
  pickupEmail: ['email', true],
  pickupPhone: ['tel'],
  pickupNationalId: ['rut'],
  width: ['number', true],
  length: ['number', true],
  height: ['number', true],
  courierPickupCodeOn: ['toggle'],
  courierPickupCode: ['text'],
  dropCodeOn: ['toggle'],
  pickupCodeOn: ['toggle'],
  createUserWithPickupInfo: ['toggle'],
  dropCode: ['text'],
  pickupCode: ['text'],
  extras: ['json'],
};

const OrderNew = ({ match, history }) => {
  const { user } = api.auth();
  const { item, actions } = api.orders.newItem({ history });
  const { item: client, actions: clientActions } = api.clients.item(null, null, { lazy: true });
  const { actions: usersActions } = api.users.index(null, { lazy: true });
  const { actions: clientIndexActions } = api.clients.index(null, { lazy: true });
  const { index: lockersIndex, actions: lockersActions } = api.lockers.indexDoors(null, { lazy: true });

  useMemo(() => {
    if (user.clientId) clientActions.get(null, null, user.clientId);
  }, [user.clientId]);

  const [initialValues, setInitialValues] = useState({ orderType: 'delivery' });
  const [selectedUser, setSelectedUser] = useState({ id: null });

  // updates the extras.* fields whenever the clientId changes
  useMemo(() => {
    if (!client.data || !client.data.get('preferences')) return;
    const preferences = client.data.get('preferences').toJS();
    const extras = {};
    for (const key in preferences.extras) {
      extras[key] = [preferences.extras[key]];
    }

    if (preferences.customDropCodeRequired) newOrderFields = { ...newOrderFields, dropCode: ['text', true] };
    if (preferences.courierPickupCodeRequired)
      newOrderFields = { ...newOrderFields, courierPickupCode: ['text', true] };
    newOrderFields = { ...newOrderFields, extras };
    setInitialValues({ clientId: client.data.get('id'), orderType: 'delivery' });
  }, [client.data]);

  const form = useForm(newOrderFields, initialValues);
  const preferences = client.data ? client.data.get('preferences').toJS() : {};
  const isAdmin = user.role == roles('admin');
  const isReturn = form.getValue('orderType') == 'return';

  // actions

  const getClients = async (search) => indexToOptions(await clientIndexActions.get({ search, perPage: 50 }));

  // ACTION

  // this is a bit cumbersome but it does the trick.
  // we need to wait for the order to load, in order to correctly load the couriers
  // this contraption continues to run until there is a clientId to use for couriers.
  // the useRef is necessary to make sure the recursive function is using the current
  // version of the function (with the latest clientId)
  const getUsers = useRef();
  const usersList = useRef({});
  getUsers.current = async (search, role) => {
    const clientId = form.getValue('clientId');
    if (!clientId) {
      await timeout(200);
      return getUsers.current(search);
    }
    const users = await usersActions.get({ search, status: 'active', clientId, role, perPage: 50 });

    const usersToOptions = [];

    usersList.current = users.toJS().map((user) => {
      usersToOptions.push({
        id: user.id,
        email: user.email,
        phone: user.phone,
        name: user.name,
        nameAndEmail: ` ${user.name}.   (${user.email})`,
        nationalId: user.nationalId,
      });

      return {
        value: user.id,
        label: user.name,
        email: user.email,
        phone: user.phone,
        nationalId: user.nationalId,
      };
    });

    return indexToOptions(fromJS(usersToOptions), { label: `nameAndEmail` });
  };

  const getDoors = async () => {
    if (isAdmin) return;
    const lockers = await lockersActions.getAll({ perPage: 50 });
    const doorsArray = lockers.map((locker) => locker.toJS().doors);
    const doors = [].concat(...doorsArray);
    const sortedDoors = doors.sort((a, b) => {
      const a_ = a.height * a.width * a.length;
      const b_ = b.height * b.width * b.length;
      return a_ - b_;
    });
    const formattedDoors = groupBy(
      sortedDoors.map((curr) => {
        curr.size = sizeToString(curr);
        return curr;
      }),
      'size'
    );
    const options = Object.keys(formattedDoors).map((key) => {
      return {
        value: [formattedDoors[key][0].height, formattedDoors[key][0].width, formattedDoors[key][0].length],
        label: formattedDoors[key][0].size,
      };
    });
    return options;
  };

  const onDoorChange = (e, value) => {
    if (value) setInitialValues({ width: value[0], height: value[1], length: value[2] });
    else {
      setInitialValues({ width: '', height: '', length: '' });
    }
  };

  const onClientChange = (name, value) => {
    clientActions.get(null, null, value);
  };

  const onCourierChange = (name, value) => {
    form.setValue('courierId', value);
  };

  const onLoadPickupUserChange = async (name, value) => {
    setSelectedUser({ id: value });
    if (!value) {
      form.setValues({ pickupEmail: '', pickupPhone: '', pickupName: '', pickupNationalId: '' });
    } else {
      const user = usersList.current.find((c) => c.value == value);
      if (!user) {
        console.error('User not found', value, usersList);
        return;
      }
      form.setValues({
        pickupEmail: user.email,
        pickupPhone: user.phone,
        pickupName: user.label,
        pickupNationalId: user.nationalId,
      });
    }
  };

  const handleCreate = async () => {
    if (form.validate(null, true)) {
      actions.create(form.getValues());
    }
  };

  const createButton = (
    <Button onClick={handleCreate} loading={item.loading}>
      {item.loading ? 'Creando Orden' : 'Crear orden'}
    </Button>
  );

  return (
    <main className={css.root}>
      <PageHeader
        section="orders"
        title={`Nueva Orden`}
        subtitle={isAdmin && client.data ? <h3>{client.data.get('name')}</h3> : null}
        {...item}>
        {createButton}
      </PageHeader>

      <div className={grid.grid}>
        <section className={grid.half}>
          {user.role == 'admin' && (
            <Fragment>
              <h3>Cliente</h3>
              <Select
                label="Cliente"
                name="clientId"
                onChange={onClientChange}
                loadOptions={getClients}
                form={form}
                isClearable={false}
                noOptionsMessage={(a) =>
                  a.inputValue ? `No hay clientes que contengan "${a.inputValue}"` : 'No hay clientes.'
                }
              />
            </Fragment>
          )}
          <h3>Bulto</h3>
          <Select
            label={isAdmin ? 'Selección de tamaño no disponible para usuarios admin' : 'Seleccionar'}
            name="orderSize"
            loadOptions={getDoors}
            readOnly={isAdmin}
            onChange={onDoorChange}
            form={form}
          />
          <InputRow>
            <Input className={css.measurementsInput} name="width" form={form} label="Ancho" sufix=" cm" min="1" />
            <FiX className={css.measurementsIcon} />
            <Input className={css.measurementsInput} name="height" form={form} label="Alto" sufix=" cm" min="1" />
            <FiX className={css.measurementsIcon} />
            <Input className={css.measurementsInput} name="length" form={form} label="Largo" sufix=" cm" min="1" />
          </InputRow>

          <h3>{isReturn ? 'Remitente' : 'Destinatario'}</h3>
          {preferences.loadPickupUsers && (
            <Select
              name="loadPickupUsers"
              readOnly={isAdmin && !form.getValue('clientId')}
              onChange={onLoadPickupUserChange}
              loadOptions={(search) =>
                getUsers.current(search, preferences.loadPickupUsersRole || DEFAULT_PICKUP_USER_ROLE)
              }
              key={form.getValue('clientId')}
              cacheOptions={form.getValue('clientId')}
              placeholder="Seleccionar usuario"
              noOptionsMessage={(a) =>
                a.inputValue ? `No hay usuarios que contengan "${a.inputValue}"` : 'No hay usuarios.'
              }
              note={
                <>
                  {'Ingresa el nombre de un usuario y selecciona para cargar sus datos como destinatario'}
                  {selectedUser.id && (
                    <>
                      <br></br>
                      <Link to={routes('user', { id: selectedUser.id })}>Haz Click aquí</Link>
                      {' para editar los datos de este usuario'}
                    </>
                  )}
                </>
              }
            />
          )}

          <Input readOnly={selectedUser.id} name="pickupName" form={form} label="Nombre" />
          <Input readOnly={selectedUser.id} name="pickupEmail" form={form} label="Email" />
          <Input readOnly={selectedUser.id} name="pickupPhone" form={form} label="Teléfono" />
          {preferences.enableUsersNationalId && (
            <Input readOnly={selectedUser.id} name="pickupNationalId" form={form} label="RUT" />
          )}
          {preferences.enableCreateUserFromPickupInfo && (
            <Toggle
              readOnly={selectedUser.id}
              name="createUserWithPickupInfo"
              label="Guardar destinatario como usuario sin acceso"
              form={form}
            />
          )}
        </section>
        <section className={grid.half}>
          {preferences.returnsEnabled && (
            <>
              <h3>Tipo de orden</h3>
              <Radio
                name="orderType"
                style={{ maxWidth: 400, paddingTop: '1.55em' }}
                options={[
                  { value: 'delivery', label: orderTypes('delivery') },
                  { value: 'return', label: orderTypes('return') },
                ]}
                form={form}
              />
            </>
          )}
          <h3>Courier</h3>
          <Select
            name="courierId"
            form={form}
            readOnly={isAdmin && !form.getValue('clientId')}
            onChange={onCourierChange}
            loadOptions={(search) => getUsers.current(search, '!noAccess')}
            key={form.getValue('clientId')}
            cacheOptions={form.getValue('clientId')}
            label="Courier"
            placeholder="No asignado"
            note={isAdmin && !form.getValue('clientId') ? 'Debes elegir un cliente antes de asignar un courier' : null}
            noOptionsMessage={(a) =>
              a.inputValue ? `No hay couriers que contengan "${a.inputValue}"` : 'No hay couriers.'
            }
          />
          {(preferences.customDropCodeEnabled || preferences.courierPickupCodeEnabled) && <h3>Códigos</h3>}
          {!isReturn && preferences.customDropCodeEnabled && (
            <Fragment>
              {!preferences.customDropCodeRequired && (
                <Toggle name="dropCodeOn" label={'Personalizar código de entrega'} form={form} />
              )}
              {(form.getValue('dropCodeOn') || preferences.customDropCodeRequired) && (
                <Input
                  name="dropCode"
                  beforeChange={(v) => v.replace(codeInputRegex, '')}
                  form={form}
                  label="Código de entrega courier"
                  placeholder={preferences.customDropCodeRequired ? 'XXXXXX' : 'Auto generado'}
                  note="No se puede cambiar una vez creada la orden"
                />
              )}
            </Fragment>
          )}
          {!isReturn && preferences.customPickupCodeEnabled && (
            <Fragment>
              <Toggle name="pickupCodeOn" label={'Personalizar código de retiro'} form={form} />
              {form.getValue('pickupCodeOn') && (
                <Input
                  name="pickupCode"
                  beforeChange={(v) => v.replace(codeInputRegex, '')}
                  form={form}
                  label="Código de retiro cliente"
                  placeholder={'Auto generado'}
                  note="No se puede cambiar una vez creada la orden"
                />
              )}
            </Fragment>
          )}
          {preferences.courierPickupCodeEnabled && (
            <Fragment>
              <Input
                name="courierPickupCode"
                form={form}
                beforeChange={(v) => v.replace(codeInputRegex, '')}
                label={isReturn ? 'Código de retiro courier' : 'Código de retiro courier (para revocación)'}
                placeholder="Sin código"
              />
            </Fragment>
          )}

          {preferences.extras && (
            <Fragment>
              <h3>Extras</h3>
              {Object.keys(preferences.extras).map((field, index) => (
                <Input key={`extras-field-${index}`} name={['extras', field]} form={form} label={Case.capital(field)} />
              ))}
            </Fragment>
          )}
        </section>
        <section className={grid.full}>{createButton}</section>
      </div>
    </main>
  );
};

export default OrderNew;

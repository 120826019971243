import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { kebab } from 'case';

import { mask, unmask } from '../../utils/mask';

import css from './Input.css';

export const Input = (props) => {
  let {
    name,
    label = '',
    form,
    value,
    type,
    error,
    onChange,
    placeholder,
    sufix,
    note,
    className,
    skipSubmit,
    onEnter,
    clickToEdit,
    readOnly,
    beforeChange,
    ...otherProps
  } = props;
  const id = kebab(`input-${name}-${label}`);

  value = form ? form.getValue(name) : value;
  type = form ? form.getType(name) : type;
  error = form ? form.getError(name) : error;
  onChange = onChange ? onChange : form.setValue;
  // XXX THIS SHOULD BE A COLOR PICKER
  type = type == 'color' ? 'text' : type;

  const touched = form ? form.getTouched(name) : true;
  const maskFn = mask[type] ? mask[type].mask : false;
  const unmaskFn = mask[type] ? mask[type].unmask : false;

  const inputRef = useRef();

  // this should be a custom proptype function
  if (typeof value == 'undefined' || typeof onChange == 'undefined')
    console.error('form or (value && onChange) props must be provided!');

  const handleOnChange = (e) => {
    const value = typeof beforeChange === 'function' ? beforeChange(e.target.value) : e.target.value;
    onChange(name, unmask(value, type), skipSubmit);
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode == 13 && onEnter) {
      onEnter();
    }
  };

  const handleEdit = () => {
    inputRef.current.focus();
  };
  return (
    <div
      className={classnames(css.root, {
        [className]: className,
        [css.readOnly]: readOnly,
        [css.withError]: touched && error,
        [css.withSufix]: sufix,
        [css.clickToEdit]: clickToEdit,
      })}>
      {label ? (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      ) : null}
      <div className={css.inputWrapper}>
        <input
          id={id}
          ref={inputRef}
          className={css.input}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          value={mask(value, type)}
          autoComplete="on"
          readOnly={readOnly}
          {...{ type, label, placeholder, ...otherProps }}
        />
        {clickToEdit && (
          <a className={css.edit} onClick={handleEdit}>
            editar
          </a>
        )}
        {sufix && <span className={css.sufix}>{sufix}</span>}
      </div>
      {touched && error && <p className={css.error}>{error}</p>}
      {note && <p className={css.note}>{note}</p>}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  type: PropTypes.oneOf(['text', 'number', 'tel', 'email', 'password', 'hidden', 'date']),
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,

  // either
  form: PropTypes.object,
  // or
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func,

  large: PropTypes.bool,
};

export const InputRow = ({ className, children }) => {
  return <div className={classnames(css.row, { [className]: className })}>{children}</div>;
};

export default Input;

import React, { useState } from 'react';
import { useForm } from '../utils/form';
import api from '../utils/api';
import { parseQuery } from '../utils';

import Input from '../components/common/Input';
import Button from '../components/common/Button';
import Otp from '../components/Otp';
import css from './Login.css';

const loginFields = {
  email: ['email', true],
  password: [
    'password',
    true,
    {
      validate: (v) => (v && v.length > 0 ? null : 'Valor requerido'),
    },
  ],
};

export const Login = (props) => {
  const [authCode, setAuthCode] = useState(null);
  const [showOtp, setShowOtp] = useState(false);

  const query = parseQuery(props.location.search);
  const loginForm = useForm(loginFields);
  const { actions } = api.auth();

  const handleLogin = async () => {
    if (loginForm.validate()) {
      const user = await actions.login(loginForm.getValues());
      if (!user) {
        loginForm.setErrors({ email: 'Invalid email or password', password: 'Invalid email or password' });
      } else if (user.authCode) {
        setAuthCode(user.authCode);
        setShowOtp(true);
      } else if (user.hasAuthCode) {
        setAuthCode(null);
        setShowOtp(true);
      } else if (user && user.id) window.location.href = query.redirect || '/';
    }
  };

  return (
    <main>
      {showOtp ? (
        <Otp code={authCode} email={loginForm.getValue('email')} />
      ) : (
        <div className={css.form}>
          <p>Por favor ingresa con tu email y password para continuar.</p>
          <Input name={'email'} label="Email" placeholder="hola@maletek.cl" onEnter={handleLogin} form={loginForm} />
          <Input name={'password'} label="Password" placeholder="••••••••••" onEnter={handleLogin} form={loginForm} />
          <Button onClick={handleLogin}>Ingresar</Button>
        </div>
      )}
    </main>
  );
};

export default Login;

import React from 'react';
import classnames from 'classnames';
import { FiX } from 'react-icons/fi';
import { isString } from 'lodash';
import { actionMessages } from '../../constants';

import grid from '../../styles/grid.css';
import css from './ErrorBanner.css';

export const ErrorBanner = (props) => {
  const { label, error, onClose = () => {} } = props;
  if (!error) return null;
  const err = isString(error) ? error : error.data ? error.data.message : false;
  return (
    <div className={classnames(css.root, grid.full)}>
      <a className={css.close} onClick={onClose}>
        <FiX className={css.icon} />
      </a>

      <p className={css.subtitle}>
        {label && <strong className={css.title}>{`Error ${label}: `}</strong>}
        {err && err}
      </p>
    </div>
  );
};

export default ErrorBanner;

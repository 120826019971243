import { useMemo } from 'react';

import {
  useItemState,
  indexRoute,
  newItemRoute,
  getItemAction,
  updateItemAction,
  deleteItemAction,
  handleApiStart,
  handleApiSuccess,
  handleApiError,
  axios,
} from './utils';

const allowedParams = ['name', 'preferences'];
const requiredParams = ['name'];

const clients = {
  index: indexRoute('/clients/'),
  newItem: newItemRoute('/clients/', { allowedParams }),
  item: (id, params, { history, lazy } = {}) => {
    const url = `/clients/${id}`;
    const [item, setItem] = useItemState(url, params);
    const get = getItemAction(url, { params, setItem });
    const update = updateItemAction(url, {
      allowedParams,
      requiredParams,
      item,
      setItem,
      onSuccess: () => get(null, 'update'),
    });
    const del = deleteItemAction(url, { setItem, history });

    const segmentAssign = async (segmentId, clientId) => {
      try {
        handleApiStart(setItem, 'assign', 'segment');
        const res = await axios.post(`/clients/${clientId || id}/assign/segment/${segmentId}`);
        handleApiSuccess(res, setItem);
        get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const segmentUnassign = async (segmentId) => {
      try {
        handleApiStart(setItem, 'unassign', 'segment');
        const res = await axios.delete(`/clients/${id}/assign/segment/${segmentId}`);
        handleApiSuccess(res, setItem);
        get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    useMemo(() => {
      if (!lazy) get();
    }, []);

    return { item, actions: { get, update, delete: del, segmentAssign, segmentUnassign } };
  },
};

export default clients;

import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import GoogleMap from 'google-map-react';
import pickBy from 'lodash/fp/pickBy';
import { FiServer, FiFlag } from 'react-icons/fi';

import classnames from 'classnames';
import css from './Map.css';
import { getDefaultLocation } from '../../utils';
import inputCss from './Input.css';
export const Map = ({
  onChange = () => {},
  lat,
  lon,
  zoom = 16,
  disabled,
  crosshair,
  className,
  compact,
  readOnly,
  children,
}) => {
  const mapOptions = {
    panControl: false,
    mapTypeControl: false,
    scrolatlngwheel: false,
    scaleControl: true,
    fulatlngscreenControl: false,
    maxZoom: 25,
    styles: [
      {
        featureType: 'all',
        stylers: [{ saturation: -75 }, { gamma: 1.1 }, { lightness: 15 }, { visibility: 'on' }],
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'simplified' }],
      },
    ],
  };

  const defaultLocation = getDefaultLocation();
  const [pos, setPos] = useState([lat ?? defaultLocation.lat, lon ?? defaultLocation.lon]);

  const onSearchChange = (loc) => {
    onChange(loc);
    setPos([loc.lat, loc.lon]);
  };

  const onMapChange = ({ center }) => {
    const loc = { lat: center.lat, lon: center.lng };
    onChange(loc);
  };

  return (
    <div
      className={classnames(css.root, {
        [className]: className,
        [css.compact]: compact,
      })}>
      <SearchBox onPlacesChanged={onSearchChange} readOnly={readOnly} />
      <div className={css.map}>
        {crosshair && <div className={css.crosshair} />}
        <GoogleMap
          placesLibrary={true}
          bootstrapURLKeys={{
            key: process.env.GOOGLE_MAPS_KEY,
            language: 'es',
            region: 'CL',
          }}
          onChange={onMapChange}
          options={mapOptions}
          center={pos}
          initialZoom={zoom}
          zoom={zoom}>
          {children}
        </GoogleMap>
      </div>
    </div>
  );
};

Map.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  lat: PropTypes.number,
  lon: PropTypes.number,
  zoom: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default Map;

export const Marker = (props) => {
  return <div className={css.marker} />;
};

Marker.propTypes = {
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
};

export const LockerMarker = (props) => {
  const { lat, lng, $hover, hover, current, className, ...rest } = props;
  const otherProps = pickBy((v, k) => k[0] !== '$', rest);
  return (
    <div
      className={classnames(css.locker, className, {
        [css.hover]: hover || $hover || current,
        [css.current]: current,
      })}
      {...otherProps}>
      <FiServer className={css.lockerIcon} />
      <FiServer className={css.lockerIcon} />
    </div>
  );
};

LockerMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export const LocationMarker = (props) => {
  const { lat, lng, mini, $hover, hover, current, className, ...rest } = props;

  const otherProps = pickBy((v, k) => k[0] !== '$', rest);
  return (
    <div
      className={classnames(css.location, className, { [css.hover]: hover || $hover, [css.mini]: mini })}
      {...otherProps}>
      <FiFlag className={css.icon} size={mini ? 20 : 40} />
    </div>
  );
};

LocationMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export const SearchBox = (props) => {
  const inputRef = useRef();
  const maps = window.google.maps;

  useEffect(() => {
    const searchBox = new maps.places.SearchBox(inputRef.current);
    searchBox.addListener('places_changed', () => onPlacesChanged(searchBox));
    return () => maps.event.clearInstanceListeners(searchBox);
  }, []);

  const onPlacesChanged = (searchBox) => {
    const { location } = searchBox.getPlaces()[0].geometry;

    if (props.onPlacesChanged) {
      props.onPlacesChanged({ lat: location.lat(), lon: location.lng() });
    }
  };

  return (
    <div className={inputCss.root}>
      <input
        readOnly={props.readOnly}
        placeholder="Buscar en el mapa por lugar o dirección"
        className={classnames(inputCss.input, css.searchBox)}
        ref={inputRef}
        type="text"
      />
    </div>
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  onPlacesChanged: PropTypes.func,
};

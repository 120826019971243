import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { fromJS } from 'immutable';

import BarChart from './graphs/BarChart';
import LineChart from './graphs/LineChart';

import { List, Header, Body, Row, Cell } from './common/List';
import { monochromaticSchemeFromColor } from '../utils';

import grid from '../styles/grid.css';

const status = {
  created: 'creación',
  reserved: 'reserva',
  ready: 'en puerta',
  completed: 'retiro',
};

const colorsArray = monochromaticSchemeFromColor('#ea5754', 3, 20, 60).concat(
  monochromaticSchemeFromColor('#c6d8d4', 3, 30, 80)
);

const isEmpty = (arr) => {
  return arr.every((e) => e.intervals.length === 0);
};

const getQuantile = (q, array) => {
  let quartile;
  array.some((e) => {
    quartile = e;
    return e.perAcc >= q;
  });
  return quartile;
};

const getMean = (array, attribute) => {
  let initial = 0;
  const total = array[0] ? array[0].total : 0;
  return array.reduce((sum, current) => sum + current[attribute], initial) / total;
};

const formatDays = (h) => {
  const d = (h / 24).toLocaleString(undefined, { maximumFractionDigits: 0 });
  const rest = h % 24;
  return d == 0 ? `${rest} horas` : rest == 0 ? `${d} días` : `${d} días y ${rest} horas`;
};

export default (props) => {
  const { data, graphSize } = props;
  const [keys, setKeys] = useState([]);
  const [colors, setColors] = useState();
  const [list, setList] = useState();
  const [groupList, setGroupList] = useState();
  const [frequencies, setFrequencies] = useState();
  const [timeGroups, setTimeGroups] = useState();

  const calculateAccumFreq = (data, keys) => {
    let freq = {};
    keys.forEach((key) => (freq[key] = []));
    data.forEach((d) => {
      keys.forEach((key) => {
        if (d[key]) {
          const [_, from, to] = d.interval.match(/(\d+),(\d+)/);
          const midPoint = (parseInt(from) + parseInt(to)) / 2;
          freq[key].push({
            hour: d.hour,
            interval: d.interval,
            freq: d[key],
            midPoint: midPoint,
            pond: midPoint * d[key],
          });
        }
      });
    });
    Object.keys(freq).forEach((key) => {
      let acc = 0;
      freq[key] = freq[key].map((e) => {
        e.acc = e.freq + acc;
        acc = e.acc;
        return e;
      });
    });
    Object.keys(freq).forEach((key) => {
      let initial = 0;
      let total = freq[key].reduce((sum, current) => sum + current.freq, initial);
      freq[key] = freq[key].map((e) => {
        e.per = e.freq / total;
        e.perAcc = e.acc / total;
        e.total = total;
        return e;
      });
    });
    return freq;
  };

  const groupByHours = (arr, hours = [24, 48, 72]) => {
    const size = hours.length;
    const groups = [];
    hours.forEach((d, i) => {
      if (i === 0) {
        groups.push({ label: `en menos de ${d}`, from: 0, to: d, value: 0 });
      } else if (i < size - 1) {
        groups.push({
          label: `entre ${hours[i - 1]} y ${hours[i]}`,
          from: hours[i - 1],
          to: hours[i],
          value: 0,
        });
      } else {
        groups.push({
          label: `entre ${hours[i - 1]} y ${hours[i]}`,
          from: hours[i - 1],
          to: hours[i],
          value: 0,
        });
        groups.push({ label: `más de ${d}`, from: hours[i], to: Infinity, value: 0 });
      }
    });
    arr.forEach((d, i) => {
      const [_, from, to] = d.interval.match(/(\d+),(\d+)/);
      groups.forEach((group) => {
        if (from >= group.from && to <= group.to) {
          group.value = group.value + d.value;
        }
      });
    });
    return groups;
  };

  const parsedData = useMemo(() => {
    let concatenatedData = [];
    let newKeys = [];
    data.forEach((d) => {
      const id =
        d.from === 'created' && d.to === 'completed' ? `Espera total` : `Entre ${status[d.from]} y ${status[d.to]}`;
      newKeys.push(id);
      let accumCount = 0;
      d.intervals.forEach((datum, index) => {
        let e = {};
        if (datum.hour >= 120) {
          accumCount = accumCount + datum.count;
          if (index + 1 === d.intervals.length) {
            e.hour = '≥ ' + parseInt(120) + ' hrs';
            e.interval = '[' + 120 + ',' + (parseInt(datum.hour) + 6) + ')';
            e.from = datum.hour;
            e[id] = accumCount;
            concatenatedData.push(e);
          }
        } else {
          e.hour = '< ' + parseInt(datum.hour + 6) + ' hrs';
          e.interval = '[' + datum.hour + ',' + (parseInt(datum.hour) + 6) + ')';
          e.from = datum.hour;
          e[id] = datum.count;
          concatenatedData.push(e);
        }
      });
    });
    newKeys = newKeys.reverse();
    setKeys(newKeys);
    let groupedData = fromJS(concatenatedData)
      .groupBy((e) => e.get('hour'))
      .toJS();
    const keys = Object.keys(groupedData);
    let newData = [];
    keys.map((key) => {
      let datum = {};
      datum.hour = key;
      groupedData[key].map((e) => {
        Object.keys(e).map((subkey) => {
          if (subkey !== 'hour') datum[subkey] = e[subkey];
        });
      });
      newData.push(datum);
    });
    let obj = {};
    newKeys.forEach((key, i) => (obj[`${key}`] = colorsArray[i]));
    setColors(obj);
    if (!isEmpty(data)) {
      const frequencies = calculateAccumFreq(newData, newKeys);
      setFrequencies(frequencies);
      const pickedOrders = newData
        .filter((d) => d['Entre en puerta y retiro'])
        .map((d) => {
          return { hour: d.hour, value: d['Entre en puerta y retiro'], from: d.from, interval: d.interval };
        });
      const groupedOrders = groupByHours(pickedOrders, [24, 48]);
      setTimeGroups(groupedOrders);
    }
    newData = newData.sort((a, b) => {
      a = parseInt(a.from);
      b = parseInt(b.from);
      return a - b;
    });
    return newData;
  }, [data]);

  useEffect(() => {
    if (!frequencies) return;
    const values = [
      { label: '80%', f: 0.8 },
      { label: '90%', f: 0.9 },
      { label: '97%', f: 0.97 },
    ];
    const rows = [];
    const header = (
      <Header>
        <Cell head key={`quantile-headerCell`}>
          {`Intervalo`}
        </Cell>
        <Cell head key={`quantile-headerCell-mean`}>
          {`Tiempo Promedio`}
        </Cell>
        {values.map((value) => (
          <Cell head key={`quantile-headerCell-${value.f}`}>
            {`${value.label}`}
          </Cell>
        ))}
      </Header>
    );
    Object.keys(frequencies).forEach((key) => {
      const quartiles = [];
      values.forEach((value) => {
        const q = getQuantile(value.f, frequencies[key]);
        if (q) quartiles.push(q);
      });

      rows.push(
        <Row key={`quantile-row-${key}`}>
          <Cell key={`quantile-cell-${key}`}>{key}</Cell>
          <Cell key={`quantile-cell-${key}-mean`}>{`${formatDays(
            getMean(frequencies[key], 'pond').toLocaleString(undefined, { maximumFractionDigits: 0 })
          )}`}</Cell>
          {quartiles.map((q, i) => {
            const [_, from, to] = q.interval.match(/(\d+),(\d+)/);
            return (
              <Cell key={`quantile-cell-${key}-${i}`}>
                <span>{`Menos de ${to} horas`}</span>
              </Cell>
            );
          })}
        </Row>
      );
    });
    setList(
      <List>
        {header}
        <Body>{rows.map((row) => row)}</Body>
      </List>
    );
  }, [frequencies]);

  useEffect(() => {
    if (!timeGroups) return;
    const rows = [];
    const header = (
      <Header>
        {timeGroups.map((group) => (
          <Cell head key={`timegroup-headerCell-${group.label}`}>
            {`${group.label.charAt(0).toUpperCase() + group.label.slice(1)} horas`}
          </Cell>
        ))}
      </Header>
    );
    let initial = 0;
    const total = timeGroups.reduce((acc, current) => acc + current.value, initial);
    const row = (
      <Row key={`timegroup-row`}>
        {timeGroups.map((group) => (
          <Cell key={`timegroup-rowCell-${group.label}`}>
            <span>{`${Math.round((group.value / total + Number.EPSILON) * 1000) / 10}%`}</span>
          </Cell>
        ))}
      </Row>
    );
    setGroupList(
      <List>
        {header}
        <Body>{row}</Body>
      </List>
    );
  }, [timeGroups]);

  const CustomTooltip = (bar) => {
    const cases = bar.value === 1 ? `${bar.value} caso` : `${bar.value} casos`;
    const intervals = bar.indexValue.match(/\d+/);
    return (
      <div
        style={{
          background: 'white',
          maxWidth: '140px',
          lineHeight: 1,
        }}>
        <strong style={{ fontSize: 11 }}>
          {bar.indexValue !== '≥ 120 hrs' ? `Menos de ${intervals[0]} horas` : `Más de 120 horas`}
        </strong>
        <div
          key={bar.id}
          style={{
            color: bar.color,
            padding: '3px 0',
            fontSize: 14,
            lineHeight: 1,
          }}>
          <strong>{bar.id}</strong> <br />
          {cases}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <BarChart
        data={parsedData}
        legend
        groupMode="grouped"
        {...graphSize}
        colors={colors}
        indexBy={'hour'}
        keys={keys}
        customTooltip={CustomTooltip}
        defaultActive={['Espera total']}
        title={`Distribución por tiempos de espera entre estados`}
        subtitle={`Considera sólo órdenes cuyo ciclo de vida completo está dentro del periodo consultado`}
        axisTitles={{ left: `Nº de órdenes`, bottom: `Intervalos de cantidad de horas` }}
        style={{
          margin: { top: 80, right: 220, bottom: 60, left: 40 },
          colors: colors,
          padding: 0.2,
          innerPadding: 1,
        }}
        tickRotation
        customTick
      />
      <div className={grid.full} />
      {frequencies && list}
      <div className={grid.full} />
      {timeGroups && (
        <Fragment>
          <h3 style={{ pageBreakAfter: 'avoid', pageBreakInside: 'avoid' }}>Porcentaje de órdenes recogidas</h3>
          <h4
            style={{
              lineHeight: 1,
              marginBottom: '20px',
              fontWeight: 'normal',
              pageBreakInside: 'avoid',
              pageBreakAfter: 'avoid',
            }}>
            (Entre <em>en puerta</em> y <em>retiro</em>)
          </h4>
          <div style={{ maxWidth: '50%' }}>{groupList}</div>
        </Fragment>
      )}
    </Fragment>
  );
};

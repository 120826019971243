import { fromJS } from 'immutable';
import { useMemo } from 'react';
import { routes } from '../../routes';

const STATUS_CHECK_MAX_RETRIES = 7;
const STATUS_CHECK_INTERVAL = 1000;

import {
  useItemState,
  indexRoute,
  newItemRoute,
  getItemAction,
  updateItemAction,
  deleteItemAction,
  handleApiStart,
  handleApiSuccess,
  handleApiError,
  axios,
  prepareData,
  actionLabel,
  sleep,
} from './utils';

export const createOrderParams = [
  'locationId',
  'clientId',
  'orderType',
  'courierId',
  'status',
  'width',
  'height',
  'length',
  'dropCode',
  'pickupCode',
  'courierPickupCode',
  'pickupName',
  'pickupEmail',
  'pickupPhone',
  'pickupNationalId',
  'extras',
  'createUserWithPickupInfo',
];

export const unreservedOrderUpdateParams = [
  'locationId',
  'courierId',
  'pickupName',
  'pickupEmail',
  'pickupPhone',
  'pickupNationalId',
  'courierPickupCode',
  'error',
  'width',
  'height',
  'length',
  'extras',
  'preferences',
];

export const reservedOrderUpdateParams = [
  'locationId',
  'courierId',
  'pickupName',
  'pickupEmail',
  'pickupPhone',
  'pickupNationalId',
  'courierPickupCode',
  'error',
  'extras',
  'preferences',
];

const orders = {
  index: indexRoute('/orders/', { relations: 'courier,client,locker,location' }),
  newItem: newItemRoute('/orders/', {
    allowedParams: createOrderParams,
  }),
  item: (id, params, { history, lazy } = {}) => {
    const url = `/orders/${id}`;
    const [item, setItem] = useItemState(url, params, { clientPreferences: fromJS({}) });
    const get = getItemAction(url, {
      params,
      setItem,
      onSuccess: (res) => {
        clientPreferences(res.data.data.clientId);
        return fromJS(res.data.data);
      },
    });

    const update = updateItemAction(url, {
      allowedParams: () => (item.data.get('lockerId') ? reservedOrderUpdateParams : unreservedOrderUpdateParams),
      item,
      setItem,
      onSuccess: async (res) => await confirm(res, 'update', 'order'),
    });

    const del = deleteItemAction(url, { setItem, history });

    const clientPreferences = async (clientId) => {
      try {
        handleApiStart(setItem, 'load', 'preferences');
        const res = await axios.get(`/clients/${clientId}`);
        handleApiSuccess(res, setItem, { clientPreferences: fromJS(res.data.data.preferences || {}) });
        return fromJS(res.data.data);
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const orderHistory = async () => {
      try {
        handleApiStart(setItem, 'load', 'history');
        const res = await axios.get(`/orders/${id}/history`);
        handleApiSuccess(res, setItem, { history: fromJS(res.data.data) });
        return fromJS(res.data);
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const lockersFindAvailable = async ({ clientId, lat, lon, width, height, length }) => {
      const params = {
        location: `${lat},${lon}`,
        size: `${width}x${height}x${length}`,
      };
      if (clientId) params.clientId = clientId;
      try {
        handleApiStart(setItem, 'findAvaliable', 'locker');
        const res = await axios.get(`/lockers/available`, { params });
        handleApiSuccess(res, setItem, { lockersAvailable: fromJS(res.data.data) });
        return fromJS(res.data.data);
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };
    const lockerReserve = async (lockerId) => {
      try {
        handleApiStart(setItem, 'reserve', 'locker');
        const res = await axios.post(`/orders/${id}/reserve/${lockerId}`);
        return await confirm(res, 'reserve', 'locker');
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const lockerUnreserve = async (lockerId) => {
      try {
        handleApiStart(setItem, 'unreserve', 'locker');
        const res = await axios.delete(`/orders/${id}/reserve/${lockerId}`);
        return await confirm(res, 'unreserve', 'locker');
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const reversal = async () => {
      try {
        handleApiStart(setItem, 'reversal', 'order');
        const res = await axios.post(`/orders/${id}/reversal`);
        return await confirm(res, 'reversal', 'order');
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const cancel = async () => {
      try {
        handleApiStart(setItem, 'cancel', 'order');
        const res = await axios.post(`/orders/${id}/cancel`);
        return await confirm(res, 'cancel', 'order');
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const returnFromOrder = async (data) => {
      try {
        handleApiStart(setItem, 'create', 'order');
        const res = await axios.post(`/orders/`, {
          order: {
            fromOrderId: id,
            clientId: item.data.get('clientId'),
            orderType: 'return',
          },
        });
        handleApiSuccess(res, setItem, { data: fromJS(res.data.data), loading: true });

        if (history && history.push) {
          await sleep(1000);
          history.push(routes('order', { id: res.data.data.id }));
        } else {
          return fromJS(res.data.data);
        }
      } catch (err) {
        handleApiError(err, setItem);
      }
    };

    const sendNotification = async (notificationType) => {
      try {
        handleApiStart(setItem, 'notification', notificationType);
        const res = await axios.post(`/notifications`, { notification: { orderId: id, notificationType } });
        handleApiSuccess(res, setItem, { message: actionLabel('notificationSent', notificationType) });
      } catch (err) {
        handleApiError(err, setItem);
      }
    };

    const confirm = async (res, action, object) => {
      // if we're here and it's not a 202, we don't have to confirm
      if (res.status !== 202) {
        handleApiSuccess(res, setItem);
        return true;
      }
      if (!res.data.mqtt) throw new Error("Can't confirm action. No mqtt id received.");

      handleApiStart(setItem, `${action}Request`, object);
      for (var i = 0; i < STATUS_CHECK_MAX_RETRIES; i++) {
        try {
          const historyRes = await axios.get(`/orders/${id}/history`);
          const status = historyRes.data.data.statuses[res.data.mqtt];
          if (status == 'accepted') {
            handleApiSuccess(historyRes, setItem, {
              message: actionLabel(`${action}Accept`, object),
            });
            get(null, 'update');
            return true;
          } else if (status == 'rejected') {
            handleApiError({ message: actionLabel(`${action}Reject`, object) }, setItem);
            return false;
          } else {
            await sleep(STATUS_CHECK_INTERVAL);
          }
        } catch (err) {
          handleApiError(err, setItem);
          return false;
        }
      }
      handleApiError({ message: actionLabel('lockerUnavailable', object) }, setItem);
    };

    useMemo(() => {
      if (!lazy) get();
    }, [id]);

    return {
      item,
      actions: {
        get,
        update,
        delete: del,
        cancel,
        reversal,
        returnFromOrder,
        history: orderHistory,
        clientPreferences,
        lockersFindAvailable,
        lockerReserve,
        lockerUnreserve,
        sendNotification,
      },
    };
  },
};

export default orders;

import { fromJS } from 'immutable';
import { useMemo } from 'react';

const STATUS_CHECK_MAX_RETRIES = 5;
const STATUS_CHECK_INTERVAL = 700;

import {
  useItemState,
  indexRoute,
  newItemRoute,
  getItemAction,
  updateItemAction,
  deleteItemAction,
  handleApiStart,
  handleApiSuccess,
  handleApiError,
  axios,
  prepareData,
  actionLabel,
  sleep,
} from './utils';

const allowedParams = ['name', 'status', 'locationId', 'preferences', 'extras'];
const allowedDoorsCreateParams = ['doors'];
const allowedDoorParams = ['width', 'height', 'length', 'status'];

const lockers = {
  index: indexRoute('/lockers/', { doorsInfo: true, relations: 'location', orderAsc: false }),
  indexDoors: indexRoute('/lockers/', { doors: true, relations: 'location' }),
  newItem: newItemRoute('/lockers/', { allowedParams }),
  item: (id, _params, { history, lazy } = {}) => {
    const params = { ..._params, doors: true };

    const url = `/lockers/${id}`;
    const [item, setItem] = useItemState(url, params);

    const get = getItemAction(item.url, { params, setItem });

    const update = updateItemAction(item.url, {
      allowedParams,
      item,
      setItem,
      onSuccess: async (res) => {
        return await confirm(res, 'update', 'locker');
      },
    });
    const del = deleteItemAction(item.url, { setItem, history });

    const doorsCreate = async (doors) => {
      try {
        handleApiStart(setItem, 'create', 'door');
        const res = await axios.post(
          `/lockers/${item.id}/doors`,
          prepareData(fromJS({ doors }), { allowedParams: allowedDoorsCreateParams })
        );
        handleApiSuccess(res, setItem);
        get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const doorUpdate = async (doorId, door) => {
      try {
        handleApiStart(setItem, 'update', 'door');
        const res = await axios.patch(`/doors/${doorId}`, {
          door: prepareData(fromJS(door), { allowedParams: allowedDoorParams }),
        });
        return await confirm(res, 'update', 'door');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const confirm = async (res, action, object) => {
      // if we're here and it's not a 202, we don't have to confirm
      if (res.status !== 202) {
        handleApiSuccess(res, setItem);
        return true;
      }
      if (!res.data.mqtt) throw new Error("Can't confirm action. No mqtt id received.");

      handleApiStart(setItem, `${action}Request`, object);
      for (var i = 0; i < STATUS_CHECK_MAX_RETRIES; i++) {
        try {
          const historyRes = await axios.get(`/lockers/${id}/history/${res.data.mqtt}`);
          switch (historyRes.data.data.status) {
            case 'accepted':
              handleApiSuccess(historyRes, setItem, {
                message: actionLabel(`${action}Accept`, object),
              });
              get(null, 'update');
              return true;
            case 'rejected':
              handleApiError({ message: actionLabel(`${action}Reject`, object) }, setItem);
              return false;
            default:
              // try again in {STATUS_CHECK_INTERVAL}ms;
              await sleep(STATUS_CHECK_INTERVAL);
          }
        } catch (err) {
          handleApiError(err, setItem);
          return false;
        }
      }
      handleApiError({ message: actionLabel('lockerUnavailable') }, setItem);
    };

    useMemo(() => {
      if (!lazy) get();
    }, []);

    return { item, actions: { get, update, delete: del, doorsCreate, doorUpdate } };
  },
};

export default lockers;

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { routes } from '../routes';
import Button from '../components/common/Button';

const NotFound = (props) => {
  return (
    <main>
      <h1>Página no encontrada.</h1>
      <p>La página que buscas no exsiste o se movió de lugar.</p>
      <div style={{ height: '4rem' }} />
      <Button large onClick={() => props.history.goBack()}>
        &larr; Volver
      </Button>
      <Button large primary to={routes('root')}>
        Página de inicio
      </Button>
    </main>
  );
};

export default withRouter(NotFound);

import React from 'react';
import { formatDateTime, timeAgo } from '../utils';
import { orderTypes } from '../constants';

import { List, Body, Row, Cell } from '../components/common/List';

import css from './OrderInfo.css';

export default (props) => {
  const { order } = props;
  return (
    <List className={css.root}>
      <Body left>
        <Row>
          <Cell head>Ubicación</Cell>
          <Cell>{order.locationId ? order.locationName : 'No asignada'}</Cell>
        </Row>
        <Row>
          <Cell head>Locker</Cell>
          <Cell>{order.lockerId ? `${order.lockerName} (#${order.lockerId})` : 'No asignado'}</Cell>
        </Row>
        <Row>
          <Cell head>Puerta</Cell>
          <Cell>{order.doorNumber || 'Se asignará al entregar el paquete'}</Cell>
        </Row>
        <Row>
          <Cell head>Fecha de Creación</Cell>
          <Cell className={css.date}>
            <span>{timeAgo(order.createdAt) || '...'}</span>
            <span>{formatDateTime(order.createdAt) || '...'}</span>
          </Cell>
        </Row>
        <Row>
          <Cell head>Última actualización</Cell>
          <Cell className={css.date}>
            <span>{timeAgo(order.updatedAt) || '...'}</span>
            <span>{formatDateTime(order.updatedAt) || '...'}</span>
          </Cell>
        </Row>
      </Body>
    </List>
  );
};

import { useMemo } from 'react';

import { useItemState, getItemAction } from './utils';

const stats = {
  dashboard: (params, { history, lazy } = {}) => {
    const url = '/stats/dashboard';
    const _params = { lockers: true, orders: true, doors: true, ...params };
    const [item, setItem] = useItemState(url, _params);
    const get = getItemAction(url, {
      params: _params,
      setItem,
      plural: true,
    });

    useMemo(() => {
      if (!lazy) get();
    }, []);

    return { item, actions: { get } };
  },
};

export default stats;

import React, { useState } from 'react';
import api from '../utils/api';
import { sleep } from '../utils/api/utils';
import { makeBwipCanvas } from '../utils/bwip';

import OtpInput from 'react-otp-input';
import Label, { labelTypes } from '../components/common/Label';
import Button from '../components/common/Button';
import classnames from 'classnames';
import css from './Otp.css';

export const Otp = (props) => {
  const { code, email } = props;

  if (!email) {
    console.error('something wrong with Otp or email...');
    window.location.href = 'login';
  }

  const appAuthUser = `ELOCKER:${email}`;
  const [qrCode, setQrCode] = useState('');
  const [otp, setOtp] = useState({ value: '', message: '', valid: false });
  const { actions } = api.auth();

  const genBarcode = async () => {
    const options = {
      bcid: 'qrcode',
      text: `otpauth://totp/${appAuthUser}?secret=${code}&issuer=ELOCKER`,
      width: 80,
      height: 80,
      padding: 20,
    };
    const imgUrl = await makeBwipCanvas(options);
    if (imgUrl) setQrCode(`url(${imgUrl})`);
  };

  if (code) genBarcode();

  const sendOtp = async () => {
    if (email && otp.value) {
      const user = await actions.otpValidate({ email, otp: otp.value });

      if (user && user.id) {
        setOtp({ message: 'OTP correcto', valid: true, value: otp.value });
        await sleep(1500);
        window.location.href = '/';
      } else setOtp({ message: 'OTP incorrecto', valid: false, value: otp.value });
    } else setOtp({ message: 'Email or OTP no set', valid: false, value: otp.value });
  };

  const authenticatorStore = 'https://apps.apple.com/es/app/google-authenticator/id388497605';
  const authenticatorPlay = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
  const authenticatorLogo =
    'https://play-lh.googleusercontent.com/NxyEuJRx4jjR1Q9PXOPGExFQXDKr_pZJ61Cb15eR0aX3UTZKAxWsXvK9Gh4K-NUd5A=s48';

  const authyStore = 'https://apps.apple.com/es/app/authy/id494168017';
  const authyPlay = 'https://play.google.com/store/apps/details?id=com.authy.authy';
  const authyLogo =
    'https://play-lh.googleusercontent.com/NxyEuJRx4jjR1Q9PXOPGExFQXDKr_pZJ61Cb15eR0aX3UTZKAxWsXvK9Gh4K-NUd5A=s48';

  return (
    <main>
      <div className={css.center}>
        {code ? (
          <div>
            <div className={css.row}>
              {code && (
                <div>
                  <h2>Configura la autenticación de doble factor</h2>
                  <p>
                    <small>
                      Esta organización requiere autenticación de doble factor como una medida de seguridad adicional.
                    </small>
                  </p>
                  <p>Para obtener acceso, debes agregar y verificar un sistema de claves de uso único.</p>
                </div>
              )}
            </div>

            <div className={css.row}>
              <section className={css.center}>
                <ul>
                  <li>
                    Si no tiene, puedes descargar una gratis:
                    <ul>
                      <li>
                        En movil, <b>Google Authenticator</b>
                        {' para '}
                        <a target="_blank" href={authenticatorPlay}>
                          Android
                        </a>
                        {' e '}
                        <a target="_blank" href={authenticatorStore}>
                          iOS
                        </a>
                      </li>
                      <li>
                        En desktop, <b>Authy</b>
                        {' para '}
                        <a target="_blank" href={authyPlay}>
                          Google Play Store
                        </a>
                        {' o '}
                        <a target="_blank" href={authyStore}>
                          App Store
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Inicia sesión y selecciona <b>Scan QR Code</b> y escanea el código QR
                  </li>
                  <li>Ingresa el código de verificación de 6 digitos en el campo de abajo</li>
                </ul>
                <div
                  style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: qrCode,
                    width: 400,
                    height: 400,
                  }}
                  id="barcode">
                  {!qrCode ? <Label type={labelTypes('normal')}>Generando QR...</Label> : ''}
                </div>
              </section>
            </div>
          </div>
        ) : (
          <div>
            <h2>Autenticación de doble factor</h2>
            <p>Ingresa el código de 6 digitos que aparece en tu app de autenticación (*)</p>
          </div>
        )}

        <div className={css.row}>
          <small>
            Para el usuario <b>{email}</b>
          </small>
        </div>
        <div className={css.row}>
          <OtpInput
            value={otp.value}
            onChange={async (v) => {
              setOtp({ value: v, message: '', valid: false });
              //if (otp.length === 6 && !isNaN(otp.value)) sendOtp();
            }}
            numInputs={6}
            inputStyle={css.inputStyle}
            inputType={'text'}
            shouldAutoFocus={true}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <div style={{ height: '40px' }} className={classnames(css.row)}>
          <Label type={otp.valid ? labelTypes('success') : labelTypes('error')}>{otp.message}</Label>
        </div>

        <div className={classnames(css.row)}>
          <Button onClick={sendOtp} disabled={!otp.value || otp.value.length !== 6}>
            Verificar
          </Button>
        </div>
        {!code && (
          <div className={css.row}>
            <small>(*) Si desinstalaste tu app o cambiaste tu teléfono contacta a soporte para resetear tu OTP</small>
          </div>
        )}
      </div>
    </main>
  );
};

export default Otp;
